import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import { ReadOnlyEditor } from '../edit/BaseEditor';
import {makeStyles} from '@material-ui/core/styles';
import BackgroundPreview from './BackgroundPreview';

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        height: '260px'
    },
    profilePicContainer: {
        width: '110px',
        height: '110px',
        borderRadius: '100%',
        background: '#f9f9f9'
    },
    leaderItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap:'8px',
        alignItems:'center'
    },
    leaderShipTitle: {
        fontSize:'32px',
        fontWeight: 600,
        margin: '32px 0px'
    }
}))

const GreetingsPreview = ({item, index, normalUserPreview, brand_color}) => {
    const classes = useStyles();

    const {data} = item;
    const {title, text} = data;

    const descpStyle = normalUserPreview ? 'preview_description_style1' : 'preview_description_style';

    return (
        <BackgroundPreview item={item} title={title} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >

                <Grid item md={12} className={descpStyle}>
                    <ReadOnlyEditor editorValue={text}/>
                </Grid>

        </BackgroundPreview>
    );
}

export default GreetingsPreview;