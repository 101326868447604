import React, {useState} from 'react';
import { TableRow, TableCell, Grid, Tooltip, Typography, ClickAwayListener, Button} from '@material-ui/core';
import {formatAmount} from '../util/currencyUtil';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { EstimateStatus, EstimateStatusColor } from '../util/AppUtil';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { trackGAEvent } from '../util/AppUtil';
import { BillStatusColor, displayStatus } from '../util/AppUtil';
import Chip from '@material-ui/core/Chip';
import { consoleToLog } from '../util/AppUtil';
import { usePropsContext } from './context';
import { history } from '../routers/AppRouter';
import { useSnackbar } from 'notistack';
import { downloadEstimateApi } from '../services/authService';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
    marginDropdown: {
        margin: theme.spacing(1),
    },
    estimateListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'4px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'2px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'180px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    statusCellWidth: {
        width:'90px'
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    actionsIcons: {
        fontSize: '18px',
    },
    displayFlex: {
        display:'flex !important',
        alignItems:'center !important'
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    billTypeStyle: {
        height: '22px',
        verticalAlign: 'middle',
        fontSize: '12px',
        color: '#fff', 
        textAlign: 'center',
        width:'110px',
        [theme.breakpoints.down(1500)] : {
            width:'90px',
            fontSize: '11px',
        },
        [theme.breakpoints.down(1400)] : {
            width:'80px',
            fontSize: '10px',
        }
    }
}));

const EstimateActions = ({ estimate, classes, onPreviewEstimateClick, onSendEstimateClick, onUndoCancelClick, onCreateInvoiceClick, onEditEstimateClick,
    closeOptionsMenu, handleConfirmDialogOpen, selectedAccount
}) => {
    const renderButtons = () => {
        switch (estimate.status) {
            case EstimateStatus.DRAFT:
            case EstimateStatus.SENT:
            case EstimateStatus.VIEWED:
            case EstimateStatus.DOWNLOADED:
            case EstimateStatus.APPROVED:
            return (
                <>
                <ActionButton
                    onClick={(e) => onPreviewEstimateClick(e, estimate)}
                    icon={<VisibilityOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`} />}
                    text="Preview"
                    className="more_actionButton1"
                />
                {estimate.can_create_invoice ?
                <ActionButton
                    onClick={(e) => onCreateInvoiceClick(e, estimate)}
                    icon={<AddCircleOutlineIcon style={{fontSize:'17px', color:'green'}} className='more_actionsIcons' />}
                    text="Create Invoice"
                    className="more_actionButton"
                />
                :
                estimate.status === EstimateStatus.DRAFT && estimate.approval_required
                ?
                <ActionButton
                    onClick={(e) => onSendEstimateClick(e, estimate)}
                    icon={<SendIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`}/>}
                    text="Send"
                    className="more_actionButton"
                />
                :
                <ActionButton
                    onClick={(e) => onEditEstimateClick(e, estimate)}
                    icon={<EditOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`}/>}
                    text="Edit Estimate"
                    className="more_actionButton"
                />
                }
                </>
            );
            case EstimateStatus.CANCELLED:
            case EstimateStatus.REJECTED:
            case EstimateStatus.CLOSED:
            return (
                <>
                <ActionButton
                    onClick={(e) => onPreviewEstimateClick(e, estimate)}
                    icon={<VisibilityOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`} />}
                    text="Preview"
                    className="more_actionButton1"
                />
                {estimate.can_reopen &&
                <ActionButton
                    onClick={(e) => handleConfirmDialogOpen(e, estimate, closeOptionsMenu, {reopen_estimate_clicked: true})}
                    icon={<UndoOutlinedIcon className='more_actionsIcons' style={{fontSize:'15px', verticalAlign:'baseline'}} />}
                    text="Reopen"
                    className="more_actionButton"
                />}
                </>
            );
            default:
            return null;
        }
        };
    
        return <div className={classes.buttonContainer}>{renderButtons()}</div>;
    };
    
    const ActionButton = ({ onClick, icon, text, className }) => (
        <Button item className={className} onClick={onClick}>
        {icon}
        <span>{text}</span>
        </Button>
    );


const EstimatesTableRow = ({estimate, entityList, accessLevelViewer, storeEstimateState, user, selectedAccount, handleConfirmDialogOpen, 
    createDuplicateEstimateClick, fromClientDetails}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [estimateId, setEstimateId] = useState(undefined);
    const [isActive, setIsActive] = useState(false);

    const {handleInvoiceDialogOpen} = usePropsContext();
    const invoice_account_id = selectedAccount?.id;
    const name = selectedAccount?.name;
    const full_name = user?.firstname + ' ' + user?.lastname;

    const onDownloadEstimateClick = async (e, estimate) => {
        e.stopPropagation();
        e.preventDefault();
        const estimate_id = estimate.id;
        const entity_id = estimate.entity_id;
        const client_id = estimate.client_id;

        try {
            const response = await downloadEstimateApi(invoice_account_id, entity_id, client_id, estimate_id);
            const res = response.data;
            const downloadUrl = res.document_signed_url;
            consoleToLog('Response downloadEstimateApi: ', downloadUrl);

            // Create a temporary anchor element to trigger the download
            const tempLink = document.createElement('a');
            tempLink.href = downloadUrl;
            tempLink.setAttribute('download', `estimate_${estimate.estimate_number}.pdf`);
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);

            trackGAEvent(name, 'Estimate downloaded', `${full_name}`);
            enqueueSnackbar('Estimate downloaded successfully', {variant: 'success'});
        } catch (error) {
            consoleToLog('Error downloadEstimateApi: ', error);
            if (error.response && error.response.data && error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {variant: 'error'});
            } else {
                enqueueSnackbar('Failed to download estimate', {variant: 'error'});
            }
        }
        closeOptionsMenu();
    };

    const estimateActions = (estimate) => {
        switch (estimate.status) {
            case EstimateStatus.DRAFT:
            case EstimateStatus.SENT:
            case EstimateStatus.VIEWED:
            case EstimateStatus.DOWNLOADED:
            case EstimateStatus.APPROVED:
            case EstimateStatus.CANCELLED:
            case EstimateStatus.REJECTED:
            case EstimateStatus.CLOSED:
                return (
                    estimate.id === estimateId &&
                    <>
                        {estimate.can_edit &&
                        <li onClick={(e) => onEditEstimateClick(e, estimate)}>
                            <a className={classes.displayFlex}>    
                            <EditOutlinedIcon fontSize='small' 
                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                            Edit
                            </a>
                        </li>}
                        <li onClick={(e) => createDuplicateEstimateClick(e, estimate, closeOptionsMenu)}>
                            <a className={classes.displayFlex}>
                            <FilterNoneIcon fontSize='small' 
                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                            Duplicate
                            </a>
                        </li>
                        {estimate.can_close &&
                        <li onClick={(e) => handleConfirmDialogOpen(e, estimate, closeOptionsMenu, {close_estimate_clicked: true})}>
                            <a className={classes.displayFlex}>
                                <CloseOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Close
                            </a>
                        </li>}
                        <li onClick={(e) => onDownloadEstimateClick(e, estimate)}>
                            <a className={classes.displayFlex}>
                                <CloudDownloadOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Download
                            </a>
                        </li>
                        {estimate.can_cancel &&
                        <li onClick={(e) => handleConfirmDialogOpen(e, estimate, closeOptionsMenu, {cancel_estimate_clicked: true})}>
                            <a className={classes.displayFlex}>
                                <CancelOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Cancel
                            </a>
                        </li>}
                        <li onClick={(e) => handleConfirmDialogOpen(e, estimate, closeOptionsMenu, {delete_estimate_clicked: true})}>
                            <a className={classes.displayFlex}>
                                <DeleteOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Delete
                            </a>
                        </li>
                    </>
                );
            default:
                return null;
        }
    };

    const closeOptionsMenu = () => {
        setIsActive(false);
        setEstimateId('');
    }

    const onEditEstimateClick = (e, estimate) => {
        e.stopPropagation();
        trackGAEvent(name, 'Estimate list Item - Edit Estimate clicked', `${full_name}`);
        handleInvoiceDialogOpen(true);
        storeEstimateState(true, false, estimate, false);
        closeOptionsMenu();
    }

    const onPreviewEstimateClick = (e, estimate) => {
        e.stopPropagation();
        trackGAEvent(name, 'Estimate list Item - Preview Estimate clicked', `${full_name}`);
        handleInvoiceDialogOpen(true);
        storeEstimateState(false, false, estimate, false);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();
    }

    const onSendEstimateClick = (e, estimate) => {
        trackGAEvent(name, 'Send Estimate button clicked', `${full_name}`);
        e.stopPropagation();
        storeEstimateState(false, false, estimate, true);
        handleInvoiceDialogOpen(true);
        closeOptionsMenu();
    }

    const onCreateInvoiceClick = (e, estimate) => {
        e.stopPropagation();
        history.push('/invoice/create', {estimateObj: estimate});
        trackGAEvent(name, 'Estimate list Item - Create Invoice clicked', `${full_name}`);
        storeEstimateState(false, true, estimate);
        handleInvoiceDialogOpen();
    }

    return (
        <TableRow hover className={classes.estimateListHover}
            onClick={(e) => onPreviewEstimateClick(e, estimate)}>
            <TableCell component="th" scope="row" className={`tablebody_style ${classes.invoiceNoCol}`}>
                <span className={classes.invoiceTypeStyle}>
                    EST
                </span>
                <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                    {estimate.estimate_number}
                </span>
            </TableCell>
            <TableCell className={`tablebody_style1 ${classes.statusCellWidth}`}>
                <Grid item container alignItems='center'>
                    <div className='status_style'
                        style={{background: EstimateStatusColor(estimate.status.toLowerCase())}}>
                    </div>
                    <Tooltip title={estimate.status.charAt(0).toUpperCase() + estimate.status.slice(1).toLowerCase()} arrow>
                        <Typography 
                        style={{
                            color:EstimateStatusColor(estimate.status.toLowerCase()), 
                            fontSize: "10px"
                        }}>
                            {estimate.status.charAt(0).toUpperCase() + estimate.status.slice(1).toLowerCase()}
                        </Typography>
                    </Tooltip>
                </Grid>
            </TableCell>
            <TableCell className='tablebody_style'>
                <Tooltip title={moment(estimate.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')} arrow>
                    <Typography variant='subtitle1' className='issue_date_text_ellipsis'>
                        {moment(estimate.issue_date, 'YYYY-MM-DD').format('MMM Do, YY')}
                    </Typography>
                </Tooltip>
            </TableCell>
            {entityList && entityList.length > 1 && 
                <TableCell className='tablebody_style'>
                    <Tooltip title={estimate.entity_name} arrow>
                        <Typography variant="subtitle1" 
                            className={'text_ellipsis'}>
                            {estimate.entity_name}
                        </Typography>
                    </Tooltip>
                </TableCell>
            }
            <TableCell className='tablebody_style'>
                <Tooltip title={estimate.client_name} arrow>
                    <Typography variant="subtitle1" 
                        className={'text_ellipsis1'}>
                            {estimate.client_name}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell><Tooltip title={estimate.estimate_description} arrow>
                    <Typography variant="subtitle1" 
                        className={'text_ellipsis1'}>
                            {estimate.estimate_description}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell>{formatAmount(estimate.total, estimate.currency)}</TableCell>
            <TableCell className='tableheading_style'>
                <Tooltip title={displayStatus(estimate.bill_status)}>
                    <Chip 
                        label={displayStatus(estimate.bill_status)} 
                        className={classes.billTypeStyle}
                        style={{
                            background: BillStatusColor(estimate.bill_status.toLowerCase()), 
                        }} 
                    />
                </Tooltip>
            </TableCell>
            {!fromClientDetails &&
            <TableCell align='right'>
            {!accessLevelViewer &&
                <Grid item
                    style={{display:'flex',
                    alignItems:'center',
                    flexDirection:'row-reverse',
                    justifyContent:'flex-end',
                    position:'relative'}}>

                    <div style={{position:'absolute', right:'0px'}}>        
                        <Tooltip title="More Actions" arrow>  
                            <i className="flaticon-more action__icon"
                            onClick={(e) => {
                                    e.stopPropagation();
                                    setIsActive(!isActive);
                                    setEstimateId(estimate.id);
                                }}
                            style={{fontSize:'15px', position:'relative', left:'-16px'}}></i>
                        </Tooltip>
                        <>    
                        {estimate.id === estimateId &&
                            <ClickAwayListener onClickAway={() => {
                                    setIsActive(false);
                                    setEstimateId('');
                            }}>
                                <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                    <ul>{estimateActions(estimate)}</ul>
                                </Grid>
                            </ClickAwayListener>
                        }
                        </>
                    </div>

                    <EstimateActions estimate={estimate}
                        classes={classes}
                        onEditEstimateClick={onEditEstimateClick}
                        onPreviewEstimateClick={onPreviewEstimateClick}
                        onSendEstimateClick={onSendEstimateClick}
                        onCreateInvoiceClick={onCreateInvoiceClick}
                        closeOptionsMenu={closeOptionsMenu}
                        handleConfirmDialogOpen={handleConfirmDialogOpen}
                        selectedAccount={selectedAccount}
                    />
                </Grid>
                }
            </TableCell>}
        </TableRow>
    );
}

export default EstimatesTableRow;