import moment from "moment";
import {SendEmailType} from "../actions/invoiceAccount";
import { SelectedDrawerState } from '../actions/drawer';
import ReactGA from "react-ga4";
import country from "../data/country.json";
import state from "../data/state.json";
import MenuItem from '@material-ui/core/MenuItem';
import { authenticateApi } from "../services/authService";
import currencyCountries from '../data/countries.json';
import { history } from "../routers/AppRouter";
import { Grid, Typography } from "@material-ui/core";
import { type } from "@testing-library/user-event/dist/type";
import md5 from 'md5';

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const emailValidator = (email) => {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (reg.test(email)) {
        return true;
    } else {
        //toast.error('Email is not valid!');
        return false;
    }
};


export const boldString = (client_name, searchClient) => {
    let boldStringText = new RegExp(searchClient, 'igm');
    let result =  client_name.replace(boldStringText, '<strong>' + searchClient + '</strong>');
    return result;
}  

export const showDate = (date) => {
     return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY');
}  

export const showListItemDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MMM Do, YYYY');
}  

export const showPaymentMethodString = (str) => {
    if(str === 'bank') return 'Bank';
    if(str === 'cash') return 'Cash';
    if(str === 'cheque') return 'Cheque';
    if(str === 'upi') return 'UPI';
    if(str === 'credit_card') return 'Credit Card';
    if(str === 'external') return 'external';
}

export const parseString = (str) => {
    //console.log('str', str);
    return str ? str.replace(/<br ?\/?>/ig,"\n") : '';
};

export const showStatusImages  = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'partial') return '/images/eprocessify_partial.png';
    if(status === 'paid') return '/images/eprocessify_paid.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
}

export const estimateStatusImage = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'closed') return '/images/est_closed.png';
    if(status === 'approved') return '/images/est_approved.png';
    if(status === 'rejected') return '/images/est_rejected.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
}

export const statusColor = (status) => {   
    if(status === 'paid') {
        return 'green'
    } else if(status === 'viewed') {
        return '#cca20e'
    } else if(status === 'partial') {
        return '#006644'
    } else if(status === 'draft') {
        return '#828c98'
    } else if(status === 'sent') {
        return '#4c51bf'
    } else if(status === 'downloaded') {
        return '#007bc3'
    } else if(status === 'cancelled') {
        return '#f44336'
    } else if(status === 'approved') {
        return 'green'
    } else if(status === 'rejected') {
        return 'red'
    }
}

export const displayInitials = (name) => {   
    const firstLetters = name
    .split(' ').filter((element) => element.replace(/[^a-zA-Z ]/, ''))
    .map(word => word[0])
    .slice(0, 2)
    .join('');

  return firstLetters.toUpperCase();
} 

export const displayInvoiceType = (invoiceType) => {
    if(invoiceType === SendEmailType.PROFORMA) {
        return 'Proforma';
    } else if(invoiceType === SendEmailType.TAX) {
        return 'Tax';
    } else {
        return '';
    }
}

export const showErrowAndClearReduxOn401 = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const show401ErrorAndClearRedux = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const trackGAPageView= (page) => {
    ReactGA.pageview(page);
}

export const trackGAEvent= (category, action, label) => {
    if(!isDebug()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
}

export const udfTypes = {
    Text: { displayName: "Text", value: "text" },
    Password: { displayName: "Password", value: 'password' },
    ImageOrDocument: { displayName: "Image / Document", value: "file" }
};

export const isPredefinedName = (name) => {
    if (name !== '') {
        return true;
    }
};

//return if valid, return error if not valid
export const isValidUserDefinedFields = (udfArray) => {
    for (var i = 0; i < udfArray.length; i++) {
        for (var j = i + 1; j < udfArray.length; j++) {//repeat
            if (udfArray[i].name === udfArray[j].name) {
                return `User defined field name can not be repeated: ${udfArray[i].name}`;
            }
        }
        //value validations
        if (!udfArray[i].value) {//empty
            return `Value can not be empty at row:${i + 1}`;
        }
    }
}

const {countries} = country;

export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem value={country.code}>{country.name}</MenuItem>
        )
    })
)

const {states} = state;
export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.code}>{state.name}</MenuItem>
        )
    })
)

export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const removeSpace = (str) => {
    return str.replace(/\s/g, '')
}

export const showDefaultClientValues = (option, clientObj) => {
    const itemObj = option.default_value;
    //console.log('*******************', itemObj)
    switch (itemObj) {
        case '{{client.name}}':
            return clientObj?.name;

        case '{{client.address}}':
            return clientObj?.address;

        case '{{client.country}}':
            return clientObj?.country;
        
        case '{{client.state}}':
            return clientObj?.state;
            
        case '{{client.tax_id}}':
            return clientObj?.tax_id
    
        default:
            break;
    }
}

export const AccessLevel = {
    OWNER: 3,
    EDITOR: 2,
    VIEWER: 1,
    getUserAccessLevelValue: function (access_level) {
        switch (access_level) {
            case "owner": return 3;
            case "editor": return 2;
            case "viewer": return 1;

            default:
                break;
        }
    }
};

export const shouldDisable = (user_access, actual_permission) => {
    return !(user_access >= actual_permission);
}

//input: 'abc' output: 'Abc'
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

// phone validation check, key pressed when entering telephone number can only be numbers
export const phoneNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const currencyFormatter = new Intl.NumberFormat('en-IN', {
    style:'currency',
    currency: 'INR',
});

export const InvoiceStatus = {
    PARTIAL : "partial",
    PAID: "paid",
    VIEWED: "viewed",
    DRAFT: "draft",
    SENT: "sent",
    DOWNLOADED: "downloaded",
    CANCELLED: "cancelled"
}

export const InoviceTypes = {
    PROFORMA : "proforma",
    TAX: "tax",
    NONE: "none",
}

export const DiscountTypes = {
    PERCENT : "percent",
    FLAT: "flat",
}

export const otherTaxesTypes = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'GST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'CGST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'SGST' && tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'IGST');
}
export const intraStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "GST" || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "CGST"
                            || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "SGST");
}

export const interStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "IGST")
}

export const OverlayMessage = {
    IMPORT_MSG:"Please wait. We are setting up clients.",
    EXPORT_MSG: "Please wait. We are exporting into spreadsheet!",
    DOWNLOAD_MESSAGE: "Please wait. We are exporting into zip!",
}

export const onlyNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key)) {
        e?.preventDefault();
    }
}

export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};

export const InvoicePlans = {
    FREE: 'free',
    MONTHLY: process.env.REACT_APP_MONTHLY_PLAN,
    YEARLY: process.env.REACT_APP_YEARLY_PLAN
}

export const onUpgradeNowClick = (props) => {
    history.push(AppRoutes.BILLING);
    props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
}

export const setHeightForComponent = (isInvoiceAccountPlanFree, entityLimitExceeded) => {
    if(isInvoiceAccountPlanFree || entityLimitExceeded) {
        return 'calc(100vh - 85px)';
    } else if(!entityLimitExceeded && !isInvoiceAccountPlanFree) {
        return 'calc(100vh - 48px)';
    }
}

export const isInvoiceAccountOnFreePlan = (plan) => {
    return plan?.toLowerCase() === InvoicePlans.FREE ? true : false;
}

export const isRecurringInvoiceActive = (recurring_invoice_obj) => {
    return recurring_invoice_obj && recurring_invoice_obj.status === 'active' ? true : false;
}

export const InvoiceEntityApi = {
    ACTIVE: 'entity_list',
    ARCHIVED: 'archived_entities'
}

const {countries_currency} = currencyCountries;
export const invoiceAccountCountryObj = (selectedAccountCountry) => {
    const countryObj = countries_currency.find(country => country.code === selectedAccountCountry);
    return countryObj
}

export const extractFlagCode = (str) => {
    return str?.split(':')[1]?.split('-')[1]?.toUpperCase()
}

export const isInvoiceAccountCountryIndia = (invoiceAccountCountry) => {
    return invoiceAccountCountry === "IN" ? true : false
}

export const getCountryObj = (countries, code) => {
    return countries.find((country) => country.code === code);
}

export const getStateObj = (states, stateCode, countryCode) => {
    return stateCode ? states.find((state) => state.state_code === stateCode && state.country_code === countryCode) : undefined;
}

export const AppRoutes = {
    DASHBOARD: '/dashboard',
    INVOICES: '/invoices',
    TRANSACTIONS: '/transactions',
    MEMBERS: '/members',
    CLIENTS: '/clients',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ONBOARDING: '/onboarding',
    SETUP: '/setup',
    CLIENT_REPORTS: '/report/client',
    ITEM_REPORTS: '/report/items',
    ESTIMATES: '/estimates',
    BILLING: '/settings/billing',
    PROPOSALS: '/proposals'
}

export const getUrlAccordingToSelectedComponent = (drawerState) => {
    if(drawerState === SelectedDrawerState.SHOW_DASHBOARD) {
        return AppRoutes.DASHBOARD;
    } else if(drawerState === SelectedDrawerState.SHOW_INVOICES) {
        return AppRoutes.INVOICES;
    } else if(drawerState === SelectedDrawerState.SHOW_TRANSACTIONS) {
        return AppRoutes.TRANSACTIONS;
    } else if(drawerState === SelectedDrawerState.SHOW_CLIENTS) {
        return AppRoutes.CLIENTS;
    } else if(drawerState === SelectedDrawerState.SHOW_MEMBERS) {
        return AppRoutes.MEMBERS;
    } else if(drawerState === SelectedDrawerState.SHOW_SETTINGS) {
        return AppRoutes.SETTINGS;
    } else if(drawerState === SelectedDrawerState.SHOW_PROFILE) {
        return AppRoutes.PROFILE;
    } else if(drawerState === SelectedDrawerState.SHOW_ONBOARDING) {
        return AppRoutes.ONBOARDING;
    } else if(drawerState === SelectedDrawerState.SHOW_REPORTS) {
        return AppRoutes.REPORTS;
    }  else if(drawerState === SelectedDrawerState.SHOW_ESTIMATES) {
        return AppRoutes.ESTIMATES;
    }  else if(drawerState === SelectedDrawerState.SHOW_PROPOSALS) {
        return AppRoutes.PROPOSALS;
    }
}

export const isInvoiceStatusCancelled = (status) => {
    return status === InvoiceStatus.CANCELLED ? true : false
}

export const displayInvoiceTypeLabel = (type) => {
    if(type === InoviceTypes.TAX){
        return 'INV'
    }
    else {
        return 'PRO' 
    }
}

export const extractBasePath = (fromBaseComponent) => {
    let pathname = window.location.pathname;
    const parts = pathname.split('/');
    if (parts.length > 2) {
        return `/${parts[1]}`;
    }
    return pathname;
};

export const storeComponentState = (fromEdit, fromCreate, itemList, items, page, total, componentObj, saveState, str, props, showSendEmail) => {
    const stateObj = {
        itemList,
        items,
        page,
        total,
        ...(props.fromClientDetails && {
            clientListState: {...props.clientComponentObj},
            clientInfo: {...props.showClientInfo},
            tabValue: props.tabValue,
            clientScrollPosition: props.scrollContainer?.scrollTop
        }),
    };
    saveState(stateObj);
    if(!fromCreate) {
    return fromEdit 
        ? history.push(`/${str}/${componentObj.slug}/edit`) 
        : history.push(`/${str}/${componentObj.slug}/preview`, {showSendEmail: showSendEmail});
    }
}

export const showValidityMessage = (validity) => {
    return (    
        <Grid item sm={12} style={{marginTop:'16px', padding:'0px 24px 16px 24px' }}>
            <Typography className='validitySyle'>
                {`This estimate is valid for ${validity} days from the date issued. After this period, 
                the terms and pricing may be subject to change. If you need more time or have any questions, 
                please let us know before the validity period expires.`}
            </Typography>
        </Grid>
    )
}

export const EstimateStatus = {
    DRAFT: 'draft',
    SENT: 'sent',
    VIEWED: 'viewed',
    DOWNLOADED: 'downloaded',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CLOSED: 'closed',
    CANCELLED: 'cancelled'
}

export const EstimateStatusColor = (status) => {   
    if(status === 'draft') {
        return '#828c98';
    } else if(status === 'sent') {
        return '#4c51bf';
    } else if(status === 'viewed') {
        return '#cca20e';
    } else if(status === 'downloaded') {
        return '#007bc3';
    } else if(status === 'approved') {
        return 'green';
    } else if(status === 'rejected') {
        return '#8b0000';
    } else if(status === 'closed') {
        return '#828c98';
    } else if(status === 'cancelled') {
        return '#f44336';
    }
}

export const BillStatusColor = (status) => {   
    if(status === 'none') {
        return '#cccccc'
    } else if(status === 'partial') {
        return '#ff9800'
    } else if(status === 'invoiced') {
        return '#4caf50'
    } 
}

export const displayStatus = (status) => {
    if(status === 'none') return 'Not Invoiced';
    else if(status === 'partial') return 'Partially Invoiced';
    else if(status === 'invoiced') return 'Invoiced';
    //else return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
}

export const invoicePrefixOptions = [
    {name: `{{prefix}}-{{number}}`, value: `{{prefix}}-{{number}}`},
    {name: `{{prefix}}-{{FY}}-{{number}}`, value: `{{prefix}}-{{FY}}-{{number}}`},
    {name: `{{prefix}}-{{MMM-YY}}-{{number}}`, value: `{{prefix}}-{{MMM-YY}}-{{number}}`}
];

export const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
    {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
    {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
    {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
];

export const showPrefixExampleValue = (formatValue, prefix) => {
    switch(formatValue) {
        case `{{prefix}}-{{number}}`:
            return `(eg: ${prefix}-045)`

        // case `{{prefix}}-INV-{{number}}`:
        //     return '(eg: GT-INV-045)'

        case `{{prefix}}-{{FY}}-{{number}}`:
            return `(eg: ${prefix}-2223-045)`

        case `{{prefix}}-{{MMM-YY}}-{{number}}`: 
            return `(eg: ${prefix}-APR-22-045)`
    }
}

export const modifyTaxObj = (id, name, rate) => {
    return {
        tax_preset_id:id, 
        name:name, 
        rate:rate
    }
}

export const PeriodicityArr = [
    {name: 'Not Recurring', value: 'none', display_value: ''},
    {name: 'Hourly', value: 'hourly', display_value: 'per hour'},
    {name: 'Weekly', value: 'weekly', display_value: 'per week'},
    {name: 'Monthly', value: 'monthly', display_value: 'per month'},
    {name: 'Half Yearly', value: 'half_yearly', display_value: 'per half year'},
    {name: 'Yearly', value: 'yearly' , display_value: 'per year'},
]

export const lightenColor = (hex, percent) => {
    // Remove the hash symbol if present
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Apply lightening based on the percentage
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

    // Convert back to hex and return the result
    const newHex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    return newHex;
};

export const ProposalStatus = {
    DRAFT: 'draft',
    SENT: 'sent',
    VIEWED: 'viewed',
    DOWNLOADED: 'downloaded',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CANCELLED: 'cancelled'
}

export const SocialMediaList = [
    {name: "Facebook", type: "facebook"},
    {name: "X (Twitter)", type: 'x'},
    {name: "LinkedIn", type: "linkedin"},
    {name: "Instagram", type: "instagram"},
    {name: "Youtube", type: "youtube"},
    {name: "Other", type:"other"}
];

export const showSocialMediaImages = (type) => {
    switch (type) {
        case 'facebook':
            return '/images/facebook_image.svg'

        case 'x':
            return '/images/x_image.svg'

        case 'linkedin':
            return '/images/linkedin_image.svg'

        case 'instagram':
            return '/images/instagram_image.svg'

        case 'youtube':
            return '/images/youtube_image.svg'

        case 'other':
            return '/images/globe_image.svg'

        default:
            return '/images/globe_image.svg'
            break;
    }
}

export const generateMd5ForApi = (url) => {
    let api_url = url
    if(api_url.indexOf("https://") !== -1) {
        api_url = api_url.replace('https://','http://');
    }
    return md5(api_url);
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function getDominantColor(imgElement, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set a larger canvas size for better color accuracy
    const targetWidth = 800; // Change this as needed
    const scaleFactor = targetWidth / imgElement.naturalWidth;
    canvas.width = targetWidth;
    canvas.height = imgElement.naturalHeight * scaleFactor;

    ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

    try {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const { data } = imageData;
    
        const colorMap = {};
        let dominantColor = { color: [0, 0, 0], count: 0 };

        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const alpha = data[i + 3];
    
            // Skip fully transparent pixels
            if (alpha === 0) continue;
    
            // Skip pixels that are close to white
            if (r > 240 && g > 240 && b > 240) continue;
    
            const color = `${r},${g},${b}`;
            colorMap[color] = (colorMap[color] || 0) + 1;
    
            if (colorMap[color] > dominantColor.count) {
            dominantColor = { color: [r, g, b], count: colorMap[color] };
            }
        }
    
        callback(dominantColor.color);
    } catch (error) {
        console.error("Error processing image data:", error);
    }
}

export const parseMarkdownToHtml = (text) => {
    // Function to parse Markdown to HTML
      // Replace headers
    if(text) {
    text = text.replace(/^###### (.*$)/gim, '<h6>$1</h6>');
    text = text.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
    text = text.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
    text = text.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    text = text.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    text = text.replace(/^# (.*$)/gim, '<h1>$1</h1>');

    // Replace bold and italic
    text = text.replace(/\*\*\*(.*?)\*\*\*/gim, '<b><i>$1</i></b>'); // Bold Italic
    text = text.replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>'); // Bold
    text = text.replace(/\*(.*?)\*/gim, '<i>$1</i>'); // Italic

    // Replace links
    text = text.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>');

    // Replace unordered lists
    text = text.replace(/^\* (.*$)/gim, '<ul><li>$1</li></ul>');
    text = text.replace(/<\/ul>\s*<ul>/gim, ''); // Merge consecutive lists

    // Replace ordered lists
    text = text.replace(/^\d+\. (.*$)/gim, '<ol><li>$1</li></ol>');
    text = text.replace(/<\/ol>\s*<ol>/gim, ''); // Merge consecutive lists

    // Replace line breaks
    text = text.replace(/\n/gim, '<br>');

    
    return text.trim();

    }
};

/*
 * Synchronizes the order of navItems with editProposal array while preserving custom sections
 */
export const syncNavItemsOrder = (navItems, editProposal) => {
    if (!navItems?.length || !editProposal?.length) return navItems;

    // Create a map of original nav items for easy lookup
    const navItemsMap = new Map(navItems.map(item => [item.type, item]));
    
    // Filter out custom sections from editProposal

    const standardSections = editProposal.filter(item => item.type !== 'custom');
    
    // Create new array maintaining the order from editProposal
    const updatedNavItems = standardSections.map(section => {
        const navItem = navItemsMap.get(section.type);
        return navItem ? { ...navItem, order_number: section.order_number } : null;
    }).filter(Boolean);

    // Add back custom sections in their original positions
    const customNavItems = navItems.filter(item => {
        const proposalItem = editProposal.find(p => p.type === item.type);
        return proposalItem?.type === 'custom';
    });
    //console.log('reordered nav array', [...updatedNavItems, ...customNavItems])
    return [...updatedNavItems, ...customNavItems];
};

export const AttachmentTypes = {
    PROPOSAL: 'proposal',
    ENTITY: 'entity'
}

export const AttachmentSubTypes = {
    PROPOSAL_TEAM: 'proposal_team',
    PROPOSAL_PROFILE_PIC: 'proposal_profile_pic',
    PROPOSAL_PDF: 'proposal_pdf',
    ENTITY_SIGNATURE: 'entity_signature'
}