import React, { memo } from 'react';
import BaseSection from './BaseSection';
import {Grid, Typography, Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
        imageContainer: {
            width: '100%',
            height: '260px',
        },
        profilePicContainer: {
            width: '110px',
            height: '110px',
            borderRadius: '100%',
            background: '#f9f9f9',
            overflow:'hidden',
            
        },
        leaderItemContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap:'8px',
            alignItems:'center'
        },
        centerAlignText:{
            textAlign:"center"
        }
}))

const Team = ({item, handleDrawerOpen, onShowOrHidePage}) => {
    const classes = useStyles();

    const {data} = item;
    const {team_image_title, show_team_image_title, team_image, team_image_signed_url, show_team_image, leadership_title, show_leadership_title, leaders} = data;

    return (
        <BaseSection title="Team" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>
                
                <Grid item md={12} className='edit_section_container'>

                <Grid item md={12}>
                    {show_team_image_title &&
                    <Grid item md={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: team_image_title}} />
                    </Grid>}

                    {show_team_image &&
                        <Grid item md={12} style={{marginTop: show_team_image_title ? '16px' : '0px'}}>
                            <div className='team_image_container'>
                                <img src={team_image_signed_url ? team_image_signed_url : '/images/team_photo.png'} 
                                    className='image_style'/>
                            </div>
                        </Grid>
                    }
                </Grid>
                
                <Grid item md={12} style={{marginTop: show_team_image_title && show_team_image ? '24px' : '0px'}}>
                    {show_leadership_title &&
                    <Grid item md={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: leadership_title}} />
                    </Grid>}

                    <Grid item md={12} style={{marginTop:show_leadership_title ? '16px' : '0px'}}>
                        <Grid item container alignItems='center' justifyContent='center'>
                            {
                                leaders && leaders.length > 0 &&
                                leaders.map((item) => {
                                    const {name, title, bio, profile_pic, profile_pic_signed_url} = item;
                                    return  item &&
                                            <Grid item md={4} className={classes.leaderItemContainer}>
                                                <div className='profile_pic_container'>
                                                    <img src={profile_pic_signed_url ? profile_pic_signed_url : '/images/profile_pic.png'} 
                                                        className='image_style' />
                                                </div>

                                                <Grid item>
                                                    <Typography>
                                                        <b>{name}</b>
                                                    </Typography>
                                                    <Typography className={classes.centerAlignText}>
                                                        {title}
                                                    </Typography>
                                                </Grid>
                                                {/* <Typography>
                                                    {bio}
                                                </Typography> */}
                                            </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>

        </BaseSection>
    );
};

export default Team;
