import React, {useState, Fragment} from 'react';
import {TextField, Grid, Chip, Avatar, Tooltip, Typography} from '@material-ui/core'; 
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from '../useIsMount';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { addSelectedEntitiesToInvoiceFilter, removeSelectedEntitiesToInvoiceFilter } from '../../actions/invoiceFilters';
import {addSelectedEntitiesToProposalFilter, removeSelectedEntitiesToProposalFilter} from '../../actions/proposalFilters';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }

    }
));

const AutocompleteEntities = (props) => {


    const classes = useStyles();
    const [entities, setEntities] = useState(props.selectedAccount.entities);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const isMount = useIsMount(); 

    const onAddEntity = (option) => {
        props.fromProposalListComponent ? props.addSelectedEntitiesToProposalFilter(option) : props.addSelectedEntitiesToInvoiceFilter(option);
    }

    const onDeleteEntity = (option) => {
        setSelectedEntities(selectedEntities.filter(entry => entry !== option));
        props.fromProposalListComponent ? props.removeSelectedEntitiesToProposalFilter(option) : props.removeSelectedEntitiesToInvoiceFilter(option);
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                className={classes.autoStyle}
                onClick={() => {
                    onAddEntity(option);
                    props.pageReset();
                }}
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name?.trim()?.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.name}>
                    <Tooltip title={option.name} arrow>
                        <Chip
                            classes={{
                                root: classes.chip
                            }}
                            avatar={<Avatar classes={{
                                root: classes.avatar
                            }}>{option.name?.trim()?.charAt(0)}</Avatar>}
                            label={option.name}
                            onDelete={() => onDeleteEntity(option)}
                            variant="outlined"
                            style={{margin: "2px 2px", overflow:'hidden'}}
                            deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                        />
                    </Tooltip>
                </Fragment>))
    }

    return (
        <div style={{marginTop:'16px'}}>
            
            <Typography style={{fontSize:'14px'}}>
                Select Entities            
            </Typography>
            <Grid item style={{marginTop:'6px'}}>
                <Autocomplete
                    multiple={true}
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    
                    options={entities}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={props.fromProposalListComponent ? props.selectedEntitiesProposalFilter : props.selectedEntitiesFilter}
                    onChange={(e, newValue) => {
                        setSelectedEntities(newValue)
                    }}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Search Entity" />
                    )}
                />
            </Grid>        
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    selectedEntitiesFilter: state.invoiceFilters.selectedEntitiesFilter,

    selectedEntitiesProposalFilter: state.proposalFilters.selectedEntitiesProposalFilter
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedEntitiesToInvoiceFilter:  (entity) => dispatch(addSelectedEntitiesToInvoiceFilter(entity)),
    removeSelectedEntitiesToInvoiceFilter:  (entity) => dispatch(removeSelectedEntitiesToInvoiceFilter(entity)),

    addSelectedEntitiesToProposalFilter:  (entity) => dispatch(addSelectedEntitiesToProposalFilter(entity)),
    removeSelectedEntitiesToProposalFilter:  (entity) => dispatch(removeSelectedEntitiesToProposalFilter(entity))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteEntities);