import React, {useState, useEffect} from 'react';
import {Grid, Popover, MenuItem, Select, Typography, Button} from '@material-ui/core';
import {connect} from 'react-redux';
import {enableInvoiceRecurringDateApi, updateInvoiceRecurringDateApi, updateNextIssueDateApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { SendEmailType, setClearInvoiceAccounts } from '../actions/invoiceAccount';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useIsMount } from './useIsMount';
import {disableRecurringInvoiceApi} from '../services/authService'; 
import { onUpgradeNowClick } from '../util/AppUtil';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import {isRecurringInvoiceActive} from '../util/AppUtil';


const RecurringInvoiceSettings = (props) => {

    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [recurringInvoiceValue, setRecurringInvoiceValue] = useState('off');
    const [nextInvoiceIssueDate, setNextInvoiceIssueDate] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);

    const invoiceObj = props.invoiceObj ? props.invoiceObj : props.editInvoiceObj || null;
    const recurring_invoice_obj = invoiceObj?.recurring_invoice;
    const canCreateRecurringInvoice = props.selectedAccount?.can_create_recurring_invoice;


    useEffect(() => {
        if(invoiceObj){
            if(recurring_invoice_obj && recurring_invoice_obj.status === 'inactive') {
                setRecurringInvoiceValue('off');
            } else {
                setRecurringInvoiceValue(recurring_invoice_obj?.interval_days ? recurring_invoice_obj?.interval_days : 'off');
                setNextInvoiceIssueDate(recurring_invoice_obj?.next_issue_date);
            }
        }
    }, [invoiceObj]);

    const handleRecurringValueChange = (event) => {
        let value = event.target.value;
        setRecurringInvoiceValue(value);
        if(value !== 'off') {
            if(props.showCreateInvoice) {
                props.setIntervalDays(value);
            } else {
                if(recurring_invoice_obj && recurring_invoice_obj?.status !== 'inactive') {
                    updateInvoiceRecurringDate(value);
                } else {
                    enableInvoiceRecurringDate(value);
                }
            }
        }

        if(value === 'off') {
            disableRecurringInvoice();
        }
    };

    const updateInvoiceState = (res) => {
        let accountObj = {
            ...props.selectedAccount,
            can_create_recurring_invoice: res.can_create_recurring_invoice
        }

        let accountList = props.invoiceAccountsList.map((ia) => {
            if (ia.id === accountObj.id) return accountObj;
                else return ia;
            });

        props.setInvoiceAccountsList(accountList);
        props.setSelectedInvoiceAccount(accountObj);

        props.setUpdatedInvoiceObj(res.invoice);
        if(props.editInvoiceObj) {
            props.setEditInvoiceObj(res.invoice);
        } else {
            props.setPreviewInvoiceObj(res.invoice);
        }
    }

    const enableInvoiceRecurringDate = (value) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoiceObj?.id;
        const interval_days = value;
        const send_invoice_mail = false;

        enableInvoiceRecurringDateApi(invoice_account_id, invoice_id, interval_days, send_invoice_mail)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response enableInvoiceRecurringDateApi: ', res);
                enqueueSnackbar('Recurring Invoice Date Created', {variant:'success'});

                updateInvoiceState(res);
                const next_issue_date = res.invoice?.recurring_invoice?.next_issue_date;
                setNextInvoiceIssueDate(next_issue_date);
            })
            .catch((e) => {
                consoleToLog('Error enableInvoiceRecurringDateApi: ', e);
                setRecurringInvoiceValue(recurring_invoice_obj?.interval_days ? recurring_invoice_obj?.interval_days : '-1');
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const updateInvoiceRecurringDate = (value) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoiceObj?.id;
        const interval_days = value;
        const send_invoice_mail = false;
        const recurring_invoice_id = recurring_invoice_obj?.id;

        updateInvoiceRecurringDateApi(invoice_account_id, invoice_id, recurring_invoice_id, interval_days, send_invoice_mail)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateInvoiceRecurringDateApi: ', res);
                enqueueSnackbar('Recurring Invoice Date Updated', {variant:'success'});

                const next_issue_date = res.recurring_invoice?.next_issue_date;
                setNextInvoiceIssueDate(next_issue_date);
                updateInvoiceState(res);
                
            })
            .catch((e) => {
                consoleToLog('Error updateInvoiceRecurringDateApi: ', e);
                setRecurringInvoiceValue(recurring_invoice_obj?.interval_days ? recurring_invoice_obj?.interval_days : '-1');
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const handleNextIssueDatePopOverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNextIssueDatePopOverClose = () => {
        setAnchorEl(null);
    }

    const handleNextIssueDatePopOver = () => {
        return (<Popover
            id={'next-issue-date-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleNextIssueDatePopOverClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            style={{position:'absolute', left:'-50px', top:'10px', overflow:'auto'}}
        >
            <DatePicker
                selected={moment(nextInvoiceIssueDate).toDate()}
                onChange={onNextIssueDateChange}
                //selectsRange
                inline
            />
        </Popover>)
    }

    const onNextIssueDateChange = (dates) => {
        setNextInvoiceIssueDate(dates);
        setAnchorEl(null);
        let customDate = moment(dates).format('YYYY-MM-DD'); 
        updateNextIssueDate(customDate)
    }

    const updateNextIssueDate = (customDate) => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoiceObj?.id;
        const recurring_invoice_id = recurring_invoice_obj?.id;
        const next_issue_date = customDate;

        updateNextIssueDateApi(invoice_account_id, invoice_id, recurring_invoice_id, next_issue_date)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateNextIssueDateApi: ', res);
                enqueueSnackbar('Recurring Invoice Next Issue Date Updated', {variant:'success'});

                const next_issue_date = res?.next_issue_date;
                setNextInvoiceIssueDate(next_issue_date);
                updateInvoiceState(res);

            })
            .catch((e) => {
                consoleToLog('Error updateNextIssueDateApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const getNextIssueDate = () => {
        const daysToAdd = recurringInvoiceValue;

        let duration, unit;

        // Map days to the appropriate duration and unit
        if (daysToAdd === 30) {
            duration = 1;
            unit = 'months';
        } else if (daysToAdd === 90) {
            duration = 3;
            unit = 'months';
        } else if (daysToAdd === 180) {
            duration = 6;
            unit = 'months';
        } else if (daysToAdd === 365) {
            duration = 1;
            unit = 'years';
        } else {
            duration = daysToAdd;
            unit = 'days';
        }
        let updatedDateAfterDays = moment(props.issueDate).add(duration, unit);
        return moment(updatedDateAfterDays).format('Do-MMMM-YYYY');
    }


    const disableRecurringInvoice = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = invoiceObj?.id;
        const recurring_invoice_id = recurring_invoice_obj?.id;

        disableRecurringInvoiceApi(invoice_account_id, invoice_id, recurring_invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response disableRecurringInvoiceApi: ', res);
                enqueueSnackbar('Recurring Invoice disabled', {variant:'success'});

                const next_issue_date = res.recurring_invoice?.next_issue_date;
                setNextInvoiceIssueDate(next_issue_date);
                updateInvoiceState(res);
                
            })
            .catch((e) => {
                consoleToLog('Error disableRecurringInvoiceApi: ', e);
                setRecurringInvoiceValue(recurring_invoice_obj?.interval_days ? recurring_invoice_obj?.interval_days : '-1');
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onUpgradeNowButtonClick = () => {
        if(props.showCreateInvoice) {
            props.setShowCreateInvoice(false);
        }
        if(Object.keys(props.editInvoiceObj).length > 0) {
            props.setEditInvoiceObj({});
        }
        if(Object.keys(props.invoiceObj).length > 0) {
            props.setPreviewInvoiceObj({});
        }
        onUpgradeNowClick(props);
        props.handleInvoiceDialogClose();
    }

    return (
        <div>
            <Grid item container direction='column' 
                className="save_invoice"
                style={{marginTop:'16px'}}
            >
                    <Grid item container direction="column" style={{position:'relative'}}>
                        <Typography style={{color:'#4a5568', fontSize:'15px', fontWeight:500, margin:'-8px 0px 10px 0px'}}>
                            Recurring Invoice Settings
                        </Typography>

                        <Grid item md={12} className='interval'>
                            <Typography className='font_styles2'>
                                Interval 
                            </Typography>
                            <Select  value={recurringInvoiceValue}
                                fullWidth
                                onChange={handleRecurringValueChange}
                                variant="outlined"
                                margin='dense'
                                autoWidth
                                MenuProps={{
                                    style: {width: '250px'}
                                }}
                                disabled={!canCreateRecurringInvoice && !isRecurringInvoiceActive(recurring_invoice_obj)}
                                >
                                    <MenuItem value={'off'}>Off</MenuItem>
                                    <MenuItem value={14}>2 weeks</MenuItem>
                                    <MenuItem value={30}>1 month</MenuItem>
                                    <MenuItem value={90}>3 months</MenuItem>
                                    <MenuItem value={180}>6 months</MenuItem>
                                    <MenuItem value={365}>1 year</MenuItem>
                            </Select>
                        </Grid>

                        {recurring_invoice_obj && recurring_invoice_obj?.status === 'active' && 
                        <Grid item md={12} style={{marginTop:'16px'}}>
                            <Typography className='font_styles2'>
                                Next Issue Date
                            </Typography>
                            <Button variant='outlined'
                                disabled={!canCreateRecurringInvoice && !isRecurringInvoiceActive(recurring_invoice_obj)}
                                fullWidth
                                color='primary' 
                                style={{fontWeight:"300 !important", border: '1px solid #ccc'}}
                                onClick={handleNextIssueDatePopOverOpen}>
                                {`${moment(nextInvoiceIssueDate).format('Do-MMMM-YYYY')}`}
                            </Button>
                            {handleNextIssueDatePopOver()}
                        </Grid>
                        }

                        {props.intervalDays && props.showCreateInvoice &&
                        <Grid item md={12} style={{marginTop:'16px'}}>
                            <Typography className='font_styles2'>
                                Next Issue Date
                            </Typography>
                            <Button variant='outlined'
                                disabled={props.showCreateInvoice || !canCreateRecurringInvoice}
                                fullWidth
                                color='primary' 
                                style={{fontWeight:"300 !important", border: '1px solid #ccc'}}
                                onClick={handleNextIssueDatePopOverOpen}>
                                    {getNextIssueDate()}
                            </Button>
                        </Grid>}
                    </Grid>

                    {!canCreateRecurringInvoice && !isRecurringInvoiceActive(recurring_invoice_obj) &&
                    <Grid item md={12} style={{marginTop:'16px'}} className={'upgradeMessageStyle'}>
                        <Typography variant='body2'>
                            With free plan, you can only create one recurring invoice. 
                            <span className='link_style1' onClick={onUpgradeNowButtonClick} style={{marginRight:'4px'}}>Upgrade Now</span>
                            to create unlimited recurring invoices.
                        </Typography>
                    </Grid>}

            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),

    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringInvoiceSettings);