import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress, Tooltip, Divider} from '@material-ui/core';
import {connect} from 'react-redux';
import { useIsMount } from '../useIsMount';
import TransactionsComponent from '../TransactionsComponent';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: "20px",
        fontWeight: "600",
    },
    transactionContainer: {
        background:'#f9fafb',
        borderBottom: '1px solid #edf2f7',
    },
    transactionStyle: {
        minWidth:"100%", 
        borderRadius:'4px', 
        color:'#2B2B2B',
    },
    transactionListItemStyle: {
        padding:'16px 16px',
        position:'relative',
        '&:hover' : {
            backgroundColor:'#f7fafc',
            cursor:'pointer'
        }
    },
    customColumn1 : {
        flexBasis:'3.5%',
        maxWidth:'3.5%'
    },
    customColumn2 : {     
        flexBasis:'14%',
        maxWidth:'14%'
    },
    customColumn3 : {
        flexBasis:'11%',
        maxWidth:'11%'
    },
    customColumn4 : {
        flexBasis:'18%',
        maxWidth:'18%'
    },
    customColumn5 : {
        flexBasis:'2%',
        maxWidth:'2%'
    },
    customColumn6 : {
        flexBasis:'30%',
        maxWidth:'30%'
    },
    customColumn7 : {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn8 : {
        flexBasis:'9%',
        maxWidth:'9%'
    },
    loading: {
        position:'relative',
        left:'50%',
        right:'50%',
        marginTop:'3%'
    },
    textEllipsis: {
        maxWidth: '40ch !important',
        whiteSpace:  'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        [theme.breakpoints.down(1400)] : {
            maxWidth: '34ch !important',
        }
    },
    seeInvoiceStyles: {
        color:'#5b51bf',
        cursor:'pointer',
        marginLeft:'3px',
        '&:hover' : {
            color:'#738bed'
        }
    },
}))

const ClientTransactions = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const [clientTransactionList, setClientTransactionList] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [id, setId] = useState(props.selectedAccount?.id);
    const [items, setItems] = useState([]);
    const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState(false);
    const [invoiceDetailObj, setInvoiceDetailObj] = useState(undefined);

    // useEffect(() => {
    //     getClientTransactions();
    // }, [props.selectedAccount?.id]);

    // useEffect(() => {
    //     setId(props.selectedAccount?.id)
    // }, [props.selectedAccount?.id]);

    // useEffect(() => {
    //     if(!isMount) {
    //         getClientTransactions();
    //     }
    // }, [props.selectedAccount?.id]);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(isMoreLoading) {
    //             getClientTransactions();
    //         }
    //     }
    // }, [isMoreLoading]);

    useEffect(() => {
        if(!isMount) {
            if(props.updateTranscItemFromParent && props.addTransaction) {
                addToTransactionList();
            }
        }
    }, [props.updateTranscItemFromParent]);

    const handleOpenInvoiceDetailModal = (e, transactionObj) => {
        //trackGAEvent(props.selectedAccount.name, 'Transaction list item - See Invoice link clicked');
        setOpenInvoiceDetailsModal(true);
        //setInvoiceDetailObj(transactionObj.invoice);
        props.handleInvoiceDialogOpen();
    }

    const handleCloseInvoiceDetailModal = () => {
        setOpenInvoiceDetailsModal(false);
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    const addToTransactionList = () => {
        setClientTransactionList(oldTransactions => [props.updateTranscItemFromParent, ...oldTransactions]);
        props.setUpdateTranscItemFromParent(undefined);
        props.setAddTransaction(false);
    }


    return (
        <Grid item xs={12} className={classes.detailsContainer}>

            <TransactionsComponent clientInfo={props.clientInfo}
                handleInvoiceDialogOpen={props.handleInvoiceDialogOpen}
                updateTranscItemFromParent={props.updateTranscItemFromParent}
                setUpdateTranscItemFromParent={props.setUpdateTranscItemFromParent}
                setAddTransaction={props.setAddTransaction}
                addTransaction={props.addTransaction}
                fromClientDetails={true}
                clientComponentObj={props.clientComponentObj}
                tabValue={props.tabValue}
                showClientInfo={props.showClientInfo}
                scrollContainer={props.scrollContainer}
            />

        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});


export default connect(mapStateToProps)(ClientTransactions);