import React, {useState, useEffect} from 'react';
import {Grid, Typography, TextField, Select, MenuItem, InputAdornment, Tooltip, IconButton, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { AccessLevel, shouldDisable, trackGAEvent, consoleToLog, capitalizeFirstLetterOfEachWord, 
    showAllCountries, showAllStates, parseString, phoneNumberValidate } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { orgNameValidator, emailValidator, mobileNoValidator, nameValidator } from '../../util/validator';
import {setSelectedInvoiceAccount, setInvoiceAccountsList} from '../../actions/invoiceAccount';
import {createUpdateInvoiceAccountEntityApi} from '../../services/authService';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from '../useIsMount';
import {connect} from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import {updateEntityEsignApi} from '../../services/authService';
import {getImageDownloadUrlApi} from '../../services/authService';
import EnableInvoiceESignModal from '../modals/EnableInvoiceESignModal';
import { verifyInvoiceAccountEntityEmailApi } from '../../services/authService';
import countryState from '../../data/countryState.json';
import country from "../../data/country.json";
import { getCountryObj, getStateObj, isInvoiceAccountCountryIndia } from '../../util/AppUtil';
import { Autocomplete } from '@material-ui/lab';
import { downloadAttachmentApi } from '../../services/uploadService';
import { AttachmentSubTypes } from '../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        //border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    labelStyles: {
        lineHeight:1, 
        color:'#555555'
    },  
    MuiFormControlLabellabel :{
        marginLeft:'-4px'
    },
    imgContainer :{
        marginLeft:'12rem',
        padding:'10px',
        width:'100px',
        height:'100px',
        borderRadius:'50%',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box'
    },
    uploadImg: {
        position:'relative',
        left:'-30px',
        top:'70px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },  
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    },
    customMarginTop : {
        marginTop:'16px'
    },
    customMarginBottom: {
        marginBottom: '-6px'
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    signatureStyles: {
        border:'1px solid rgba(0,0,0,0.1)',
        padding:"8px",
        width:'75px',
        height: '35px',
        borderRadius:'5px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    }
}))

const OnboardingEntityUpdate = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [attachment, setAttachment] = useState(undefined);
    const [entityName, setEntityName] = useState('');
    const [entityEmail, setEntityEmail] = useState('');
    const [entityAddress, setEntityAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedState, setSelectedState] = useState({});
    const [entityMobile, setEntityMobile] = useState(null);
    const [entityTaxId, setEntityTaxId] = useState('')
    const [donResetEntityInfo, setDontResetEntityInfo] = useState(false);
    const [entityClientNotes, setEntityClientNotes] = useState('');
    const [entityPaymentTerms, setEntityPaymentTerms] = useState('');
    const [signUrl, setSignUrl] = useState(undefined);
    const [signUrlLoading, setSignUrlLoading] = useState(false);
    const [openESignDialog, setOpenESignDialog] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [upiIdText, setUpiIdText] = useState('');


    const { access_level } = props.selectedAccount || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const isEmailVerified = props.entityObj?.is_email_verified;
   
    const invoice_account_id = props.selectedAccount?.id;
    const entity_id = props.entityObj ? props.entityObj?.id : undefined;
    const {countries} = country;
    const {states} = countryState;


    let acc_name = props.entityObj?.name;
    let acc_email = props.entityObj?.email;
    let acc_address = props.entityObj?.address;
    let acc_country = props.entityObj?.country;
    let acc_state = props.entityObj?.state;
    let acc_mobile = props.entityObj?.mobile;
    let acc_tax_id = props.entityObj?.tax_id;
    let acc_client_notes = props.entityObj?.default_client_notes;
    let acc_payment_terms = props.entityObj?.default_payment_terms;
    let acc_upi_id = props.entityObj?.upi_id;

    // useEffect(() => {
    //     getDownloadSignedUrl();
    // }, []);
    useEffect(() => {
        
    }, []);

    useEffect(() => {
            if(props.entityObj && !donResetEntityInfo) {
                setEntityName(acc_name);
                setEntityEmail(acc_email);
                setEntityAddress(acc_address);

                let countryObj = getCountryObj(countries, props.selectedAccount?.country);
                setSelectedCountry(countryObj ? countryObj : {});

                const state_list = states.filter((state) => state.country_code === props.selectedAccount?.country);
                setStateList(state_list);

                let stateObj = getStateObj(states, acc_state, props.entityObj?.country);
                console.log('stateObj', stateObj);
                setSelectedState(stateObj ? stateObj : {});

                setEntityMobile(acc_mobile);
                setEntityTaxId(acc_tax_id);
                setEntityClientNotes(acc_client_notes);
                setEntityPaymentTerms(acc_payment_terms);
                setUpiIdText(acc_upi_id);
                setAttachment(undefined);
                if(props.entityObj?.enable_esign) {
                    getDownloadSignedUrl();
                }
            }
    }, [props.entityObj]);

    useEffect(() => {
        if(!isMount) {
            if(props.entityObj && donResetEntityInfo) {
                if(props.entityObj?.enable_esign) {
                    getDownloadSignedUrl();
                }
            }
        }
    }, [props.entityObj]);


    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end" onClick={() => {
                if (!disabled && !props.entityObj.is_email_verified) onVerifyInvoiceAccountEmail()
            }}>
                <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {isEmailVerified ? 
                            <CheckSharpIcon fontSize="small" style={{fontSize:'18px'}}/> 
                            : 
                            <>
                                <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold', fontSize:'15px'}}/>
                                <Typography style={{color:"#0071FF"}}>Verify </Typography>
                            </>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    }

    const onInvoiceAccountPhotoSelected = (e) => {
        consoleToLog("onOrgPhotoSelected",e.target.files[0]);
        setAttachment (e.target.files[0])
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);
        oFReader.onload = function (oFREvent) {
            document.getElementById("input_InvoiceAccountPhoto").src = oFREvent.target.result;
        };
    }

    const onClientCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        props.editClientObj && props.setClientCountry(e.target.value);
    }

    const onClientStateChange = (e) => {
        setSelectedState(e.target.value);
        props.editClientObj && props.setClientState(e.target.value);
    }

    const handleEnableInvoiceESignModalOpen = () => {
        setOpenESignDialog(true);
    }

    const handleEnableInvoiceESignModalClose = () => {
        setOpenESignDialog(false);
    }

    const handleChange = (updateEsignDrawing) => {
        if(!updateEsignDrawing && props.entityObj?.enable_esign && props.entityObj?.signature) {
            updateEntityESign(false);
            setDontResetEntityInfo(true);
        } else if(!updateEsignDrawing && !props.entityObj?.enable_esign && props.entityObj?.signature) {
            updateEntityESign(true);
            setDontResetEntityInfo(true);
        } else {
            handleEnableInvoiceESignModalOpen();
        }
    }

    const getDownloadSignedUrl = async() => {
        
        setSignUrlLoading(true);
        try {
            const response = await downloadAttachmentApi(invoice_account_id, AttachmentSubTypes.ENTITY_SIGNATURE, props.entityObj?.signature);
            const res = response;
            consoleToLog("Response downloadAttachmentApi",res);

            setSignUrl(res.signed_url);
            setSignUrlLoading(false);

        } catch(e) {
            consoleToLog("downloadAttachmentApi error", e.response);
            setSignUrlLoading(false);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        }   
    }

    const updateEntityESign = (enable_esign) => {

        const signature = props.entityObj?.signature;

        updateEntityEsignApi(invoice_account_id, entity_id, signature, enable_esign)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEntityEsignApi', res);

                enqueueSnackbar('Invoice e-sign settings updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === entity_id) {
                            item.signature = res.signature;
                            item.enable_esign = res.enable_esign;
                        }
                        return item
                    })
                };

                let obj = {
                    ...props.entityObj,
                    signature: res.signature,
                    enable_esign: res.enable_esign,
                }
                props.setEntityObj(obj);

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                setDontResetEntityInfo(false);

            })
            .catch((e) => {
                consoleToLog("Error updateEntityEsignApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onUpdateClick = () => {

        const invoice_account_name = entityName;
        if (!invoice_account_name) {
            enqueueSnackbar('Invoice account name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isInvoiceAccountNameValid = orgNameValidator(invoice_account_name);
        if (!isInvoiceAccountNameValid) {
            enqueueSnackbar('Invoice account name is not valid! Please enter Alphabets only', {
                variant:'error'
            });
            return;
        }

        const invoice_account_email = entityEmail;
        if (!invoice_account_email) {
            enqueueSnackbar('Invoice account email can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isInvoiceAccountEmailValid = emailValidator(invoice_account_email);
        if (!isInvoiceAccountEmailValid) {
            enqueueSnackbar('Email is not valid!', {
                variant:'error'
            });
            return;
        }

        if(Object.keys(selectedCountry).length === 0) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        }
        const country = selectedCountry?.code;

        if(Object.keys(selectedState).length === 0) {
            enqueueSnackbar('Please Select the State', {
                variant: 'error'
            });
            return;
        }

        let state = selectedState?.state_code;
        let address = entityAddress;
        if (!address) {
            enqueueSnackbar('Address cannot be empty!', {
                variant:'error'
            });
            return;
        }
        address = address.trim();

        const mobile = entityMobile ? entityMobile : '';
        const isMobileNoValid = mobileNoValidator(mobile);
        if (mobile && !isMobileNoValid) {
            enqueueSnackbar('Please Enter Valid Mobile No.', {
                variant:'error'
            });
            return;
        }

        const tax_id = entityTaxId ? entityTaxId : ''
        const default_client_notes = entityClientNotes ? entityClientNotes : '';
        const default_payment_terms = entityPaymentTerms ? entityPaymentTerms : '';
        const new_image = attachment ? attachment : undefined;
        const upi_id = upiIdText ? upiIdText : '';
        
        createUpdateInvoiceAccountEntityApi(invoice_account_id, capitalizeFirstLetterOfEachWord(invoice_account_name), address, 
            country, state, invoice_account_email, mobile, tax_id, default_client_notes, default_payment_terms, new_image, entity_id, upi_id)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response invoiceAccountEntityUpdateApi: ", res);

                if(entity_id) {
                    let account = {
                        ...props.selectedAccount,
                        entities: props.selectedAccount.entities.map((entityItem) => {
                            if(entityItem.id === res.id) {
                                entityItem = res;
                            }
                            return entityItem
                        })
                    };

                    let accountList = props.invoiceAccountsList.map((ia)=>{
                        if(ia.id === account.id) return account;
                        else return ia;
                    });
                    props.setInvoiceAccountsList(accountList);
                    props.setSelectedInvoiceAccount(account);

                    let obj = {
                        ...res,
                    }

                    props.setEntityObj(obj);
                    
                } 

                enqueueSnackbar("Inovice account enitity info updated", {variant: 'success'});

                //trackGAEvent(props.selectedOrganization.organization.name, ' Save Organization', `${props.auth.user.firstname} ${props.auth.user.lastname} saved organization`);
            })
            .catch((e) => {
                consoleToLog("invoiceAccountUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onVerifyInvoiceAccountEmail = () => {

        verifyInvoiceAccountEntityEmailApi(invoice_account_id, entity_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response verifyInvoiceAccountEntityEmailApi: ", res)
            enqueueSnackbar(res.message, {
                variant:'success'
            });
          //trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email verification link from settings`);
        })
        .catch((e) => {
            consoleToLog("verifyInvoiceAccountEntityEmailApi error", e);
    
            if (e && e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'success'
                });
                return false;
            }
        });
    }

    const onCountryNameChange = (e, newValue) => {
        setSelectedCountry(newValue);
        setSelectedState({});
    }

    const onStateNameChange = (e, newValue) => {
        setSelectedState(newValue ? newValue : {});
    }

    return (
        <div style={{width:'50%'}}>
            <Grid item md={8} lg={8} style={{margin:'16px 0px'}}>
                <div style={{display:'flex', justifyContent:'center', position:'relative'}}>
                    <img className={classes.imgContainer}
                        src={props.entityObj?.image ? props.entityObj?.image : "/images/eprocessify-icon.png"}
                        id="input_InvoiceAccountPhoto"
                        alt="invoiceAccountPic"
                    />
                    <label className={classes.uploadImg}>
                    <Typography style={{marginLeft:'4px', marginTop:'3px'}}>
                        <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                    </Typography>
                        <input style={{display:'none'}}
                            type="file"
                            id="input_InvoiceAccountPhoto"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onInvoiceAccountPhotoSelected}
                            disabled={disabled}
                        />
                    </label>
                </div>
            </Grid>  

            <Grid item md={12} lg={12}>
                <Typography variant="body2" className={classes.customMarginBottom}>
                    Entity Name
                </Typography>

                <TextField 
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={entityName}
                    placeholder="Enter Entity Name"
                    InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                    disabled={disabled}
                    onChange={(e) => setEntityName(e.target.value)}
                />
            </Grid> 

            <Grid item container alignItems='center' spacing={1} style={{marginTop:'12px'}}>

                <Grid item md={6} lg={6}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        Email   
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={entityEmail}
                        placeholder="Enter Entity Email"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        onChange={(e) => setEntityEmail(e.target.value)}  
                        InputProps={{
                            endAdornment: props.entityObj && endAdornmentIcon()
                        }}

                    />
                </Grid>

                <Grid item md={6} lg={6}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        Mobile (Optional)    
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={entityMobile !== null ? entityMobile : ''}
                        placeholder="Enter Entity Mobile Number"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setEntityMobile(e.target.value)}  
                        onKeyPress={(e) => phoneNumberValidate(e)}
                    />
                </Grid>
            </Grid>

            <Grid item md={12} lg={12} className={classes.customMarginTop}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        Addresss
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={parseString(entityAddress)}
                        placeholder="Enter Entity Address"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setEntityAddress(e.target.value)}  
                        multiline
                        maxRows={4}
                    />
                </Grid>
            
            <Grid item container alignItems='center' spacing={1} style={{marginTop:'12px'}}>
                <Grid item md={6} lg={6}>
                    <Typography variant="subtitle1">
                        Country
                    </Typography>   

                    <Autocomplete
                        id="combo-box-demo"
                        options={countries}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={selectedCountry}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            onCountryNameChange(e, newValue)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search Country' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                        disabled={true}
                    />
                </Grid>

                <Grid item md={6} lg={6}>
                    <Typography variant="subtitle1">
                        State
                    </Typography>   

                    <Autocomplete
                        id="combo-box-demo"
                        options={stateList}
                        classes={{
                            root: classes.MuiAutocompleteinputRoot
                        }}
                        value={selectedState}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            onStateNameChange(e, newValue)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" 
                            placeholder='Search State' 
                            fullWidth
                            classes={{
                                root: classes.customTextField
                            }}
                                />
                        )}
                    />
                </Grid>

                {/* <Grid item md={12} lg={12}>
                    <Typography variant="subtitle1">
                        Country
                    </Typography>   

                    <Select 
                        className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                        value={selectedCountry}
                        onChange={onClientCountryChange}
                        variant="outlined"
                        placeholder='Select Country'
                        autoWidth
                        disabled={disabled}
                        style={{width:'100%'}}
                        >
                        {
                            selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                        } 
                        {showAllCountries()}
                    </Select>
                </Grid> */}
            </Grid>

            <Grid item md={12} lg={12} className={classes.customMarginTop}>
                <Typography variant="body2" className={classes.customMarginBottom}>
                    Tax Id (Optional)
                </Typography>

                <TextField 
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={entityTaxId}
                    placeholder="Enter Entity Tax Id"
                    InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                    disabled={disabled}
                    onChange={(e) => setEntityTaxId(e.target.value)}
                />
            </Grid> 

            <Grid item container alignItems='center' spacing={1} style={{marginTop:'12px'}}>
                <Grid item md={6} lg={6}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        Client Notes (Optional)    
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={entityClientNotes}
                        placeholder="Enter Invoice Client Notes"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setEntityClientNotes(e.target.value)} 
                        multiline
                        maxRows={4} 
                    />
                </Grid>

                <Grid item md={6} lg={6}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        Payment Terms (Optional)    
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={entityPaymentTerms}
                        placeholder="Enter Invoice Payment Terms"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setEntityPaymentTerms(e.target.value)}  
                        multiline
                        maxRows={4}
                    />
                </Grid>

            </Grid>

            {isInvoiceAccountCountryIndia(props.selectedAccount?.country) &&
                <Grid item md={12} lg={12}  className={classes.customMarginTop}>
                    <Typography variant="body2" className={classes.customMarginBottom}>
                        UPI ID (Optional)
                    </Typography>

                    <TextField 
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={upiIdText}
                        placeholder="Enter Entity UPI ID"
                        InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        disabled={disabled}
                        onChange={(e) => setUpiIdText(e.target.value)}
                    />
                </Grid> }

                {props.entityObj &&
                    <>
                    <Grid item md={6} lg={6} className={classes.customMarginTop}>
                        <Typography variant="body1">
                            Invoice E-Signature
                        </Typography>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.entityObj?.enable_esign ? props.entityObj?.enable_esign : false}
                                    onChange={() => handleChange(false)}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Enable E-Sign"
                        />
                    </Grid> 

                    {props.entityObj?.enable_esign &&
                    <Grid item md={6} lg={6} className={classes.customMarginTop}>
                        {signUrlLoading ?
                            <CircularProgress size={16} style={{marginLeft:"16px"}}/>
                            :
                            signUrl &&
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <img src={signUrl} className={classes.signatureStyles} />
                                </Grid>

                                <Grid item>
                                    <Tooltip title={'Edit E-Sign'} arrow>
                                        <Button style={{minWidth:'20px', marginLeft:'8px'}}
                                            onClick={() => handleChange(true)}>
                                            <EditIcon style={{fontSize:'20px'}}/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                    </Grid>}
                    </>}
            
            <Grid item md={3} style={{margin:'24px auto 0px auto'}}>
                <Button variant='contained' color='primary'
                    onClick={onUpdateClick}
                    fullWidth>
                    Save
                </Button>
            </Grid>

            {openESignDialog && <EnableInvoiceESignModal openESignDialog={openESignDialog}
                handleEnableInvoiceESignModalClose={handleEnableInvoiceESignModalClose}
                entityObj={props.entityObj}
                setEntityObj={props.setEntityObj}
                fromOnboardingComponent={true}
                />
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingEntityUpdate);