import React from 'react';
import {Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { PeriodicityArr, lightenColor } from '../../../util/AppUtil';
import { formatAmount } from '../../../util/currencyUtil';
import BackgroundPreview from './BackgroundPreview';

const BillingsPreview = ({item, index, normalUserPreview, currency, brand_color}) => {

    const {data} = item;
    const {title, quantity_terminology, show_quantity, items} = data;
    const lighterColor = lightenColor(brand_color, 95);

    return (
        <BackgroundPreview item={item} title={title} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >

            <Grid item md={12}>
                <TableContainer component={Paper} variant='outlined' >
                    <Table>
                        <TableHead className='preview_table_head'>
                            <TableRow>
                                <TableCell className="add_item_table_heading">Item name</TableCell>
                                <TableCell className={`add_item_table_heading`}>
                                    {quantity_terminology}
                                </TableCell>
                                <TableCell className="add_item_table_heading">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{background:lighterColor}}>
                            {items && items.length > 0 && items.map((item, index) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography className='add_item_table_descp'>
                                            {item.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={`add_item_table_descp`}>
                                            {item.quantity}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className='add_item_table_descp'>
                                            <strong>{formatAmount(item.price, currency)}</strong>
                                            <strong>{` ${PeriodicityArr.find((period) => period.value === item.periodicity)?.display_value}`}</strong>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
        </BackgroundPreview>
    );
}

export default BillingsPreview;