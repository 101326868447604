import React from 'react';
import BaseSection from './BaseSection';
import {Grid, Typography} from '@material-ui/core';
import { ReadOnlyEditor } from './BaseEditor';

const WhyUs = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {title, description, bullet_points} = data;

    return (
        <BaseSection title="Why Us?" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>
                
                <Grid item md={12} className='edit_section_container'>

                    <Grid item md={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: title}} />
                    </Grid>

                    <Grid item md={12}>
                        <ReadOnlyEditor editorValue={description}/>
                        
                    </Grid>

                    <Grid item md={12}>
                        <div className='edit_section_item_container why_us_bulletpoints'>
                            {
                                bullet_points && bullet_points.length > 0 &&
                                bullet_points.map((item) => {
                                    let description = `__${item.title}__: ${item.description}`
                                    return (
                                        <ReadOnlyEditor editorValue={description}/>
                                    )
                                })
                            }
                        </div>
                    </Grid>
                        
                </Grid>

        </BaseSection>
    );
};

export default WhyUs;
