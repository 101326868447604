import React, {useEffect, useState} from 'react';
import {getCurrencyObjApi} from '../services/authService';
import { consoleToLog, extractFlagCode } from '../util/AppUtil';
import {Grid, Typography, InputAdornment, TextField} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Flags from "country-flag-icons/react/3x2";
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    itemOptionStyle: {
        width: "100%", 
        height:'100%',
        color:'#777f8e',
        margin:'5px 0px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0px 3px',
        borderRadius: '4px',
        background:'#fff',
        '& .MuiAutocomplete-input' : {
            padding:'7.5px 4px'
        }
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    }
}))

const AutocompleteCurrencies = ({selectedCurrency, setSelectedCurrency, fromBillingsDrawer, proposalCurrency, setDirtyFlag, ...props}) => {
    const classes = useStyles();    
    const {enqueueSnackbar} = useSnackbar();

    const {selectedAccount} = props;

    const [currencies, setCurrencies] = useState([]);
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        getCurrencies(fromBillingsDrawer ? proposalCurrency?.name : selectedAccount.currencies?.name, true);
    }, [])

    const invoice_account_id = selectedAccount.id;
    const currenciesObj = fromBillingsDrawer ? proposalCurrency : selectedAccount.currencies;


    const getCurrencies = (query, setCurrencyObj) => {
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })
    }

    const renderCurrencyOptions = (option) => {
        const flagCode =  extractFlagCode(option.flag);
        const Flag = Flags[flagCode];
        return (
            <Grid item md={12} className={classes.itemOptionStyle}>
                <Grid item container alignItems='center'> 
                    <Grid item md={1}>
                        <div style={{position:'relative', top:'1px'}}>
                            <Flag width='28px' height='22px'/>
                        </div>
                    </Grid>
                    <Grid item md={8}>
                        <Typography variant='body1' style={{marginLeft:'8px'}}>
                            {`${option.name}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            );
    }

    const onSelectedCurrencyChange = (e, newValue) => {
        setSelectedCurrency(newValue);
        fromBillingsDrawer && setDirtyFlag(true);
        //console.log('selected currency', newValue);
    }

    const showFlagStartAdornment = () => {
        const flagCode =  extractFlagCode(selectedCurrency?.flag);
        const Flag = Flags[flagCode];

        if (!Flag) {
            //console.error(`Flag component for code ${selectedCurrency?.code} not found.`);
            return null; // Return null if the Flag is undefined
        }
    

        return ( 
                <InputAdornment position="start">
                    <Grid item container alignItems='center'>
                        <Grid item style={{paddingLeft:'6px', position:"relative", top:'1px'}}>
                            <Flag width='25px'/>
                        </Grid>
                    </Grid>
                </InputAdornment>
            )
    }

    const handleInputChangeForCurrency = (e, newValue) => {
        const value = e?.target.value;
        value && getCurrencies(newValue, false);

        setInputText(newValue);
            if (!newValue) {
                setShowDropdown(false);
            }
    }

    return (
        <div style={{marginTop:'6px'}}>
            <Autocomplete
                id="combo-box-demo"
                options={currencies}
                classes={{
                    root: classes.MuiAutocompleteinputRoot
                }}
                open={showDropdown}
                onOpen={() =>  {
                    if(inputText) {
                        setShowDropdown(true)
                    }
                }}
                onClose={() => setShowDropdown(false)}
                value={selectedCurrency}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => renderCurrencyOptions(option)}
                onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
                onInputChange = {(e, newValue) => handleInputChangeForCurrency(e, newValue)}
                renderInput={params => (
                    <TextField {...params} variant="outlined" 
                    placeholder='Search Currency' 
                    fullWidth
                    classes={{
                        root: classes.customTextField
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                            {showFlagStartAdornment()}
                            {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                        />
                )}
                
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(AutocompleteCurrencies);