import React from 'react';
import BaseSection from './BaseSection';
import {Grid, Typography, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {showSocialMediaImages} from '../../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    coverPageContainer : {
        display:'flex',
        flexDirection:'column',
        gap:'140px'
        
    },
    imageContainer: {
        width: '10%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    logoImage: {
        width: '98%',
        objectFit:'cover',
        objectPosition:'cover'
    },
    companyName: {
        fontSize:'16px',
        fontWeight: 600, 
        position:'relative', 
        top:'-1px'
    },
    title: {
        fontSize: '52px',
        fontWeight: 700
    },
    preparedLabelText: {
        fontSize: '14px',
        fontWeight: 600
    },
    customClass: {
        flexBasis:'9%',
        maxWidth:'9%'
    }
}))

const CoverPage = ({item, handleDrawerOpen, onShowOrHidePage, proposalObj}) => {

    const classes = useStyles();
    
    const {data} = item;
    const {client_company_name, title, client_first_name, client_last_name, show_company_logo, show_company_name, website, social_links} = data;
    const entity_name = proposalObj?.entity.name;
    const entity_logo = proposalObj?.entity.logo_url;

    const onWebsiteUrlClick = () => {
        window.open(website, '_blank');
    }

    return (
        <BaseSection title="Cover Page" 
            item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

                <div className={classes.coverPageContainer}>
                    <Grid item md={12}>
                        <Grid item container alignItems='center'>
                        {show_company_logo && 
                            <Grid item md={1} className={classes.customClass}>
                                    {show_company_logo && <img className={classes.logoImage} src={entity_logo ? entity_logo : '/images/entity_business-and-trade.png'} alt="Company logo" />}
                            </Grid>
                        }

                            <Grid item>
                                <Typography className={classes.companyName} style={{marginLeft:show_company_logo ? '6px' : '0px'}}>
                                    {show_company_name && entity_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item md={12}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>

                    <Grid item md={12}>
                        <Grid item container alignItems='flex-end' justifyContent='space-between'>
                            <Grid item>
                                <Typography className={classes.preparedLabelText}>
                                    Prepared for:
                                </Typography>

                                {(client_first_name || client_last_name) &&
                                <Typography className='drawer_list_items_descp'>
                                    {`${client_first_name} ${client_last_name}`}
                                </Typography>}

                                <Typography>
                                    {client_company_name}
                                </Typography>
                            </Grid>

                            <Grid item style={{position:"relative", top:"-4px"}}>
                                {website &&
                                <Typography 
                                    onClick={onWebsiteUrlClick}
                                    className='drawer_link_style'>
                                    {website}
                                </Typography>}
                                <Grid item container alignItems='center' justifyContent='flex-end' spacing={1}>
                                    
                                        {
                                            social_links && social_links.length > 0 &&
                                            social_links.map((item) => {
                                                return <Grid item key={item.type}>
                                                            <Link href={item.link} target='blank'>
                                                                <img src={(showSocialMediaImages(item.type))} width={'24px'} height={'24px'}/>
                                                            </Link>
                                                        </Grid>
                                            })
                                        }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

        </BaseSection>
    );
};

export default CoverPage;
