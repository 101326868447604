import React,{useState, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField, Grid, Avatar, Chip, Typography} from '@material-ui/core';
import {getClientListApi} from '../../services/authService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { useIsMount } from '../useIsMount';
import {addSelectedClientToInvoiceFilter, deleteSelectedClientFromInvoiceFilter} from '../../actions/invoiceFilters';
import {addSelectedClientToTransactionFilter, removeSelectedClientFromTransactionFilter} from '../../actions/transactionFilters';
import {addSelectedClientToProposalFilter, deleteSelectedClientFromProposalFilter} from '../../actions/proposalFilters';

const useStyles = makeStyles((theme) => ({
    avatar : {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
        },
    
    chip : {
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        },
        
    avatarStyle: {
        width: 20,
        height: 20,
        fontSize: 12
    },
       
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
            
    }
    }
));

const ClientFilterComponent = (props) => {
    const classes = useStyles();
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [inputText, setInputText] = useState('')
    const [open, setOpen] = useState(false);
    const isMount = useIsMount(); 

    const fetchClientList = (text) => {
        const invoice_account_id = props.selectedAccount.id;

        getClientListApi(invoice_account_id, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientListApi: ', res);

            if(res && res.length > 0) {
                setClientList(res);
             } else {
                const noCF = { id: -1, name: 'No clients found' }
                setClientList([noCF])
            }
        })
        .catch((e) => {
            consoleToLog('Response getClientListApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }

    // useEffect(() => {
    //     if(!isMount) {
    //         consoleToLog("Autocomplete companies- on company change: in useeffect", selectedClient);
    //     }
    // }, [selectedClient])

    // useEffect(() => {
    //     if(!isMount) {
    //         consoleToLog("Autocomplete Companies- On Clear click: in useeffect");
    //     }
    // }, [])

    const onAddCompany = (option) => {
        setSelectedClient(option);
        if(props.fromProposalListComponent) {
            props.addSelectedClientToProposalFilter(option);
        } else if(props.isFromTransactionFilter) {
            props.addSelectedClientToTransactionFilter(option);
        } else {
            props.addSelectedClientToInvoiceFilter(option);
        }
    }

    const onDeleteCompany = (option) => {
        setSelectedClient(selectedClient.filter(entry => entry !== option));
        if(props.fromProposalListComponent) {
            props.deleteSelectedClientFromProposalFilter(option);
        } else if(props.isFromTransactionFilter) {
            props.removeSelectedClientFromTransactionFilter(option);
        } else {
            return props.deleteSelectedClientFromInvoiceFilter(option);
        }
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                className={classes.autoStyle}
                onClick={() => {
                    onAddCompany(option);
                    props.pageReset();
                }}     
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.name}>
                <Chip
                    classes={{
                        root: classes.chip
                    }}
                    avatar={<Avatar classes={{
                        root: classes.avatar
                    }}>{option.name.charAt(0)}</Avatar>}
                    label={option.name}
                    onDelete={() => onDeleteCompany(option)}
                    variant="outlined"
                    style={{margin: "2px 2px", overflow:'hidden'}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
    }

    const handleInputChange = (e, newValue) => {
        fetchClientList(newValue);
            
        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }

    const valueforAutocomplete = () => {
        if(props.fromProposalListComponent) {
            return props.selectedClientsProposalFilter;
        } else if(props.isFromTransactionFilter) {
            return props.transactionClientsFilter;
        } else {
            return props.invoiceClientsFilter;
        }
    }

    return (
        <div style={{marginTop:'16px'}}>
            <Typography style={{fontSize:'14px'}}>
                Select Client            
            </Typography>
            <Grid container md={12} lg={12} style={{marginTop:'6px'}}>
                <Grid item lg={12}>
                <Autocomplete
                    multiple={true}
                    style={{width: "100%"}}
                    id="size-small-outlined-multi"
                    size="small"
                    open={open}
                    onOpen={() =>  {
                        if(inputText) {
                            setOpen(true)
                        }
                    }}
                    onClose={() => setOpen(false)}
                    options={clientList}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    renderOption={(option) => renderOptions(option)}
                    value={valueforAutocomplete()}
                    onChange={(e, newValue) => {
                        //props.isFromReport &&  props.onUpdateClearFilterValue();
                        props.invoiceClientsFilter && trackGAEvent(props.selectedAccount.name, 'Invoice filter - client filter changed');
                        props.isFromTransactionFilter && trackGAEvent(props.selectedAccount.name, 'Transaction filter - client filter changed');
                        props.fromProposalListComponent && trackGAEvent(props.selectedAccount.name, 'Proposal filter - client filter changed');
                        setSelectedClient(newValue)
                    }}
                    onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                    renderTags={(options) => renderTags(options)}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Search Client" />
                    )}
                />
                </Grid>
            </Grid>        
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceClientsFilter: state.invoiceFilters.invoiceClientsFilter,
    transactionClientsFilter: state.transactionFilters.transactionClientsFilter,
    selectedClientsProposalFilter: state.proposalFilters.selectedClientsProposalFilter,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedClientToInvoiceFilter: (item) => dispatch(addSelectedClientToInvoiceFilter(item)),
    deleteSelectedClientFromInvoiceFilter: (item) => dispatch(deleteSelectedClientFromInvoiceFilter(item)),

    addSelectedClientToTransactionFilter: (item) => dispatch(addSelectedClientToTransactionFilter(item)),
    removeSelectedClientFromTransactionFilter: (item) => dispatch(removeSelectedClientFromTransactionFilter(item)),

    addSelectedClientToProposalFilter: (item) => dispatch(addSelectedClientToProposalFilter(item)),
    deleteSelectedClientFromProposalFilter: (item) => dispatch(deleteSelectedClientFromProposalFilter(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientFilterComponent);