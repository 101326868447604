import React from 'react';
import BaseSection from './BaseSection';
import {Grid, Typography} from '@material-ui/core';
import { ReadOnlyEditor } from './BaseEditor';

const ThanksMessage = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {title, text} = data;

    return (
        <BaseSection title="Thanks Message" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

                <Grid item md={12} className='edit_section_container'>

                    <Grid item md={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: title}} />
                    </Grid>

                    <Grid item md={12}>
                        <ReadOnlyEditor editorValue={text}/>
                    </Grid>
                    
                    
                </Grid>

        </BaseSection>
    );
};

export default ThanksMessage;
