import React from 'react';
import { Modal, Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    modalBody: {
        backgroundColor: 'white', 
        borderRadius: '10px', 
        maxWidth: '460px',
    },
}))

const ShowConfirmationModal = ({ showConfirmModal, handleShowConfirmModalClose, itemObj, updatedItemObj, callUpdateSectionApi, setDirtyFlag, handleDrawerClose}) => {
    const classes = useStyles();

    const handleSave = () => {
        callUpdateSectionApi(itemObj, updatedItemObj);
        handleShowConfirmModalClose();
    };

    const handleDiscard = () => {
         // Close the modal without saving
        handleShowConfirmModalClose();
        setDirtyFlag(false);
        handleDrawerClose();
    };

    return (
        <Modal open={showConfirmModal} 
            onClose={handleShowConfirmModalClose} 
            className={classes.modalContainer}>
            <div className={classes.modalBody}>
                <div style={{padding:'16px'}}>
                    <Typography variant="h6" component="h2">
                        You have not saved your changes. Do you want to save?
                    </Typography>
                </div>

                <div style={{padding:'16px', borderTop:'1px solid #ccc'}}>
                    <Grid item container spacing={1}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={handleDiscard}>
                                Discard
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal>
    );
};

export default ShowConfirmationModal;
