import React, {useState, useEffect} from 'react';
import {Grid, Button, Typography, Link, CircularProgress} from '@material-ui/core';
import PreviewProposal from './preview/PreviewProposal';
import { useParams } from 'react-router-dom';
import { consoleToLog, generateMd5ForApi, ProposalStatus } from '../../util/AppUtil';
import {getClientProposalPreviewApi, approveRejectApi, downloadClientProposalApi} from '../../services/proposalService';
import { useSnackbar } from 'notistack';
import { Spinner } from '../Spinner';
import {
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
    CancelOutlined as CancelOutlinedIcon,
    CloudDownloadOutlined as CloudDownloadOutlinedIcon,
    PictureAsPdf as PictureAsPdfIcon
} from '@material-ui/icons';
import ShowConfirmModal from './ShowConfirmModal';
import ErrorMessageComponent from './ErrorMessageComponent';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    approvedProposal: {
        background:'#def7ec',
        color:'#222',
    },
    rejectedProposal: {
        background:'#FFEAEB',
        color:'#222',
    },
    statusIcons: {
        fontSize:"17px",
        position:'relative',
        top:'2px',
        right:'6px',
        '& .checkIcon' : {
            color:"green !important"
        },
        '& .cancelIcon' : {
            color:"red"
        }
    },
    checkIcon : {
        color:"#0e9f6e"
    },
    cancelIcon : {
        color:"red"
    },
}))

const ClientProposalPreview = ({  }) => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [proposalObj, setProposalObj] = useState({});
    const [previewProposalPdfArr, setPreviewProposalArr] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    const [downloadApiLoading, setDownloadApiLoading] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [proposalItem, setProposalItem] = useState(undefined);
    const [showError, setShowError] = useState(false);

    const {proposal_uuid} = useParams();

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');
    const urlkeyParam = url.searchParams.get('url_key');
    const email = decodeURIComponent(emailParam).replace(/[\[\]']/g, '').replace(/\s/g, '');
    const urlkey = decodeURIComponent(urlkeyParam).replace(/[\[\]']/g, '').replace(/\s/g, '');
    const status = proposalObj?.status;


    useEffect(() => {
        setLoading(true);

        if(proposal_uuid) {
            callClientProposalPreviewApi()
        } 
        
    }, [proposal_uuid]);

    console.log('proposal', proposalObj)

    const callClientProposalPreviewApi = async() => {
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/client/proposal/${proposal_uuid}/preview?url_key=${urlkey}`);
        consoleToLog('preview md5 hash: ', md5_hash, email);

        try {
            const response =  await getClientProposalPreviewApi(md5_hash, proposal_uuid, urlkey)
            const res = response.data;
            consoleToLog('Response clientPreviewApi: ', res);
            setProposalObj(res);
            setPreviewProposalArr([...res.sections])
            setLoading(false);
            showError && setShowError(true);

        } catch(e) {
            consoleToLog('Error clientPreviewApi: ', e.response);
                setLoading(false);
                setShowError(true);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
        }
}

    const handleConfirmDialogOpen = (e, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let propsosal_obj = {
            id: proposalObj.id,
            ...obj
        }
        setProposalItem(propsosal_obj);
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
        setProposalItem(undefined);
    }

    const onApproveOrRejectClick = async (path) => {
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/proposal/${proposalObj?.id}/${path}`)
        
        setApiLoading(true);
        
        try {
            const response  = await approveRejectApi(md5_hash, proposalObj?.id, urlkey, path)
            const res = response.data;
            consoleToLog('Response approveRejectApi: ', res);
            setApiLoading(false);

            enqueueSnackbar(`Proposal ${path === 'approve' ? 'Approved' : 'Rejected'} successfully!`, {variant:'success'});
            setProposalObj({...res});
            handleConfirmDialogClose();
            
        } catch(e) {
            consoleToLog('Error approveRejectApi: ', e.response);
            setApiLoading(false);
            handleConfirmDialogClose();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'})
                return;
            }
        }
    }

    const onDownloadProposalClick = () => {
        var md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/proposal/${proposalObj?.id}/download?url_key=${urlkey}`);
        
        setDownloadApiLoading(true);

        downloadClientProposalApi(md5_hash, proposalObj?.id, urlkey)
            .then((response) => {
                const res = response.data;
                consoleToLog('Reponse downloadClientProposalApi: ', res);
                setDownloadApiLoading(false);

                const downloadableURL = res.result;

                //initiate download
                document.getElementById('proposal_download').href = downloadableURL;
                document.getElementById('proposal_download').click();

            })
            .catch((e) => {
                consoleToLog('Error downloadClientProposalApi: ', e.response);
                setDownloadApiLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
            })
    }

    return (
        <div style={{position:"relative"}}>
            {
                loading
                ?
                <Spinner />
                :
                <PreviewProposal proposalObj={proposalObj}
                    previewProposalPdfArr={previewProposalPdfArr}
                    showClientPreview={true}
                    selectedAccount={{}}

                    />
            }

            {!loading && proposalObj?.id &&
            <div className={`proposals_footer_buttons ${status === 'approved' ? classes.approvedProposal : status === 'rejected' && classes.rejectedProposal}`}>

                {(status === 'approved' || status === 'rejected') &&
                <Grid item container justifyContent='center'>
                <Typography style={{textAlign:'center !important', fontWeight:600}}>
                    <span>{status === ProposalStatus.APPROVED ? <CheckCircleIcon className={`${classes.statusIcons} ${classes.checkIcon}`}/> : 
                    <CancelIcon className={`${classes.statusIcons} ${classes.cancelIcon}`}/>}</span>
                    {status ===  ProposalStatus.APPROVED  ? 'This proposal has been approved' : 'This proposal has been rejected'}
                </Typography>
                </Grid>
                }
                
                <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                            <Button 
                                onClick={onDownloadProposalClick}
                                style={{background:'#fff'}}
                                className='footer_btn footer_btn_cancel'>
                                {downloadApiLoading ? <CircularProgress size={15} style={{marginRight:'5px'}}/> : <PictureAsPdfIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />}
                                Download
                                <Link
                                    style={{ display: "none" }}
                                    id={"proposal_download"}
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}
                                    download
                                ></Link> 
                            </Button>
                        </Grid>

                        {proposalObj?.status !== 'approved' && proposalObj?.status !== 'rejected'  &&
                        <Grid item>
                            <Button 
                                onClick={(e) => handleConfirmDialogOpen(e, {approve_proposal_clicked: true})}
                                className='footer_btn approveButton'>
                                <CheckCircleOutlineOutlinedIcon fontSize='small'
                                    style={{marginRight:'5px', fontSize:'16px'}} />
                                Approve
                            </Button>
                        </Grid>
                        }
                        
                        {proposalObj?.status !== 'approved' && proposalObj?.status !== 'rejected'  &&
                        <Grid item>
                            <Button 
                                onClick={(e) => handleConfirmDialogOpen(e, {reject_proposal_clicked: true})}
                                className='footer_btn rejectButton'>
                                <CancelOutlinedIcon fontSize='small'
                                        style={{marginRight:'5px', fontSize:'16px'}} />
                                Reject
                            </Button>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </div>}

            {
                openConfirmDialog &&
                <ShowConfirmModal openConfirmDialog={openConfirmDialog}
                    handleConfirmDialogClose={handleConfirmDialogClose}
                    onApproveOrRejectClick={onApproveOrRejectClick}
                    proposalItem={proposalItem}
                    apiLoading={apiLoading}
                    fromClientProposalPreview={true}
                />
            }
        </div>
    );
}

export default ClientProposalPreview;