import React, {useState, useEffect} from 'react';
import {Grid, Typography, TextField, Switch, Button, IconButton, CircularProgress, Tooltip} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import { consoleToLog } from '../../../util/AppUtil';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import BaseDrawer from './BaseDrawer';
import {uploadAttachmentApi} from '../../../services/uploadService';
import { useIsMount } from '../../useIsMount';
import {AttachmentTypes, AttachmentSubTypes} from '../../../util/AppUtil'

const useStyles = makeStyles((theme) => ({
    photoContainer: {
        height: '230px',
        width:'100%',
        borderRadius:'8px',
        background: '#dddddd',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    profilePicContainer: {
        width: '80px',
        height: '80px',
        overflow: 'hidden',
        borderRadius:'100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageStyle: {
        width:"100%",
        height:"100%",
        objectFit:'cover',
        objectPosition:'cover'
    },
    uploadImg: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative',
        fontSize:'12px',
        top:'-20px',
        left:'45px',
        cursor:'pointer',
        color:"white",
        width:'20px',
        height:'20px',
        borderRadius:'40px',
        background:'black',
    },
    imageBackdrop: {
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    
    profilePicWrapper: {
        position: 'relative',
        width: '80px',
        height: '80px',
        '&:hover .profilePicBackdrop': {
            visibility: 'visible'
        }
    },
    teamProfilePicColumn:{
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customTopNegativeMargin:{
        flexBasis:'-8px',
        maxWidth:'-8px'
    },
    MuiTooltipTooltip: {
        fontSize: '13px',
        cursor: 'pointer'
    },
}))


const TeamDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, invoice_account_id, proposal_id, apiLoading, setDirtyFlag}) => {
    const classes = useStyles();
    const isMount = useIsMount();

    const {data} = {...itemObj};
    const {team_image_title, show_team_image_title, team_image, team_image_signed_url, show_team_image, leadership_title, show_leadership_title, leaders} = data;

    const [showTeamImage, setShowTeamImage] = useState(show_team_image);
    const [teamImage, setTeamImage] = useState(team_image);
    const [teamPhotoImage, setTeamPhotoImage] = useState(team_image);

    const [showTeamPhotoTitle, setShowTeamPhotoTitle] = useState(show_team_image_title);
    const [teamPhotoTitle, setTeamPhotoTitle] = useState(team_image_title);

    const [showLeadershipTitle, setShowLeadershipTitle] = useState(show_leadership_title);
    const [leadershipTitle, setLeadershipTitle] = useState(leadership_title);

    const [leadersList, setLeadersList] = useState([...leaders]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedLeaderItem, setSelectedLeaderItem] = useState(undefined);
    const [fileObj, setFileObj] = useState(undefined);
    const [uploadingProfilePic, setUploadingProfilePic] = useState(null);
    const attachment_type = AttachmentTypes.PROPOSAL;

    useEffect(() => {
        if(!isMount) {
            if(!showTeamImage) {
                setShowTeamPhotoTitle(false);
            }
        }
    }, [showTeamImage])

    const handleShowTeamPhotoTitle = (event) => {
        setShowTeamPhotoTitle(event.target.checked);
        setDirtyFlag(true);
    };

    const handleShowTeamPhoto = (event) => {
        setShowTeamImage(event.target.checked);
        setDirtyFlag(true);
    };

    const handleShowLeadershipTitle = (event) => {
        setShowLeadershipTitle(event.target.checked);
        setDirtyFlag(true);
    };

    const handleEditClick = (index, leaderObj) => {
        setSelectedItemIndex(index);
        setSelectedLeaderItem(leaderObj)
    };

    const handleTeamPhotoUpload = async (e) => {
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById(`teamPhotoUpload`).src = oFREvent.target.result;
        };

        const file = e.target.files[0];
        const file_name = file.name;
        
        const attachment_subtype = AttachmentSubTypes.PROPOSAL_TEAM;
        const response = await uploadAttachmentApi(invoice_account_id, attachment_type, attachment_subtype, file_name, file, proposal_id);
        const res = response;

        consoleToLog('Response uploadAttachmentApi', res);
        //setTeamImage(res.upload_url);
        setTeamPhotoImage(res.upload_url);

        setDirtyFlag(true);
    }

    const readFileObj = (fileObj) => {
        var oFReader = new FileReader();
        oFReader.readAsDataURL(fileObj);

        oFReader.onload = function (oFREvent) {
            document.getElementById(`${leadersList[selectedItemIndex].name + ` profile_pic`}`).src = oFREvent.target.result;
        };
    }

    const onProfilePhotoSelected = async(index, e) => {
        readFileObj(e.target.files[0]);
        const file = e.target.files[0];
        const file_name = file.name;
        const attachment_subtype = AttachmentSubTypes.PROPOSAL_PROFILE_PIC;
        
        setUploadingProfilePic(index);
        
        try {
            const response = await uploadAttachmentApi(invoice_account_id, attachment_type, attachment_subtype, file_name, file, proposal_id);
            const res = response;

            consoleToLog('Response uploadAttachmentApi', res);
            const tempUrl = URL.createObjectURL(file);
            setLeadersList((prevState) =>
                prevState.map((state, i) =>
                i === index ? { ...state, profile_pic: res.upload_url, temp_url: tempUrl} : state
                )
            );
            setDirtyFlag(true);
        } finally {
            setUploadingProfilePic(null);
        }
    }

    const handleNameChange = (index, e) => {
        setLeadersList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, name: e.target.value } : state
            )
        );
        setDirtyFlag(true);
    }

    const handleTitleChange = (index, e) => {
        setLeadersList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, title: e.target.value } : state
            )
        );
        setDirtyFlag(true);
    }

    const handleBioChange = (index, e) => {
        setLeadersList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, bio: e.target.value } : state
            )
        );
        setDirtyFlag(true);
    }

    const saveChanges = () => {
        setSelectedItemIndex(null);
        setSelectedLeaderItem(undefined);
    };

    const cancelEdit = () => {
        setSelectedItemIndex(null);
        if ((leadersList[selectedItemIndex]?.name === '' && leadersList[selectedItemIndex]?.title === '' && leadersList[selectedItemIndex]?.profile_pic === "") || !selectedLeaderItem) {
            const newItems = leadersList.filter((_, i) => i !== selectedItemIndex);
            setLeadersList(newItems);
        } else {
            const updatedArray = [...leadersList];
            updatedArray[selectedItemIndex] = {...selectedLeaderItem};
            setLeadersList(updatedArray);
        }
        setSelectedLeaderItem(undefined);
    };

    const addMember = () => {
        setLeadersList([{name: "", title: "", bio: "", profile_pic: ""}, ...leadersList]);
        setDirtyFlag(true);
        setSelectedItemIndex(0);
    
    };

    const onDeleteIconClick = (index) => {
        const newItems = leadersList.filter((_, i) => i !== index);
        setLeadersList(newItems);
        setDirtyFlag(true);
    
    }

    const handleTeamPhotoTitleChange = (e) => {
        setTeamPhotoTitle(e.target.value);
        setDirtyFlag(true);
    }

    const handleTeamLeaderShipTitleChange = (e) => {
        setLeadershipTitle(e.target.value);
        setDirtyFlag(true);
    }

    const setUpdatedItemObj = () => {
        const updatedLeadersList = [...leadersList].map((leader) => {
            delete leader.temp_url;
            return leader
        })
        const dataObj = {
            team_image_title: teamPhotoTitle,
            team_image: teamPhotoImage,
            show_team_image: showTeamImage,
            leaders: updatedLeadersList,
            leadership_title: leadershipTitle, 
            show_leadership_title: showLeadershipTitle,
            show_team_image_title:showTeamPhotoTitle,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }


    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Team'
        >
            <div className='drawer_content_container' style={{padding:'16px 0px'}}>
                <Grid item md={12} className='drawer_element_padding'>
                    <Grid item container alignItems='center' justifyContent='space-between'> 
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Typography className='drawer_label_style'>
                                    Team photo title
                                </Typography>
                                <Tooltip classes={{
                                    tooltip: classes.MuiTooltipTooltip
                                }} 
                                    title='Please enable team photo to show team photo title' arrow>
                                    <img src='/images/info.svg' style={{cursor:"pointer"}}/>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Switch checked={showTeamPhotoTitle} onChange={handleShowTeamPhotoTitle} name="team_title" 
                                disabled={!showTeamImage}/>
                        </Grid>
                    </Grid>

                    <Grid item md={12}>
                        {
                            showTeamPhotoTitle &&
                            <TextField 
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                value={teamPhotoTitle}
                                onChange={handleTeamPhotoTitleChange}
                            
                            />

                        }
                    </Grid>
                </Grid>

                <Grid item md={12} className='drawer_element_padding'>
                    <Grid item container alignItems='center' justifyContent='space-between'> 
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Typography className='drawer_label_style'>
                                    Team Photo/Company Photo
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Switch checked={showTeamImage} onChange={handleShowTeamPhoto} name="team_photo" />
                        </Grid>
                    </Grid>

                    <Grid item md={12}>
                        {
                            showTeamImage &&
                            <div className={classes.photoContainer} style={{position: 'relative'}}>
                                <img src={team_image_signed_url ? team_image_signed_url : '/images/team_photo.png'} id="teamPhotoUpload" 
                                    className={classes.imageStyle}/>
                                <label className={classes.imageBackdrop}>
                                    <input type="file" style={{display: 'none'}} id="teamPhotoUpload" onChange={handleTeamPhotoUpload} />
                                    <span style={{color: 'white', cursor: 'pointer'}}>Change Image</span>
                                </label>
                            </div>

                        }
                    </Grid>
                </Grid>
                
                <Grid item md={12} className='drawer_element_padding'>
                    <Grid item container alignItems='center' justifyContent='space-between'> 
                        <Grid item>
                            <Grid item container alignItems='center'>
                                <Typography className='drawer_label_style'>
                                    Leadership team title
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Switch checked={showLeadershipTitle} onChange={handleShowLeadershipTitle} name="team_title" />
                        </Grid>
                    </Grid>

                    <Grid item md={12}>
                        {
                            showLeadershipTitle &&
                            <TextField 
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                value={leadershipTitle}
                                onChange={handleTeamLeaderShipTitleChange}
                            
                            />

                        }
                    </Grid>
                </Grid>

                <Grid item md={12} className='drawer_element_padding'>
                    {leadersList.length === 3 ?
                    <Tooltip title={"You cannot add more than 3 members"} arrow>
                        <span>
                        <Button fullWidth variant='outlined' 
                        disabled={leadersList.length === 3}
                        className='edit_drawer_add_btn'>
                        <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                        Add Member
                    </Button>
                        </span>
                    </Tooltip>
                    :
                    <Button fullWidth variant='outlined' 
                        disabled={leadersList.length === 3}
                        onClick={addMember}
                        className='edit_drawer_add_btn'>
                        <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                        Add Member
                    </Button>}
                </Grid>

                <Grid item md={12} className='drawer_list_items'>
                    {
                        leadersList && leadersList.length > 0 &&
                        leadersList.map((leaderObj, index) => {
                            return (
                                <Grid item md={12} className='itemList'>
                                    {index === selectedItemIndex ? (
                                    <div className='drawer_edit_item_container edit_list_width'>

                                        <Grid item md={12}>
                                            <Grid item container alignItems='center'>
                                                <Grid item md={2} className={classes.teamProfilePicColumn}>
                                                    <div className={classes.profilePicWrapper}>
                                                        <div className={classes.profilePicContainer}>
                                                            <img 
                                                                id={`${leaderObj.name + ` profile_pic`}`}
                                                                src={
                                                                    leadersList[index].temp_url || 
                                                                    (leadersList[index].profile_pic_signed_url 
                                                                        ? leadersList[index].profile_pic_signed_url 
                                                                        : '/images/profile_pic.png')
                                                                }
                                                                className={classes.imageStyle}
                                                            />
                                                        </div>
                                                        <label className={'profilePicBackdrop'}>
                                                            <input style={{display:'none'}}
                                                                type="file"
                                                                id={`${leaderObj.name + ` profile_pic`}`}
                                                                accept="image/x-png,image/gif,image/jpeg"
                                                                onChange={(e) => onProfilePhotoSelected(index, e)}
                                                            />
                                                            {uploadingProfilePic === index ? (
                                                                <CircularProgress size={24} style={{color: 'white'}} />
                                                            ) : (
                                                                <EditRoundedIcon style={{fontSize:'14px'}}/>
                                                            )}
                                                        </label>
                                                    </div>
                                                </Grid>

                                                <Grid item md={8}>

                                                        <Grid item md={12}>
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                margin='dense'
                                                                value={leadersList[index].name}
                                                                placeholder='Name'
                                                                onChange={(e) => handleNameChange(index, e)}
                                                                className='edit_drawer_textfield'
                                                            
                                                            />
                                                        </Grid>

                                                        <Grid item md={12}>
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                margin='dense'
                                                                value={leadersList[index].title}
                                                                placeholder='Title'
                                                                onChange={(e) => handleTitleChange(index, e)}
                                                                className='edit_drawer_textfield'
                                                            
                                                            />
                                                        </Grid>

                                                        {/* <Grid item md={12}>
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                margin='dense'
                                                                value={leadersList[index].bio}
                                                                onChange={(e) => handleBioChange(index, e)}
                                                                className='edit_drawer_textfield'
                                                            
                                                            />
                                                        </Grid> */}

                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item container alignItems='center' spacing={1}>
                                            <Grid item>
                                                <Button onClick={saveChanges} className='drawer_list_save_btn'>
                                                    Save
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button onClick={cancelEdit} variant='outlined'>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </div>
                                ) : (
                                    <div className='drawer_edit_item_container' 
                                        onClick={() => handleEditClick(index, leaderObj)}>
                                            <Grid item container
                                                alignItems='center'
                                                >
                                                <Grid item md={2} className={classes.teamProfilePicColumn}>
                                                    <div className={classes.profilePicContainer}>
                                                        <img 
                                                            id={`${leaderObj.name + ` profile_pic`}`}
                                                            src={
                                                                leadersList[index].temp_url || 
                                                                (leadersList[index].profile_pic_signed_url 
                                                                    ? leadersList[index].profile_pic_signed_url 
                                                                    : '/images/profile_pic.png')
                                                            }
                                                            className={classes.imageStyle}
                                                        />
                                                    </div>
                                                </Grid>

                                                <Grid item md={8}>

                                                        <Grid item md={12}>
                                                            <Typography className='drawer_list_items_descp'>
                                                                <b>{leaderObj?.name}</b>
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item md={12}>
                                                            <Typography className='drawer_list_items_descp'>
                                                                {leaderObj?.title}
                                                            </Typography>
                                                        </Grid>

                                                        {/* <Grid item md={12}>
                                                            <Typography className='drawer_list_items_descp'>
                                                                {leaderObj.bio}
                                                            </Typography>
                                                        </Grid> */}

                                                </Grid>

                                            </Grid>
                                    </div>
                                )}
                                    <IconButton 
                                        onClick={() => onDeleteIconClick(index)}
                                        className={'edit_drawer_delete_icon'}>
                                        <img src='/images/drawer_delete.svg' />
                                    </IconButton>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </div>
                    
            <div className={'drawer_footer'}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item>
                        <Button
                            onClick={onSaveClick} 
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>

        </BaseDrawer>
    );
}

export default TeamDrawer;