import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Popover, TextField} from '@material-ui/core';
import TableItemRow from '../TableItemRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import AutocompleteCurrencies from '../../AutocompleteCurrencies';
import { useIsMount } from '../../useIsMount';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display:'flex',
        flexDirection:'column',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#007bfb',
                borderColor: '#007bfb',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        
    },
    checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
    popoverContent: {
        width: 200,
        height:300
    },
    switchContainer: {
        padding: '6px 8px',
        background: '#E9ECEF',
    },
    textFieldContainer: {
        padding: '8px',
    },
}));

const AddItems = ({items, setItems, invoiceItems, errorType, setErrorType, selectedAccount, showQuantity, setShowQuantity, quantityUnitTerminology,
                    setQuantityUnitTerminology, selectedCurrency, setSelectedCurrency}) => {

    const classes = useStyles();
    const isMount = useIsMount();

    const [editingIndex, setEditingIndex] = useState(items && items.length > 1 ? null : 0);
    const [anchorEl, setAnchorEl] = useState(null);


    useEffect(() => {
        if(!isMount) {
            if(!showQuantity) {
                const updatedArray = items.map((item) => {
                    return {
                        ...item,
                        quantity: parseFloat(1),
                        amount: parseFloat(item.price)
                    }
                })
                setItems([...updatedArray]);
            }

        }
    }, [showQuantity])

    const currenciesObj = selectedAccount?.currencies;

    const handleSave = (index, editedItem) => {
        const newItems = [...items];
        newItems[index] = editedItem;
        setItems(newItems);
        setEditingIndex(null);
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
    };

    const handleDelete = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const addItem = () => {
        if(validationForTableRow()) {
            setItems([...items, { name: '', quantity: 1, price: '', periodicity: 'none' }]);
            setEditingIndex(items.length);
        }
    };

    const validationForTableRow = () => {
        if (items[editingIndex]?.name === '') {
            setErrorType(3);
            return;
        } else if (items[editingIndex]?.quantity === '') {
            setErrorType(4);
            return;
        } else if (items[editingIndex]?.price === '') {
            setErrorType(5);
            return;
        } else if (items[editingIndex]?.amount === '') {
            setErrorType(6);
            return;
        } else if (items[editingIndex]?.periodicity === 'none') {
            setErrorType(7);
            return;
        } else {
            setErrorType(0);
            return true;
        }
    };

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onCancelClick = () => {
        const updatedArray = [...items];
        if(!updatedArray[editingIndex]?.item_id) {
            setItems([...updatedArray].filter((_, i) => i !== editingIndex));
        }
        setEditingIndex(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'settings-popover' : undefined;

    return (
        <div>
            <Box className='component_container'>
                <Grid item md={12}>
                    <Typography className='component_heading'>
                        Items
                    </Typography>

                    <Typography className='component_description'>
                        Add and customize the list of items to be included in the proposal. You can define item names, quantities, prices, and periodicity. 
                        Toggle the settings to show or hide quantity as per your preference.                   
                    </Typography>
                </Grid>

                <Grid item md={5}>
                    <Typography className='component_description'>
                        Currency
                    </Typography>

                    <AutocompleteCurrencies selectedCurrency={selectedCurrency}
                        setSelectedCurrency={setSelectedCurrency}
                        />
                </Grid>

                <Grid item md={12}>
                    <TableContainer component={Paper} variant='outlined' >
                        <Table>
                            <TableHead style={{padding:0, background:'#f4f4f4'}}>
                                <TableRow>
                                    <TableCell className="item_name_width table_cell_style1 add_item_table_heading">Item name</TableCell>
                                    <TableCell className="quantity_width table_cell_style1 add_item_table_heading">
                                        <span className={!showQuantity && 'hide_page_style'}>{`${quantityUnitTerminology}`}</span>
                                        <img src='/images/settings.svg' className='add_item_tabel_settings_icon settings_icon_color' onClick={handleSettingsClick}/>
                                        {/* <SettingsOutlinedIcon className='add_item_tabel_settings_icon' onClick={handleSettingsClick} /> */}
                                    </TableCell>
                                    <TableCell className="price_width table_cell_style1 add_item_table_heading">Price</TableCell>
                                    <TableCell className="price_width table_cell_style1 add_item_table_heading">Amount</TableCell>
                                    <TableCell className="periodicity_width table_cell_style1 add_item_table_heading">Recurring</TableCell>
                                    <TableCell className="action_items table_cell_style1"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item, index) => (
                                    <TableItemRow
                                        key={index}
                                        item={item}
                                        index={index}
                                        onSave={handleSave}
                                        onEdit={handleEdit}
                                        onDelete={handleDelete}
                                        isEditing={editingIndex === index}
                                        invoiceItems={invoiceItems}
                                        currenciesObj={currenciesObj}
                                        errorType={errorType}
                                        setErrorType={setErrorType}
                                        showQuantity={showQuantity}
                                        quantityUnitTerminology={quantityUnitTerminology}
                                        selectedCurrency={selectedCurrency}
                                        setEditingIndex={setEditingIndex}
                                        onCancelClick={onCancelClick}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item container justifyContent='flex-end'>
                    <Button variant='outlined'
                        onClick={addItem}
                        className='btn_width'
                        style={{marginLeft:'auto'}}>
                        <AddCircleOutlineIcon fontSize='small' style={{marginRight:'4px'}}/> 
                        Add Item
                    </Button>
                </Grid>
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    <Typography component='div' className={classes.switchContainer}>
                        <Grid item container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <Typography>Show Quantity</Typography>
                            </Grid>
                            
                            <Grid item>
                                <AntSwitch
                                    checked={showQuantity}
                                    onChange={() => setShowQuantity(!showQuantity)}
                                    name='quantity unit'
                                    color='primary'
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Grid>
                        </Grid>
                    </Typography>
                    <div className={classes.textFieldContainer}>
                        <TextField
                            variant='outlined'
                            margin='dense'
                            value={quantityUnitTerminology}
                            onChange={(e) => setQuantityUnitTerminology(e.target.value)}
                            fullWidth
                        />
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default AddItems;
