import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Grid, Container, Typography, Button, Avatar, Tooltip, CircularProgress, Link, ClickAwayListener} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import md5 from 'md5';
import { clientPreviewApi, downloadInvoiceApi } from '../services/authService';
import { consoleToLog, showDate, displayInitials, showStatusImages, displayInvoiceType, DiscountTypes, isInvoiceAccountCountryIndia} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import OtherInvoicesModal from './modals/OtherInvoicesModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import {history} from '../routers/AppRouter';
import {useParams} from 'react-router-dom';
import { useWindowSize } from './useWindowSize';
import {getImageDownloadUrlApi} from '../services/authService';
import { useIsMount } from './useIsMount';
import { formatAmount } from '../util/currencyUtil';
import { QRCode } from 'react-qr-code';
import PaymentReceipt from './PaymentReceipt';
import {getInvoiceTransactions} from '../util/invoiceTransactionUtil';
import {displayInvoiceTypeLabel} from '../util/AppUtil';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import {getClientEstimatePreviewObjApi, approveRejectEstimateApi, downloadClientEstimateApi} from '../services/authService';
import ShowConfirmDialog from './ShowConfirmDialog';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        flexGrow: 1,
        width:'100vw',
        height:'100vh',
        overflow:'auto !important',
        boxSizing:'border-box',
        background: theme.palette.primary.light,
        
    }, 
    container: {
        margin:'0px auto 32px auto',
        //padding:'8px 8px 8px 16px'
    },
    createFormContainer : {
        background:'white',
        padding:'0px 0px 32px 0px',
        borderRadius:'5px',
        '& .customColumn1' : {
        flexBasis:'58%',
        maxWidth:'58%',
        [theme.breakpoints.down(992)]: {
            flexBasis:'100%', 
            maxWidth:'100%'
        } 
        }

    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 16px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    firstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'18px 14px 16px 14px'
    },
    backIconContainer : {
        [theme.breakpoints.down(1450)] : {
            flexBasis:'30%',
            maxWidth:'30%',
        }
    },
    backIcon: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
    },
    secondRow: {
        padding:'24px'
    },
    avatarStyle: {
        width:"80px",
        height:"80px",
        fontSize:'35px',
        fontWeight:'600',
        [theme.breakpoints.down(500)] : {
            width:"60px",
            height:"60px",
            fontSize:'32px',
        },
        [theme.breakpoints.down(365)] : {
            margin:'8px 0px'
        }
    },
    thirdRow: {
        padding:'16px 24px 16px 24px' 
    },
    customColumn: {
        marginLeft:'16px',
        flexBasis:'40%',
        maxWidth:'40%',
    },
    secondColumnContainer: {
        marginLeft:'16px',
        flexBasis:'20%', 
        maxWidth:'20%',
        [theme.breakpoints.down(992)]: {
            marginLeft:'0px',
            marginBottom:'24px',
            flexBasis:'100%', 
            maxWidth:'100%'
        } 
    },
    plusIcon: {
        fontSize: '18px',
        marginRight:'8px'
    },
    MuiTextFieldroot : {
        background:'#fff',
        fontSize:'12px !important'
    },
    highlightOffIcon: {
        fontSize:'16px',
        marginRight:'4px'
    },
    customMarginBottom : {
        marginBottom: '24px'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    customColumn2: {
        marginLeft:'16px',
        flexBasis:'23%',
        maxWidth:'23%'
    },
    invoiceListContainer : {
        background:'white', 
        borderRadius:'4px', 
        marginBottom:'6px',
        color:'#2B2B2B',
        display:'flex', 
        alignItems:'center', 
        justifyContent:'space-between',
        cursor:'pointer',
        '& .action__icon' : {
            padding:'0px 2px 0px 2px',
            border:'1px solid white',
            borderRadius:'4px'
        },
        '&:hover .action__icon' : {
           border:'1px solid #ccc'
        },
    },
    itemStyle: {
        borderBottom:'1px solid rgba(0, 0, 0, 0.1)', 
        paddingTop:'16px',
        paddingBottom:'8px'
    },
    paymentClientStyles: {
        marginLeft:'8px',
        width:'100% !important',
        [theme.breakpoints.down('md')]: {
            marginLeft:'0px'
        }
    },
    marginDropdown: {
        top:'25px !important',
        right: '4px',
    },
    modalMarginDropdown: {
        top:'25px !important',
        right: '35px',
        cursor:'pointer'
    },
    actionsIcons: {
        color: '#b5bbc2',
        fontSize: '18px'
    },
    clientOwnerName: {
        color: '#4a5568',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '26px',
        [theme.breakpoints.down(600)] : {
            fontSize: '15px',
            maxWidth:'190px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(500)] : {
            fontSize: '15px',
            maxWidth:'170px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(420)] : {
            fontSize: '15px',
            maxWidth:'150px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(390)] : {
            fontSize: '15px',
            maxWidth:'140px',
            wordWrap:'break-word !important'
        }
    },
    clientOwnerAddress: {
        fontSize: "13px !important",
        color: '#4a5568 !important',
        lineHeight: "26px !important",
        fontWeight: '400 !important',
        [theme.breakpoints.down(600)] : {
            maxWidth:'190px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(600)] : {
            maxWidth:'170px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(420)] : {
            maxWidth:'150px',
            wordWrap:'break-word !important'
        },
        [theme.breakpoints.down(390)] : {
            maxWidth:'140px',
            wordWrap:'break-word !important'
        }
    },
    mobileMargin: {
        marginBottom:'8px'
    },
    paymentStubStyle: {
        flexBasis:'55%', 
        maxWidth:'55%',
        [theme.breakpoints.down(700)] : {
            flexBasis:'100%', 
            maxWidth:'100%',
        }
        
    },
    mobileListContainer: {
        marginBottom:'16px',
        '&:last-child': {
            marginBottom:'0px'
        }
    },
    previewInvoiceDescriptionStyle: {
        fontSize: '13px !important', 
        color: '#4a5568 !important',
        [theme.breakpoints.down(480)] : {
            width:'180px !important',
            wordWrap:'break-word !important',
        }
    },
    statusImage: {
        width:"180px", 
        height:"67.5px",
        [theme.breakpoints.down] : {
            width:"120px", 
            height:"58px",
        },
        [theme.breakpoints.down(365)] : {
            margin:'8px 0px'
        }
    },
    customColumn3: {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn4: {
        flexBasis:'16%',
        maxWidth:'16%'
    },
    customColumn5: {
        flexBasis:'22%',
        maxWidth:'22%'
    },
    customColumn6: {
        flexBasis:'38%',
        maxWidth:'38%'
    },
    customMarginLeft: {
        marginLeft:'auto'
    },
    typeStyle : {
        fontSize:'13px', 
        color:'#4a5568', 
        marginTop:'1px',
        marginLeft:'6px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
    },
    tdsDisplay: {
        width:'auto',
        maxWidth:'250px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            width:'180px', 
        }
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    approveButton: {
        backgroundColor:'green',
        color: '#fff',
        '&:hover' : {
            backgroundColor:'green',
            opacity:'0.8',
        }
    },
    rejectButton: {
        background:'#8b0000',
        color: '#fff',
        '&:hover' : {
            backgroundColor:'#8b0000',
            opacity:'0.8',
        }
    },
    imgContainer :{
        marginTop:"8px",
        width:'100px',
        height:'100px',
    },
    imageFile: {
        width:'100%',
        objectFit:'cover',
        objectPosition:'center'
    }
    
}));

const PreviewAndDownloadInvoice = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [invoiceEstimateObj, setInvoiceEstimateObj] = useState(undefined);
    const [otherInvoices, setOtherInvoices] = useState([]);
    const [showAllInvoicesModal, setShowAllInvoicesModal] = useState(false);
    const [menuId, setMenuId] = useState('');
    const [isActive, setIsActive] = useState(false); 
    const [fromInvoiceModal, setFromInvoiceModal] = useState(false);
    const [signUrl, setSignUrl] = useState(undefined);
    const [invoiceAccount, setInvoiceAccount] = useState(undefined);

    const [eSignUrl, setESignUrl] = useState(undefined);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);

    const {enqueueSnackbar} = useSnackbar();
    const {invoice_uuid} = useParams();
    const {estimate_uuid} = useParams();

    const {width} = useWindowSize();
    const isMount = useIsMount();

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');
    const email = decodeURIComponent(emailParam).replace(/[\[\]']/g, '').replace(/\s/g, '');

    const client_name = invoiceEstimateObj?.client.name;
    let over_due = (moment(invoiceEstimateObj?.due_date).toDate().getTime() < Date.now())

    const invoiceType = invoiceEstimateObj?.invoice_type;
    const entityObj = invoiceEstimateObj?.entity;
    let locale = invoiceEstimateObj?.locale;
    let currency = invoiceEstimateObj?.currency;
    let currenciesObj = invoiceEstimateObj?.currencies;
    const invoiceAccountCountry = invoice_uuid && invoiceEstimateObj?.invoice_account.country;
    const invoiceTransactions = invoice_uuid && invoiceEstimateObj?.invoice_transactions;
    const items = invoice_uuid ? invoiceEstimateObj?.invoice_items : invoiceEstimateObj?.estimate_items;
    const sequence_str = invoice_uuid ? invoiceEstimateObj?.invoice_sequence_str : invoiceEstimateObj?.estimate_sequence_str;
    const description = invoice_uuid ? invoiceEstimateObj?.invoice_description : invoiceEstimateObj?.estimate_description;
    const estimate_terminology = estimate_uuid && invoiceEstimateObj?.invoice_account?.estimate_terminology;
    const invoice_account_id = invoiceEstimateObj?.invoice_account_id;

    const uuid_string = invoice_uuid || estimate_uuid;
    consoleToLog('uuid: ', uuid_string);

    const generateMd5ForApi = (url) => {
        let api_url = url
        if(api_url.indexOf("https://") !== -1) {
            api_url = api_url.replace('https://','http://');
        }

        return md5(api_url);
    }

    useEffect(() => {
        setLoading(true);
        setShowAllInvoicesModal(false);

        // const uuid_string = invoice_uuid || estimate_uuid;
        // consoleToLog('uuid: ', uuid_string);

        // var preview_url = `${process.env.REACT_APP_BASE_URL}/client/invoice/${uuid_string}/preview?email=${email}`;
        // if(preview_url.indexOf("https://") !== -1){
        //     preview_url = preview_url.replace('https://','http://');
        // }
        const md5_hash = generateMd5ForApi(invoice_uuid ? `${process.env.REACT_APP_BASE_URL}/client/invoice/${uuid_string}/preview?email=${email}` : `${process.env.REACT_APP_BASE_URL}/client/estimate/${uuid_string}/preview?email=${email}`)
        consoleToLog('preview md5 hash: ', md5_hash, email);
        //console.log('request urlv: ', preview_url);

        if(invoice_uuid) {
            callClientInvoicePreviewApi(md5_hash, uuid_string, email)
        } else {
            callClientEstimatePreviewApi(md5_hash, uuid_string, email)
        }
        
        
    }, [invoice_uuid]);

    const callClientInvoicePreviewApi = (md5_hash, uuid_string, email) => {
        clientPreviewApi(md5_hash, uuid_string, email)
            .then(async(response) => {
                const res = response.data;
                consoleToLog('Response clientPreviewApi: ', res);
                let image = res.invoice?.entity?.image;
                //getDownloadSignedUrl(res.invoice);

                setInvoiceEstimateObj(res.invoice);
                setOtherInvoices(res.results);
                setESignUrl(res.signature);
                setSignUrl(image);
                setLoading(false);
                // const data = await getInvoiceTransactions(invoice_account_id, invoice_id, props);
                // setInvoiceTransactions(data);
            })
            .catch((e) => {
                consoleToLog('Error clientPreviewApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
            })
    }

    const callClientEstimatePreviewApi = async (md5_hash, uuid_string, email) => {

        try {
            const response  = await getClientEstimatePreviewObjApi(md5_hash, uuid_string, email)
            const res = response.data;
            consoleToLog('Response getClientEstimatePreviewObjApi: ', res);
            let image = res.entity?.image;
            //getDownloadSignedUrl(res.invoice);

            setInvoiceEstimateObj(res);
            setSignUrl(image);
            setESignUrl(res.entity?.signature_url);
            setLoading(false);
            // const data = await getInvoiceTransactions(invoice_account_id, invoice_id, props);
            // setInvoiceTransactions(data);
        } catch(e) {
            consoleToLog('Error getClientEstimatePreviewObjApi: ', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'})
                return;
            }
        }
    }

    const getDownloadSignedUrl = async(invoice) => {
        let invoice_account_id = invoice?.invoice_account?.id;
        let image = invoice?.entity?.image;

        try {
            const response  = await getImageDownloadUrlApi(invoice_account_id, image, 'image');
            const res = response.data;
            consoleToLog("getDownloadSignedUrl", res);

            setSignUrl(res.download_signed_url);
        } catch(e) {
            consoleToLog("getDownloadSignedUrl error", e.response);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        }
    }

    const onDownloadInvoiceClick = (invoice) => {
        const invoice_id = invoice ? invoice.id : invoiceEstimateObj?.id;
        var request_url = `${process.env.REACT_APP_BASE_URL}/client/invoice/${invoice_id}/download`;

        if(request_url.indexOf("https://") !== -1){
            request_url = request_url.replace('https://','http://');
        }

        const md5_hash = md5(request_url);
        consoleToLog('download md5_hash: ', md5_hash);
        if(isActive) closeOptionsMenu();

        downloadInvoiceApi(md5_hash, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Reponse downloadInvoiceApi: ', res);

                const downloadableURL = res.document_signed_url;

                //initiate download
                document.getElementById('invoice_download').href = downloadableURL;
                document.getElementById('invoice_download').click();

            })
            .catch((e) => {
                consoleToLog('Error downloadInvoiceApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'})
                    return;
                }
            })
    }

    const onDownloadEstimateClick = async () => {
        const estimate_id = invoiceEstimateObj?.id;
        const invoice_account_id = invoiceEstimateObj?.invoice_account_id;

        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/download`);
        consoleToLog('download md5_hash: ', md5_hash);

        try {
            const response = await downloadClientEstimateApi(invoice_account_id, md5_hash, estimate_id)
            const res = response.data;

            consoleToLog('Reponse downloadClientEstimateApi: ', res);

            const downloadableURL = res.document_signed_url;

            //initiate download
            document.getElementById('estimate_download').href = downloadableURL;
            document.getElementById('estimate_download').click();

        } catch(e) {
            consoleToLog('Error downloadClientEstimateApi: ', e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'})
                return;
            }
        }

    }

    const onViewAllInvoicesClick = () => {
        setShowAllInvoicesModal(true);
    }

    const closeOtherInvoicesModal = () => {
        setShowAllInvoicesModal(false);
    }

    const onPreviewInvoiceClick = (invoice) => {
        //console.log('invoice uuid', invoice.uuid);
        history.push(`/client/invoice/${invoice.uuid}/preview`);
        closeOptionsMenu();
    }

    const closeOptionsMenu = () => {
        setIsActive(false);
        setMenuId('');
        if(fromInvoiceModal) setFromInvoiceModal(false);
    }

    const handleConfirmDialogOpen = (e, obj) => {
        e.stopPropagation();
        const estimate_obj = {
            ...invoiceEstimateObj,
            ...obj
        }
        setInvoiceEstimateObj(estimate_obj);
        setOpenConfirmDialog(true);
    }

    const clearApproveOrRejectValueInEstimateObj = (res) => {
        
        let estimateObj = res ? res : invoiceEstimateObj
        let obj = {
            ...estimateObj,
            approve_estimate_clicked: undefined,
            reject_estimate_clicked: undefined
        }
        setInvoiceEstimateObj(obj);
    }

    const handleConfirmDialogClose = (res) => {
        setOpenConfirmDialog(false);
        clearApproveOrRejectValueInEstimateObj(res);

    }

    const showDropDown = (invoice) => {
        let marginStyle = fromInvoiceModal ? classes.modalMarginDropdown : classes.marginDropdown;
        return (
            <>
            {invoice.id === menuId &&
                <ClickAwayListener onClickAway={closeOptionsMenu}>
                <Grid item className={`menu1 ${isActive ? `active ${marginStyle}` : "inactive"}`}>
                    <ul>

                        <li onClick={(e) => {
                            e.stopPropagation();
                            onPreviewInvoiceClick(invoice)
                        }}>
                            <a>
                                <VisibilityOutlinedIcon fontSize='small'  
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Preview
                            </a>
                        </li>

                        <li onClick={(e) => {
                            e.stopPropagation();
                            onDownloadInvoiceClick(invoice)
                        }}>
                            <a>
                                <CloudDownloadOutlinedIcon fontSize='small'  
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Download
                                <Link
                                    style={{ display: "none" }}
                                    id="invoice_download"
                                    target="_blank"
                                    onClick={(e) => e.stopPropagation()}
                                    download
                                ></Link>
                            </a>
                        </li>

                        {/* <li>
                            <a>
                                <FilterNoneOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons}`}/>
                                Get shareable link
                            </a>
                        </li> */}
                    </ul>
                </Grid>
                </ClickAwayListener>
                }
            </>
        )
    }

    const ShowDesktopView = () => {
        return(
        <Grid item container alignItems='center'
            className={classes.thirdRow}>
            {items && items.length > 0 &&
            <Grid item sm={12} >
                <Grid item container
                    alignItems='center' 
                    className="table_container">
                    <Grid item md={4} sm={4}>
                        <Typography variant="subtitle1" 
                            className='bill_label'>
                            Item
                        </Typography>
                    </Grid>

                    <Grid item md={2} sm={2}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            Cost
                        </Typography>
                    </Grid>

                    <Grid item md={2} sm={2} className={classes.customColumn3}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            QTY
                        </Typography>
                    </Grid>

                    {invoice_uuid && isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                    <Grid item sm={2} className={classes.customColumn4}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            HSN/SAC code
                        </Typography>
                    </Grid>
                    }

                    <Grid item md={invoice_uuid &&isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 4} 
                        className={invoice_uuid && isInvoiceAccountCountryIndia(invoiceAccountCountry) ? classes.customColumn5 : classes.customColumn6}
                        style={{textAlign:'right'}}>
                        <Typography variant="subtitle1"
                            className='bill_label'>
                            Amount
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>}

            {items && items.length > 0 &&
                items.map((invoice) => {
                return <Grid item sm={12} key={invoice.id}>
                            <Grid item container style={{padding:'8px'}}>
                                <Grid item md={4} sm={4}>
                                    <Typography className='preview_list_name_style'>
                                        {invoice.item_name}
                                    </Typography>

                                    <Typography className="preview_list_style">
                                        {invoice.item_description} 
                                    </Typography>
                                </Grid>

                                <Grid item md={2} sm={2}>
                                    <Typography className="preview_list_style">
                                        {/* {invoice.cost.toFixed(2)}₹ */}
                                        {formatAmount(invoice.cost, currenciesObj)}
                                    </Typography>
                                </Grid>

                                <Grid item md={2} sm={2} className={classes.customColumn3}>
                                    <Typography className="preview_list_style">
                                        {invoice.quantity.toFixed(2)}
                                    </Typography>
                                </Grid>

                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                <Grid item md={2} sm={2} className={classes.customColumn4}>
                                    <Typography className="preview_list_style">
                                        {invoice.hsn_code}
                                    </Typography>
                                </Grid>
                                }

                                <Grid item md={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 2 : 4} 
                                    className={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? classes.customColumn5 : classes.customColumn6}
                                    style={{textAlign:'right'}}>
                                    <Typography className="preview_list_style">
                                        {/* {invoice.price.toFixed(2)}₹ */}
                                        {formatAmount(invoice.price, currenciesObj)}
                                    </Typography>
                                    {
                                        invoice.tax.map((taxObj) => {
                                            return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500',  color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                        {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + `(${taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0})`}
                                                    </Typography>
                                        })
                                    }

                                    {
                                        invoice.discount.map((discount) => {
                                            return  <Grid item xs={12}>
                                                        {
                                                            discount.type === DiscountTypes.PERCENT 
                                                            ?
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {`${discount.value}% (${formatAmount(discount.amount, currenciesObj)})`}
                                                            </Typography>
                                                            :
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {` ${formatAmount(discount.amount, currenciesObj)}`}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        <div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>
                    </Grid>
                })
            }    
        </Grid>
        )
    };

    const ShowMobileView = () => (
        <Grid item container 
            direction="column"
            justifyContent='space-between'
            className={classes.thirdRow}>
            {items && items.length > 0 &&
                items.map((invoice) => {
                return <Grid item sm={12} key={invoice.id} className={classes.mobileListContainer}>
                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Item
                                        </Typography>
                                    </Grid>
                                    <Typography className='mobile_list_name_style'>
                                        {invoice.item_name}
                                    </Typography>

                                    <Typography  className="mobile_list_style">
                                        {invoice.item_description} 
                                    </Typography>
                                </Grid>

                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item sm={12} className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Cost
                                        </Typography>
                                    </Grid>
                                    <Typography className="mobile_list_style">
                                        {/* {invoice.cost.toFixed(2)}₹ */}
                                        {formatAmount(invoice.cost, currenciesObj)}
                                    </Typography>
                                </Grid>

                                {isInvoiceAccountCountryIndia(invoiceAccountCountry) &&
                                <Grid item sm={12} className={classes.mobileMargin}>
                                    <Grid item sm={12} className="table_container">
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            HSN/SAC code
                                        </Typography>
                                    </Grid>
                                    <Typography className="mobile_list_style">
                                        {invoice.hsn_code}
                                    </Typography>
                                </Grid>}

                                <Grid item container className="table_container"
                                    justifyContent='space-between'>
                                    <Grid item sm={6}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            QTY
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={6} style={{textAlign:'right'}}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Amount
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container justifyContent='space-between'>
                                    <Grid item sm={6} className={classes.mobileMargin}>
                                        <Typography className="mobile_list_style">
                                            {invoice.quantity.toFixed(2)}
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={6} style={{textAlign:'right'}}>
                                        <div style={{marginRight:'8px'}}>
                                        <Typography className="mobile_list_style">
                                            {/* {invoice.price.toFixed(2)}₹ */}
                                            {formatAmount(invoice.price, currenciesObj)}
                                        </Typography>
                                        {
                                            invoice.tax.map((taxObj) => {
                                                return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500',  color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                            {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + `(${taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0})`}
                                                        </Typography>
                                            })
                                        }

                                    {
                                        invoice.discount.map((discount) => {
                                            return  <Grid item xs={12}>
                                                        {
                                                            discount.type === DiscountTypes.PERCENT 
                                                            ?
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {`${discount.value}% (${formatAmount(discount.amount, currenciesObj)})`}
                                                            </Typography>
                                                            :
                                                            <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                Discounted {` ${formatAmount(discount.amount, currenciesObj)}`}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                        })
                                    }
                                        </div>
                                        
                                    </Grid>

                                </Grid>        
                        <div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>
                    </Grid>
                })
            }    
        </Grid>
    );
    
    const onApproveOrRejectClick = async (path) => {
        const invoice_account_id = invoiceEstimateObj?.invoice_account_id;
        const estimate_id = invoiceEstimateObj?.id;
        const md5_hash = generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/${path}`)
        
        setApiLoading(true);
        
        try {
            const response  = await approveRejectEstimateApi(invoice_account_id, md5_hash, estimate_id, path)
            const res = response.data;
            consoleToLog('Response approveRejectEstimateApi: ', res);
            setApiLoading(false);

            enqueueSnackbar(`${estimate_terminology} ${path === 'approve' ? 'Approved' : 'Rejected'} successfully!`, {variant:'success'});
            handleConfirmDialogClose(res);
            
        } catch(e) {
            consoleToLog('Error approveRejectEstimateApi: ', e.response);
            setApiLoading(false);
            handleConfirmDialogClose();
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'})
                return;
            }
        }
    }

    return (
        <div className={classes.mainContent}>
            {loading ?
            <CircularProgress size={30} style={{position:'absolute', top:'50px', left:'50%', right:'50%'}}/>
            :
            <Container className={classes.container} maxWidth="xl">
                    {signUrl &&
                    <Grid item container justifyContent="center" style={{margin:'24px 0px 0px 0px'}}>
                        <img src={signUrl} width="80" />
                    </Grid>}
                <Grid item container justifyContent='center' style={{flexWrap: 'wrap-reverse', marginTop:'24px'}}>
                    <Grid item md={7} sm={12} xs={12}
                        className={classes.createFormContainer}>
                        <Grid item container alignItems='center'
                            justifyContent='space-between' 
                            className={classes.firstRow}>

                            <Grid item sm={width < 1500 ? 4 : 5} xs={12}
                                style={{marginLeft:width < 700 ? '0px' : '16px'}}>
                                <Typography className='preview_invoice_text_style'>
                                    <b>{invoice_uuid ? 'Invoice Number:' : `${estimate_terminology} Number`}</b> {sequence_str}
                                </Typography>
                                {invoiceEstimateObj?.reference_number &&
                                <Typography className='preview_invoice_text_style'>
                                    <b>Reference Number:</b> {invoiceEstimateObj?.reference_number}
                                </Typography>}

                            </Grid>

                            <Grid item sm={width < 1500 ? 7 : 6} xs={12}>
                                <Grid item container direction={invoiceEstimateObj?.reference_number ? 'column' : 'row'} 
                                justifyContent={ width > 700 ? "flex-end" : 'flex-start'} 
                                alignItems={invoiceEstimateObj?.reference_number ? width > 700 ? 'flex-end' : 'flex-start' : 'center'}
                                style={{marginTop: width > 700 ? '0px' : '16px'}}>
                                    <Grid item style={{marginRight:invoiceEstimateObj?.reference_number ? '8px' : '16px'}}>
                                        <Typography className='preview_invoice_text_style'>
                                            <b>Issued: </b>  
                                            {showDate(invoiceEstimateObj?.issue_date)}
                                        </Typography>
                                    </Grid>

                                    <Grid item style={{marginRight:'8px'}}>
                                        <Typography className='preview_invoice_text_style'>
                                            <b>Due: </b> 
                                            {showDate(invoiceEstimateObj?.due_date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> 

                        <Grid item container alignItems='center'
                            justifyContent='space-between' 
                            className={classes.secondRow}>

                                {client_name &&
                                <Grid item>
                                    {
                                    invoiceEstimateObj?.entity.logo_url ? 
                                        <div className={classes.imgContainer} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <img className={classes.imageFile}
                                                src={invoiceEstimateObj?.entity.logo_url ? invoiceEstimateObj?.entity.logo_url : "/images/entity_business-and-trade.png"}
                                                id="input_InvoiceAccountPhoto"
                                                alt="entity logo"
                                            />
                                        </div> : 
                                    <Avatar className={classes.avatarStyle} 
                                        style={{background:'white', color:'#202020', border:'1px solid #202020'}}>
                                        {displayInitials(client_name)}
                                    </Avatar>
                                    }
                                </Grid>}

                                {over_due && invoiceEstimateObj?.status !== 'paid' ?
                                <Grid item>
                                    <img src="/images/eprocessify_pastdue.png"
                                        className={classes.statusImage} alt='status image'/>
                                </Grid>
                                :
                                (invoiceEstimateObj?.status === 'partial' || invoiceEstimateObj?.status === 'paid') &&
                                <Grid item>
                                    <img src={showStatusImages(invoiceEstimateObj?.status)} 
                                        className={classes.statusImage} alt='status image'/>
                                </Grid>}

                                <Grid item style={{textAlign:'right'}}>
                                    <Typography className='preview_invoice_style'>
                                        {invoice_uuid && displayInvoiceType(invoiceType)}  {invoice_uuid ? 'Invoice' : estimate_terminology}
                                    </Typography>
                                    <Typography className={classes.previewInvoiceDescriptionStyle}>
                                        {description}
                                    </Typography>
                                </Grid>

                        </Grid> 

                        <div className='modalBorderBottom1'></div>

                        <Grid item container 
                            justifyContent='space-between' 
                            className={classes.thirdRow}>
                                <Grid item md={5} sm={6}> 
                                    <Typography className='bill_label'>
                                        Bill To:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {invoiceEstimateObj?.client.name}
                                    </Typography>
                                    { <Typography className={classes.clientOwnerAddress}>
                                        {<div>{invoiceEstimateObj?.client.address}</div>}
                                        {<div>{invoiceEstimateObj?.client.state && `${invoiceEstimateObj?.client.state},`} {invoiceEstimateObj?.client.country}</div>}
                                        {/* {invoiceEstimateObj?.client.tax_id &&
                                            <div>Tax Id: {invoiceEstimateObj?.client.tax_id}</div>
                                        } */}
                                        {
                                            invoiceEstimateObj?.client.contacts && invoiceEstimateObj?.client.contacts.length > 0 &&
                                            invoiceEstimateObj?.client.contacts.slice(0, 1).map((contact) => {
                                                return <div key={contact.id}>
                                                        {
                                                            contact.email && <div> Email - {contact.email} </div>
                                                        }
                                                        {
                                                            contact.phone_number && <div> Phone - {contact.phone_number} </div>
                                                        }
                                                        </div>
                                            })
                                        }
                                        {/* {
                                            invoiceEstimateObj?.client.phone_number &&
                                            <div>Tel: {invoiceEstimateObj?.client.phone_number}</div>
                                        }
                                        {
                                            invoiceEstimateObj?.client.email &&
                                            <div>Email: {invoiceEstimateObj?.client.email}</div>
                                        } */}
                                        {
                                            invoiceEstimateObj?.client.user_defined_field && invoiceEstimateObj?.client.user_defined_field.length > 0 && 
                                            invoiceEstimateObj?.client.user_defined_field.map(udf => {
                                                return (udf.value !== '' && udf.show_invoice) ?
                                                <div key={udf.value}>{udf.name}: {udf.value}</div>
                                                :
                                                <div></div>
                                            })
                                        }
                                    </Typography> }
                                </Grid>

                                <Grid item md={5} sm={6} style={{textAlign:'right'}}>
                                        <Typography className='bill_label'>
                                            Bill From
                                        </Typography>
                                        <Typography className={classes.clientOwnerName}> 
                                            {entityObj?.name}
                                        </Typography>
                                        <Typography className={classes.clientOwnerAddress}> 
                                            <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                            </div>
                                            <div>{entityObj?.country}</div>
                                            <div>Tel: {entityObj?.mobile}</div>
                                            <div>Email: {entityObj?.email}</div>
                                            {entityObj?.tax_id &&
                                                <div>Tax Id: {entityObj?.tax_id}</div>
                                            }
                                        </Typography>
                                </Grid>
                        </Grid> 

                        <div className='modalBorderBottom1'></div>

                        {width < 600 ? 
                        <ShowMobileView />
                            :
                        <ShowDesktopView />
                        } 

                        <Grid item sm={12} 
                            style={{margin:width < 700 ? '-8px 8px 0px 8px' : '-16px 0px'}}>
                            <Grid item container justifyContent="space-between" 
                                className={classes.thirdRow}  
                                style={{flexWrap:'wrap-reverse'}}>
                                    
                                <Grid item md={4} sm={width < 700 ? 12 : 5} xs={12} 
                                    style={{marginTop: width < 700 ? '32px' : '0px'}}
                                    className={classes.paymentClientStyles}>
                                {invoiceEstimateObj?.show_payment_terms &&
                                    <Grid item sm={12} xs={12}>
                                        <Typography className='font_styles'>
                                            PAYMENT TERMS
                                        </Typography>

                                        <Typography className='clientOwner_address'
                                            dangerouslySetInnerHTML={{__html: invoiceEstimateObj?.payment_terms}}>
                                        </Typography>
                                    </Grid>}
                                    
                                {invoiceEstimateObj?.show_client_notes &&
                                    <Grid item sm={12} xs={12} style={{marginTop:invoiceEstimateObj?.show_payment_terms &&'24px', width:'40ch'}}>
                                        <Typography className='font_styles'> 
                                            CLIENT NOTES
                                        </Typography>

                                        <Typography className='clientOwner_address' 
                                            dangerouslySetInnerHTML={{__html: invoiceEstimateObj?.client_notes}}>
                                        </Typography>
                                    </Grid>}

                                </Grid>

                                <Grid item md={7} sm={width < 700 ? 12 : 6} xs={12} 
                                    style={{marginRight:width > 700 ? '8px' : '0px'}}>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12} className='mobile_flex_display'> 
                                            <Typography className='list_item_styles'>
                                                Subtotal
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceEstimateObj?.subtotal.toFixed(2)}₹ */}
                                                {formatAmount(invoiceEstimateObj?.subtotal, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                    {
                                        invoiceEstimateObj?.discount.map((discount) => {
                                            return <Grid item sm={12} xs={12}
                                                        key={discount.value}
                                                        className="item_marginTop mobile_flex_display"> 
                                                        <Typography className='list_item_styles'>
                                                            Discount
                                                        </Typography>
                    
                                                        <Typography className='list_item_styles'>
                                                            {`${formatAmount(discount.amount, currenciesObj)}`}
                                                        </Typography>
                                                    </Grid>
                                        })
                                    }
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                    {
                                        invoiceEstimateObj?.total_tax.map((taxObj) => {
                                        return <Grid item sm={12} xs={12}
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop mobile_flex_display"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0}
                                                    </Typography>
                                                </Grid>
                                        })
                                    }
                                    </Grid>

                                    {isInvoiceAccountCountryIndia(invoiceAccountCountry) && invoiceEstimateObj?.tcs && invoiceEstimateObj.tcs?.name &&
                                    <Grid item sm={7} style={{marginLeft:'auto'}}>
                                        <Grid item container justifyContent='space-between'
                                            className="item_marginTop">

                                            <Tooltip title={invoiceEstimateObj?.tcs?.name} arrow>
                                                <Typography 
                                                    className={`list_item_styles ${classes.tdsDisplay}`}>
                                                    TCS ({invoiceEstimateObj?.tcs?.name})
                                                </Typography>
                                            </Tooltip>

                                            <Typography className='list_item_styles'>
                                                {formatAmount(invoiceEstimateObj.tcs.value, currenciesObj)}
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>}

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>

                                        <div className={width < 700 ? 'bill_invoice' : 'bill_invoice1'}></div>

                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Total
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceEstimateObj?.total.toFixed(2)}₹ */}
                                                {formatAmount(invoiceEstimateObj?.total, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Paid to date
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceEstimateObj?.paid.toFixed(2)}₹ */}
                                                {formatAmount(invoiceEstimateObj?.paid, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={7} sm={7} className={classes.customMarginLeft}>
                                        <Grid item sm={12} xs={12}
                                            className="item_marginTop mobile_flex_display"> 
                                            <Typography className='list_item_styles'>
                                                Amount Due (INR)
                                            </Typography>

                                            <Typography className='list_item_styles'>
                                                {/* {invoiceEstimateObj?.due.toFixed(2)}₹ */}
                                                {formatAmount(invoiceEstimateObj?.due, currenciesObj)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {entityObj?.enable_esign ? <div className="border_bottom_two"></div> : <div className="dottedborderBottom"></div>}

                        {entityObj?.enable_esign &&
                            <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                                <Grid item container direction='column' alignItems='flex-end'>
                                    
                                    {eSignUrl &&
                                        <Grid item style={{position:'relative', top:'3px'}}>
                                            <div className={'eSign_style'}>
                                                <img src={eSignUrl} width='40%' />
                                            </div>
                                            <Typography style={{fontWeight:400, marginTop:'4px'}}>
                                                Authorized Signatory
                                            </Typography>
                                        </Grid>
                                    }

                                </Grid>
                                <div className="dashed_border"></div>
                            </Grid>}

                        {invoice_uuid && invoiceEstimateObj?.show_payment_stub &&
                    <Grid item sm={12} className={classes.thirdRow}>
                        <Grid item container justifyContent="space-between">
                                <Grid item md={5} sm={width < 700 ? 12 : 5} xs={12}
                                    className={`payment_stubContainer ${classes.paymentStubStyle}`}>
                                    <Typography className='clientOwner_address'>
                                        Bill from:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {entityObj.name}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                            <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                            </div> 
                                            <div>{entityObj?.country}</div>
                                            <div>Tel: {entityObj?.mobile}</div>
                                            <div>Email: {entityObj?.email}</div>
                                            {entityObj?.tax_id &&
                                                <div>Tax Id: {entityObj?.tax_id}</div>
                                            }
                                    </Typography>   
                                </Grid>

                            <Grid item md={5} sm={width < 700 ? 12 : 5} xs={12} 
                                style={{margin:width < 700 ? '16px 8px 0px 16px' : '0px 8px 0px 0px'}}>
                                <Grid item container justifyContent='space-between'> 
                                    <Typography className='list_item_styles'>
                                        Client
                                    </Typography>

                                    <Typography className='list_item_styles' 
                                    style={{
                                        width: '180px',
                                        wordWrap:'break-word',
                                        textAlign:'right'
                                    }}>
                                    {invoiceEstimateObj?.client?.name}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                       Invoice
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                       #{invoiceEstimateObj?.invoice_number}
                                    </Typography>
                                </Grid>

                                {
                                   invoiceEstimateObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.amount ? formatAmount(taxObj.amount, currenciesObj) : 0}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                <div className="bill_invoice"></div>


                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Due (INR)
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {invoiceEstimateObj?.due.toFixed(2)}₹ */}
                                        {formatAmount(invoiceEstimateObj?.due, currenciesObj)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Enclosed
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {invoiceEstimateObj?.paid.toFixed(2)}₹ */}
                                        {formatAmount(invoiceEstimateObj?.paid, currenciesObj)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    {invoice_uuid && invoiceEstimateObj?.show_upi_qr_code && invoiceEstimateObj?.entity?.upi_id && <div className='modalBorderBottom1'></div>}

                    {invoice_uuid && invoiceEstimateObj?.show_upi_qr_code && invoiceEstimateObj?.entity?.upi_id &&
                        <Grid item xs={12} style={{margin:'16px 0px'}}>
                            <Typography className='font_styles' style={{textAlign:'center'}}> 
                                UPI QR Code
                            </Typography>

                            <div style={{ height: "auto", 
                                        maxWidth: 120,
                                        width: "100%",
                                        margin:'8px auto 0px auto' }}>

                                    <QRCode 
                                        value={`upi://pay?pa=${invoiceEstimateObj?.entity?.upi_id}&am=${0}`}
                                        size="120"
                                        //logoImage="https://i.postimg.cc/5tdHfkxF/118852864-1241633666213183-4722008391193475906-n.png"
                                        logoWidth="80"
                                        logoHeight="100"
                                        logoOpacity="0.6"
                                    />
                            </div>

                            {
                                invoiceEstimateObj?.entity?.upi_id &&
                                <Typography variant='subtitle1' style={{textAlign:'center', marginTop:'8px', fontWeight:500}}>
                                    UPI ID: {invoiceEstimateObj?.entity?.upi_id}
                                </Typography>
                            }
                        </Grid>} 
                    </Grid>

                    <Grid item md={3} sm={12} xs={12} className={classes.secondColumnContainer}>
                        <Grid item container direction='column' alignItems='center' style={{gap:'16px'}}>

                            <Grid item sm={12} xs={12} className="download_invoice"
                                style={{display:'flex', flexDirection:'column', gap:'16px'}}>

                                {invoiceEstimateObj?.approval_required &&
                                <Button fullWidth className={`${classes.buttonStyles} ${classes.approveButton}`}
                                    onClick={(e) => handleConfirmDialogOpen(e, {approve_estimate_clicked: true})}
                                    variant="outlined">
                                    <CheckCircleOutlineOutlinedIcon fontSize='small'
                                        style={{marginRight:'5px', fontSize:'16px'}} />
                                        Approve 
                                </Button>}

                                {invoiceEstimateObj?.approval_required &&
                                <Button fullWidth className={`${classes.buttonStyles} ${classes.rejectButton}`}
                                    onClick={(e) => handleConfirmDialogOpen(e, {reject_estimate_clicked: true})}
                                    variant="outlined">
                                    <CancelOutlinedIcon fontSize='small'
                                        style={{marginRight:'5px', fontSize:'16px'}} />
                                        Reject

                                </Button>
                                }

                                <Button fullWidth className={classes.buttonStyles}
                                    onClick={() => invoice_uuid ? onDownloadInvoiceClick('') : onDownloadEstimateClick()}
                                    variant="contained" style={{background:'#4a5568', color:'#fff'}} >
                                    <PictureAsPdfIcon fontSize='small'
                                        style={{marginRight:'5px', fontSize:'16px'}} />
                                        Download
                                        <Link
                                            style={{ display: "none" }}
                                            id={invoice_uuid ? "invoice_download" : "estimate_download"}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                            download
                                        ></Link>    
                                </Button>
                            </Grid>

                            {otherInvoices && otherInvoices.length > 0 &&
                            <Grid item sm={12} xs={12} className="download_invoice" 
                                style={{marginTop:'16px'}}>    
                                <Typography variant="subtitle1"
                                    style={{textAlign:'center', marginBottom:'8px'}}>
                                    Other Invoices
                                </Typography> 

                                {
                                    otherInvoices && otherInvoices.length > 0 &&
                                    otherInvoices.slice(0, 5).map((invoice) => {
                                        return <Grid container justifyContent='space-between' alignItems='center'
                                                    key={invoice.id}
                                                    style={{position:'relative'}}
                                                    className={classes.itemStyle}>
                                                    <Typography className='list_item_styles' style={{cursor:'pointer'}}>
                                                        {<span className={classes.invoiceTypeStyle}>
                                                            {displayInvoiceTypeLabel(invoice.invoice_type)}
                                                        </span>} 
                                                        <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                                            {invoice.inv_number}
                                                        </span>
                                                    </Typography>
                
                                                    <div className={classes.invoiceListContainer}>
                                                        <Typography className='list_item_styles'>
                                                        {showDate(invoice?.due_date)}
                                                        </Typography>
                
                                                        <Grid item md={1} lg={1} 
                                                        style={{position:'relative', 
                                                            marginLeft: width < 992 ? '0' : '-8px', 
                                                            marginRight:width < 992 ? '0' : '12px',
                                                            top:'4px'}}>
                                                            <Tooltip title="More Actions" arrow>  
                                                                <MoreHorizOutlinedIcon fontSize='small'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setIsActive(!isActive);
                                                                    setMenuId(invoice.id);
                                                                }}
                                                                className='action__icon'/>
                                                            </Tooltip>
                                                            {!fromInvoiceModal && showDropDown(invoice)}
                                                        </Grid> 
                                                    </div>
                
                                                </Grid>
                                                })
                                }   

                                <Button variant="outlined" 
                                    color="primary" 
                                    onClick={onViewAllInvoicesClick}
                                    style={{marginTop:'24px', width:'100%'}}>
                                    <i className='flaticon-file' 
                                        style={{marginRight:'8px', display:'flex', alignItems:'center'}}></i>
                                    View All Invoices
                                </Button>
                            </Grid>}

                            {invoiceTransactions && invoiceTransactions.length > 0 &&
                                <PaymentReceipt invoiceEstimateObj={invoiceEstimateObj}
                                    invoiceTransactions={invoiceTransactions}
                                    invoice_account_id={invoice_account_id}
                                    fromClientPreview={true}
                                    invoiceObj={invoiceEstimateObj}
                                    generateMd5ForApi={generateMd5ForApi}
                                    />
                            }
                        </Grid>
                    </Grid>              

                </Grid>
            </Container>}

            {showAllInvoicesModal &&
            <OtherInvoicesModal showAllInvoicesModal={showAllInvoicesModal}
                otherInvoices={otherInvoices}
                invoiceEstimateObj={invoiceEstimateObj}
                closeOtherInvoicesModal={closeOtherInvoicesModal}
                showDropDown={showDropDown}
                setIsActive={setIsActive}
                setMenuId={setMenuId}
                isActive={isActive}
                setFromInvoiceModal={setFromInvoiceModal}
                fromInvoiceModal={fromInvoiceModal}
            />}

            {
                openConfirmDialog && 
                <ShowConfirmDialog openConfirmDialog={openConfirmDialog}
                    handleConfirmDialogClose={handleConfirmDialogClose}
                    fromClientEstimatePreview={true}
                    estimateItem={invoiceEstimateObj}
                    onApproveOrRejectClick={onApproveOrRejectClick}
                    apiLoading={apiLoading}
                />
            }
        </div>
    );
}

export default PreviewAndDownloadInvoice;