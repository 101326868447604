import React from 'react';
import BackgroundPreview from './BackgroundPreview';
import {Grid} from '@material-ui/core';
import { ReadOnlyEditor } from '../edit/BaseEditor';

const WhyUsPreview = ({item, index, normalUserPreview, brand_color}) => {
    const {data} = item;
    const {title, description, bullet_points} = data;

    const descpStyle = normalUserPreview ? 'preview_description_style1' : 'preview_description_style';

    return (
        <BackgroundPreview item={item} title={title}
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >
            <Grid item md={12} className={descpStyle}>
                <ReadOnlyEditor editorValue={description}/>
            </Grid>

            <Grid item md={12}>
                <div className={descpStyle}>
                    {
                        bullet_points && bullet_points.length > 0 &&
                        bullet_points.map((item) => {
                            let description = `__${item.title}__: ${item.description}`
                            return (
                                <ReadOnlyEditor editorValue={description}/>
                            )
                        })
                    }
                </div>
            </Grid>
        </BackgroundPreview>
    );
}

export default WhyUsPreview;