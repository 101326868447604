import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {lightenColor} from '../../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    backgroundContainer: {
        position: "relative",
        minHeight: "1250px",
        height: '100%',
        background: "#fff",
        boxShadow: '0px 0px 25px 0px #00000026',
        overflow:'hidden',
        padding:'80px',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
            minHeight: "800px",
            height: '100%',
        },
    },
    backgroundContainer1: {
        position: "relative",
        padding: "60px",
        minHeight: "1000px",
        height: '100%',
        background: "#fff",
        boxShadow: '0px 0px 25px 0px #00000026',
        overflow:'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
            minHeight: "800px",
            height: '100%',
        },
    },
    bookmarkImage: {
        position:"relative",
        top:'0px',
        width: '168px',
        height: '281px',
        [theme.breakpoints.down('sm')]: {
            width: '80px',
            height: '120px',
            top:'0px',
        },
    },
    titleStyles: {
        marginTop:"32px",
        fontSize:'38px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize:'24px',
            fontWeight: 600
        },
    },
    titleStyles1: {
        marginTop:"32px",
        fontSize:'28px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize:'21px',
            fontWeight: 600
        },
    },
    dottedImage: {
        position: 'absolute',
        bottom:140,
        right:120,
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            bottom: 60,
            right:40,
        },
    },
    dottedImage1: {
        width:'151px',
        height: '151px',
        position: 'absolute',
        bottom:140,
        right:120,
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            bottom: 60,
            right:40,
        },
    },
    descriptionStyle: {
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            width: '86%',
        },
    },
    pageNoStyle: {
        position:'absolute',
        right: '90px',
        bottom:'90px',
        [theme.breakpoints.down('sm')]: {
            right: '30px',
            bottom:'30px',
        },
    },
    pageNoCircle: {
        width:"20px",
        height:"20px",
        borderRadius:"25px",
        [theme.breakpoints.down('sm')]: {
            width:"15px",
            height:"15px",
        },
    },
    pageNoText: {
        fontSize:'18px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px',
        },
    }
}));

const BackgroundPreview = ({item, index, title, normalUserPreview, brand_color, children}) => {
    const classes = useStyles();
    
    const lighterColor = lightenColor(brand_color, 95);
    // console.log('lighterColor', lighterColor)
    document.documentElement.style.setProperty("--original-brand-color", brand_color);
    document.documentElement.style.setProperty("--lighter-color", lighterColor);

    const backgroundClass = normalUserPreview ? classes.backgroundContainer1 : classes.backgroundContainer;
    const titleClass = normalUserPreview ? classes.titleStyles1 : classes.titleStyles;
    const dottedImage = normalUserPreview ? classes.dottedImage1 : classes.dottedImage;
    const bottomArc = normalUserPreview ? 'preview_bottom_arc1' : 'preview_bottom_arc';

    return (
        <Box className={backgroundClass} 
            style={{background:lighterColor }}>

                <div className='preview_top_arc outward' style={{background: brand_color}}></div>
                
                {item.type !== 'team' &&
                <Typography className={titleClass} style={{color:brand_color}}>
                    {title}
                </Typography>}
                

                <Box mt={4} mb={4} className={classes.descriptionStyle}>        
                    {children}
                </Box>
                {item.type !== 'cover_page' && <img src={'/images/dotted_image.png'} alt="Bookmark" className={dottedImage} />}
                <div className={bottomArc} style={{background: brand_color}}></div>
                <div className={classes.pageNoStyle}>
                    {item.type !== 'cover_page' &&
                    <Grid item container spacing={1} alignItems='center'>
                        <Grid item>
                            <div style={{background: brand_color}}
                                className={classes.pageNoCircle}>

                            </div>
                        </Grid>


                        <Grid item>
                            <Typography  className={classes.pageNoText}
                                style={{color: brand_color}}>
                                {index}
                            </Typography>
                        </Grid>
                    </Grid>}
                </div>
        </Box>
    );
}

export default BackgroundPreview;