import React from 'react';
import BaseSection from './BaseSection';
import {Grid, Typography} from '@material-ui/core';
import { ReadOnlyEditor } from './BaseEditor';

const PaymentTerms = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {title, terms} = data;

    return (
        <BaseSection title="Payment" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}
            >

                <Grid item md={12} className='edit_section_container'>

                    <Grid item md={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: title}} />
                    </Grid>

                    <Grid item md={12}>
                        <ReadOnlyEditor editorValue={terms}/>
                    </Grid>
                    
                    
                </Grid>

        </BaseSection>
    );
};

export default PaymentTerms;
