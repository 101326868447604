import Header from "../Header";
import { makeStyles } from '@material-ui/core/styles';
import SnackbarComponent from "../SnackbarComponent";
import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { extractBasePath } from "../../util/AppUtil";

const useStyles = makeStyles((theme) => ({
    //apply full 100% width to page
    root: {
        height: "100vh"
    },
    //stretch page to full width of screen below header
    child: {
    }
}));

const BaseComponent = ({children, drawerAction, verifyEmailPage, notFoundPage, handleClickDialogOpen, handleOpenAddNewPaymentDrawer, 
    handleClientDrawerOpen, handleInvoiceDialogOpen, accessLevelViewer, setOpenDialog, setShowEstimateClicked,
    setInvoiceComponentState, setTransactionComponentState, invoicesComponentState, transactionsComponentState, 
    estimateComponentState, setEstimateComponentState, handleProposalModalOpen, proposalsComponentState, setProposalsComponentState
    }) => {

        const classes = useStyles();

        const location = useLocation();
        const pathname = location?.pathname;

        useEffect(() => {
            const basePath = extractBasePath(true);
            if(basePath === `/invoice` || basePath === `/estimate`) {
                handleInvoiceDialogOpen();
            }

            if(basePath === `/proposal`) {
                handleProposalModalOpen();
            }
        }, [pathname])

    return(
        <div className={classes.root}>
            {pathname !== '/payment/success' && pathname !== '/verify-email' && pathname !== 'setup' && 
            pathname !== '/account-email-verification-success/' && pathname !== '/account-email-verification-failure/' 
            && !accessLevelViewer &&
            <SnackbarComponent showDrawer={false}
                unVerifiedEntityEmails={[]}
                fromBaseComponent={true}/>}
            <Header drawerAction={drawerAction} 
                verifyEmailPage={verifyEmailPage}
                notFoundPage={notFoundPage}
                handleClickDialogOpen={handleClickDialogOpen}
                handleOpenAddNewPaymentDrawer={handleOpenAddNewPaymentDrawer}
                handleClientDrawerOpen={handleClientDrawerOpen}
                setOpenDialog={setOpenDialog}
                setShowEstimateClicked={setShowEstimateClicked}
                invoicesComponentState={invoicesComponentState}
                transactionsComponentState={transactionsComponentState}
                estimateComponentState={estimateComponentState}
                setInvoiceComponentState={setInvoiceComponentState}
                setTransactionComponentState={setTransactionComponentState}
                setEstimateComponentState={setEstimateComponentState}
                handleProposalModalOpen={handleProposalModalOpen}
                proposalsComponentState={proposalsComponentState} 
                setProposalsComponentState={setProposalsComponentState}
                />
            <div className={classes.child}>{children}</div>
        </div>
    )
}

export default BaseComponent;

