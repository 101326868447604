import React from 'react';
import {Grid, Typography, Button, Tooltip, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {formatAmount} from '../util/currencyUtil';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {downloadReceiptApi, clientDownloadReceiptApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    itemStyle: {
        borderBottom:'1px solid rgba(0, 0, 0, 0.1)', 
        paddingTop:'16px',
        paddingBottom:'8px'
    },
    downloadIcon: {
        fontSize:'18px',
        cursor:'pointer',
        '&:hover': {
            color:"#595d68"
        }
    }
}))

const PaymentReceipt = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const transactionArr = props.invoiceTransactions;
    const invoice_account_id = props.fromClientPreview ? props.invoice_account_id : props.selectedAccount?.id; 
    const invoice_id = props.invoiceObj?.id;

    const downloadReceipt = (transactionObj) => {
        const transaction_id = transactionObj?.id;
        const details = {};

        props.fromClientPreview ? callDownloadClientReceiptApi(transaction_id) : callUserDownloadReceiptApi(transaction_id, details)
    }

    const callUserDownloadReceiptApi = async(transaction_id, details) => {
        try {
            const response = await downloadReceiptApi(invoice_account_id, invoice_id, transaction_id, details)
            const res = response.data;
            consoleToLog("Response downloadReceiptApi: ", res);
            document.getElementById('dowload_receipt').href = res.document_signed_url;
            document.getElementById('dowload_receipt').click();

        } catch (e) {
            consoleToLog("Response downloadReceiptApi: ", e);
            e.response.data && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
    }

    const callDownloadClientReceiptApi = async(transaction_id) => {
        console.log('transaction_id', transaction_id)
        const md5_hash = props.generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/client/invoice/${invoice_id}/transaction/${transaction_id}/download_receipt`)
        try {
            const response = await clientDownloadReceiptApi(md5_hash, invoice_id, transaction_id)
            const res = response.data;
            consoleToLog("Response downloadReceiptApi: ", res);
            document.getElementById('dowload_receipt').href = res.document_signed_url;
            document.getElementById('dowload_receipt').click();

        } catch (e) {
            consoleToLog("Response downloadReceiptApi: ", e);
            e.response.data && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
    }

    return (
        <Grid item sm={12} xs={12} className="download_invoice"
            style={{marginTop:'16px'}}> 
            
            <Typography variant='body1' style={{textAlign:'center'}}>
                Receipts:
            </Typography>
            
            {
                transactionArr && transactionArr.length > 0 &&
                transactionArr.map((transaction) => {
                    const currenciesObj = transaction.currencies;
                    return <Grid item container 
                                justifyContent='space-between'
                                alignItems='center'
                                className={classes.itemStyle}
                                key={transaction?.id}
                                style={{color:'#6B7280'}}>

                                <Grid item>
                                    <Typography variant='body2'>
                                        {moment(transaction.payment_date).format('MM-DD-YYYY')}
                                    </Typography>
                                </Grid>

                                <Grid item>

                                    <Grid item container alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {formatAmount(transaction.amount, currenciesObj)}
                                            </Typography>
                                        </Grid>

                                        <Grid item id='dowload-receipt'>
                                            <Tooltip title='Download' arrow>
                                                <Button style={{padding:0, minWidth:'20px', position:'relative', top:'-3px',}}>
                                                    <CloudDownloadOutlinedIcon 
                                                        onClick={() => downloadReceipt(transaction)}
                                                        fontSize='small'
                                                        className={classes.downloadIcon}
                                                        style={{marginLeft:'4px'}}/>
                                                    <Link
                                                        style={{ display: "none" }}
                                                        id='dowload_receipt'
                                                        target="_blank"
                                                        onClick={(e) => e.stopPropagation()}
                                                        download
                                                    ></Link>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                })
            }

        </Grid>
    );
}

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
})

export default connect(mapStateToProps)(PaymentReceipt);