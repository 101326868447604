import {SendEmailType} from '../actions/invoiceAccount';

const defaultState = {
    invoiceAccountsList: [],
    selectedAccount: {},
    user: {},
    tdsTypeList:[],
    goToBilling: false
}

export default (state = defaultState, action) => {
    switch(action.type) {

        case 'SET_INVOICE_ACCOUNTS_LIST' :
            return {
                ...state,
                invoiceAccountsList : action.accountList
        }

        case 'SET_SELECTED_INVOICE_ACCOUNT' :
            return {
                ...state,
                selectedAccount : action.account
        }   
            
        case 'SET_USER_OBJ' :
            return {
                ...state,
                user : action.user_obj
            }

        case 'SET_TDS_TYPE_LIST' :
            return {
                ...state,
                tdsTypeList : action.tdsTypeList
        }


        case 'SET_GO_TO_BILLING' :
            return {
                ...state,
                goToBilling: action.goToBilling

            }

            
        case 'SET_CLEAR_INVOICE_ACCOUNTS' :    
            return defaultState
        
        default:
            return state;
    }
}