import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar } from '@material-ui/core';
import {Grid, Typography, TextField, Button, CircularProgress, IconButton, 
    InputAdornment, Tooltip, MenuItem, Select} from '@material-ui/core';
import { consoleToLog, capitalizeFirstLetterOfEachWord, shouldDisable, AccessLevel, showAllCountries, showAllStates, parseString, trackGAEvent, isInvoiceAccountCountryIndia } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { orgNameValidator, emailValidator, mobileNoValidator, nameValidator } from '../util/validator';
import { useIsMount } from './useIsMount';
import {phoneNumberValidate} from '../util/AppUtil';
import {connect} from 'react-redux';
import {setSelectedInvoiceAccount, setInvoiceAccountsList} from '../actions/invoiceAccount';
import {createUpdateInvoiceAccountEntityApi} from '../services/authService';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ClearIcon from '@material-ui/icons/Clear';
import {verifyInvoiceAccountEntityEmailApi} from '../services/authService';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import {updateEntityEsignApi} from '../services/authService';
import {getImageDownloadUrlApi} from '../services/authService';
import {Autocomplete} from '@material-ui/lab';
import countryState from '../data/countryState.json';
import country from "../data/country.json";
import { getCountryObj, getStateObj } from '../util/AppUtil';
import { SketchPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import {rgbToHex, getDominantColor} from '../util/AppUtil';
import { downloadAttachmentApi } from '../services/uploadService';


const useStyles = makeStyles((theme) => ({
    drawer: {
    //    width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: '550px',
        height:'100vh',
        flexGrow:1,
        overflow:'auto'
    },
    appBar: {
        background:'#F9F9F9',
        height:'45px',
        color:'#202020'
    },
    uploadImg: {
        position:'absolute',
        right:'0px',
        bottom:'0px',
        minWidth:'25px', 
        height:'25px', 
        borderRadius:'50%',
        background:'#3d3d3d', 
        color:'white',
        cursor:'pointer'
    },
    customColumn : {
        flexBasis:'27%',
        maxWidth:'27%'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },  
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
    selectCountry:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    selectCountry2:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"grey"
    },
    enableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
    },
    disableStyle:{
        width:"100%",
        height:"2.5rem",
        padding:"2px",
        color:"#bababa"
    },
    customMarginTop : {
        margin:'0px'
    },
    customMarginBottom: {
        marginBottom: '-4px'
    },
    signatureStyles: {
        border:'1px solid rgba(0,0,0,0.1)',
        padding:"8px",
        width:'75px',
        height: '35px',
        borderRadius:'5px'
    },
    MuiAutocompleteinputRoot : {
        '& .MuiOutlinedInput-root' : {
        padding:'0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background:'#fff',
        marginTop:'4px'
        }
    },
    customTextField: {
        "& input::placeholder": {
            paddingLeft: "8px",
        }
    },
    defaultColorStyle: {
        width:'45px',
        height: '20px',
        borderRadius: '8px',
        border:'1px solid #ccc'
    },
    defaultColorStyle1: {
        width:'30px',
        height: '30px',
        borderRadius: '30px',
        border:'1px solid #ccc',
        cursor:'pointer',
        '&:hover': {
            opacity: '80%'
        }
    },
    imgContainer :{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:"8px",
        marginLeft:'12rem',
        width:'115px',
        height:'115px',
        border: '1px solid rgba(0, 113, 255, 0.19)',
        boxSizing:'border-box',
        borderRadius:'5px'
    },
    imageFile: {
        width:'100%',
        objectFit:'cover',
        objectPosition:'center'
    }
}));

const AddEditEntityDrawer = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const { enqueueSnackbar } = useSnackbar();

    const [attachment, setAttachment] = useState(undefined);
    const [invoiceAccountName, setInvoiceAccountName] = useState('');
    const [invoiceAccountEmail, setInvoiceAccountEmail] = useState('');
    const [invoiceAccountAddress, setInvoiceAccountAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [selectedState, setSelectedState] = useState(undefined);
    const [invoiceAccountMobile, setInvoiceAccountMobile] = useState(null);
    const [invoiceAccountTaxId, setInvoiceAccountTaxId] = useState('')
    const [invoiceAccountClientNotes, setInvoiceAccountClientNotes] = useState('');
    const [invoiceAccountPaymentTerms, setInvoiceAccountPaymentTerms] = useState('');
    const [signUrl, setSignUrl] = useState(undefined);
    const [signUrlLoading, setSignUrlLoading] = useState(false);
    const [donResetEntityInfo, setDontResetEntityInfo] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [upiIdText, setUpiIdText] = useState('');
    const [brandColor, setBrandColor] = useState('#0071ff');
    const [anchorEl, setAnchorEl] = useState(null);


    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    const { image } = props.entityObj || {};
    const { access_level } = props.selectedAccount || {};
    const disabled = shouldDisable(AccessLevel.getUserAccessLevelValue(access_level), AccessLevel.OWNER);
    const isEmailVerified = props.entityObj?.is_email_verified;

    let acc_name = props.entityObj?.name;
    let acc_email = props.entityObj?.email;
    let acc_address = props.entityObj?.address;
    let acc_country = props.entityObj?.country;
    let acc_state = props.entityObj?.state;
    let acc_mobile = props.entityObj?.mobile;
    let acc_client_notes = props.entityObj?.default_client_notes;
    let acc_payment_terms = props.entityObj?.default_payment_terms;
    let acc_tax_id = props.entityObj?.tax_id;
    let acc_upi_id = props.entityObj?.upi_id;
    let acc_brand_color = props.entityObj?.brand_color;

    
    const invoice_account_id = props.selectedAccount?.id;
    const entity_id = props.entityObj ? props.entityObj?.id : undefined;
    const {countries} = country;
    const {states} = countryState;
    const img = document.getElementById('input_InvoiceAccountPhoto');
    const attachment_subtype = 'entity_signature';

    useEffect(() => {

        let countryObj = getCountryObj(countries, props.selectedAccount?.country);
        setSelectedCountry(countryObj);

        const state_list = states.filter((state) => state.country_code === props.selectedAccount?.country);
        setStateList(state_list);
    }, []);

    useEffect(() => {
            if(props.entityObj && !donResetEntityInfo) {

            setInvoiceAccountName(acc_name);
            setInvoiceAccountEmail(acc_email);
            setInvoiceAccountAddress(acc_address);

            let stateObj = getStateObj(states, acc_state, acc_country);
            setSelectedState(stateObj);

            setInvoiceAccountMobile(acc_mobile);
            setInvoiceAccountTaxId(acc_tax_id);
            setInvoiceAccountClientNotes(acc_client_notes);
            setInvoiceAccountPaymentTerms(acc_payment_terms);
            setUpiIdText(acc_upi_id);
            setBrandColor(acc_brand_color);
            setAttachment(undefined);
            if(props.entityObj?.enable_esign) {
                getDownloadSignedUrl();
            }
        }
    }, [props.entityObj]);

    useEffect(() => {
        if(!isMount) {
            if(props.entityObj && donResetEntityInfo) {
                if(props.entityObj?.enable_esign) {
                    getDownloadSignedUrl();
                }
            }
        }
    }, [props.entityObj]);

    

    useEffect(() => {
        if(img) {
            img.onload = () => {
                getDominantColor(img, (color) => {
                    const hexColor = rgbToHex(...color);
                    // document.getElementById('colorDisplay').style.backgroundColor = hexColor;
                    // document.getElementById('colorHex').textContent = `Dominant Color: ${hexColor}`;
                    setBrandColor(`${hexColor}`);
                    console.log('Dominant Color (Hex):', hexColor);
                });
            };
        }   
    }, [img])


    const onInvoiceAccountPhotoSelected = (e) => {
        consoleToLog("onOrgPhotoSelected",e.target.files[0]);
        setAttachment (e.target.files[0])
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);
        oFReader.onload = function (oFREvent) {
            setImgSrc(oFREvent.target.result);
            document.getElementById("input_InvoiceAccountPhoto").src = oFREvent.target.result;
        };
    }

    const closeDrawer = () => {
        props.handleEntityDrawerClose();
        setInvoiceAccountName('');
        setInvoiceAccountEmail('');
        setInvoiceAccountAddress('');
        setSelectedCountry(undefined);
        setSelectedState(undefined);
        setInvoiceAccountMobile('');
        setInvoiceAccountTaxId('');
        setInvoiceAccountClientNotes('');
        setInvoiceAccountPaymentTerms('');
        setAttachment(undefined);
        setStateList([]);
    }

    const getDownloadSignedUrl = () => {
        
        setSignUrlLoading(true);
        downloadAttachmentApi(invoice_account_id, attachment_subtype, props.entityObj?.signature)
            .then((response) => {
                const res =  response;
                consoleToLog('Response downloadAttachmentApi: ', res);
                setSignUrlLoading(false);

                setSignUrl(res.signed_url);
            })
            .catch((e) => {
                consoleToLog("downloadAttachmentApi error", e.response);
                setSignUrlLoading(false);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const onSaveClick = () => {

        const invoice_account_name = invoiceAccountName;
        if (!invoice_account_name) {
            enqueueSnackbar('Invoice account name can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isInvoiceAccountNameValid = orgNameValidator(invoice_account_name);
        if (!isInvoiceAccountNameValid) {
            enqueueSnackbar('Invoice account name is not valid! Please enter Alphabets only', {
                variant:'error'
            });
            return;
        }

        const invoice_account_email = invoiceAccountEmail;
        if (!invoice_account_email) {
            enqueueSnackbar('Invoice account email can not be empty!', {
                variant:'error'
            });
            return;
        }

        const isInvoiceAccountEmailValid = emailValidator(invoice_account_email);
        if (!isInvoiceAccountEmailValid) {
            enqueueSnackbar('Email is not valid!', {
                variant:'error'
            });
            return;
        }

        if(!selectedCountry) {
            enqueueSnackbar('Please Select the Country', {
                variant: 'error'
            });
            return;
        }
        const entityCountry = selectedCountry?.code;


        // if(selectedCountry !== 'IN') {
        //     enqueueSnackbar('Please Select India as Country', {
        //         variant: 'error'
        //     });
        //     return;
        // }
    
        if(!selectedState) {
            enqueueSnackbar('Please Select the State', {
                variant: 'error'
            });
            return;
        }

        let state = selectedState?.state_code;
        let address = invoiceAccountAddress;
        if (!address) {
            enqueueSnackbar('Address cannot be empty!', {
                variant:'error'
            });
            return;
        }
        address = address.trim();

        const mobile = invoiceAccountMobile ? invoiceAccountMobile : '';
        const isMobileNoValid = mobileNoValidator(mobile);
        if (mobile && !isMobileNoValid) {
            enqueueSnackbar('Please Enter Valid Mobile No.', {
                variant:'error'
            });
            return;
        }

        const tax_id = invoiceAccountTaxId ? invoiceAccountTaxId : ''
        const default_client_notes = invoiceAccountClientNotes ? invoiceAccountClientNotes : '';
        const default_payment_terms = invoiceAccountPaymentTerms ? invoiceAccountPaymentTerms : '';
        const new_image = attachment ? attachment : undefined;
        const upi_id = upiIdText ? upiIdText : '';
        const brand_color = brandColor;
        
        setLoading(true);
        createUpdateInvoiceAccountEntityApi(invoice_account_id, capitalizeFirstLetterOfEachWord(invoice_account_name), address, 
        entityCountry, state, invoice_account_email, mobile, tax_id, default_client_notes, default_payment_terms, new_image, entity_id, upi_id, brand_color)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response invoiceAccountEntityUpdateApi: ", res);
                setLoading(false);

                // if(entity_id) {
                //     let account = {
                //         ...props.selectedAccount,
                //         entities: props.selectedAccount.entities.map((entityItem) => {
                //             if(entityItem.id === res.id) {
                //                 entityItem = res;
                //             }
                //             return entityItem
                //         })
                //     };

    
                //     let accountList = props.invoiceAccountsList.map((ia)=>{
                //         if(ia.id === account.id) return account;
                //         else return ia;
                //     });
                //     props.setSelectedInvoiceAccount(account);
                //     props.setInvoiceAccountsList(accountList);

                // } else {
                //     let account = {
                //         ...props.selectedAccount,
                //         can_create_entity: res.can_create_entity,
                //         entities: [...props.selectedAccount.entities, res.entity]
                //     };

                //     let accountList = props.invoiceAccountsList.map((ia)=>{
                //         if(ia.id === account.id) return account;
                //         else return ia;
                //     });
                //     props.setSelectedInvoiceAccount(account);
                //     props.setInvoiceAccountsList(accountList);

                // }

                enqueueSnackbar("Inovice account enitity info updated", {variant: 'success'});
                closeDrawer();
                props.onEntityUpdate(res);
                //trackGAEvent(props.selectedOrganization.organization.name, ' Save Organization', `${props.auth.user.firstname} ${props.auth.user.lastname} saved organization`);
            })
            .catch((e) => {
                consoleToLog("invoiceAccountUpdateApi error", e.response);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant:'error'
                    });
                    return false;
                }
            });
    }

    const onVerifyInvoiceAccountEmail = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = props.entityObj?.id;

        verifyInvoiceAccountEntityEmailApi(invoice_account_id, entity_id)
        .then((response) => {
            const res = response.data;
            consoleToLog("Response verifyInvoiceAccountEntityEmailApi: ", res)
            enqueueSnackbar(res.message, {
                variant:'success'
            });
          //trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email verification link from settings`);
        })
        .catch((e) => {
            consoleToLog("verifyInvoiceAccountEntityEmailApi error", e);
    
            if (e && e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:'success'
                });
                return false;
            }
        });

    }

    const endAdornmentIcon = () => {
        return (
            <InputAdornment position="end" onClick={() => {
                if (!disabled && !isEmailVerified) onVerifyInvoiceAccountEmail()
            }}>
                <Tooltip title={isEmailVerified ? "Email Verified" : "Verify Email"} arrow>
                    <IconButton disableRipple style={{color: isEmailVerified ? 'green' : 'red', padding:'0', background:"none"}}>
                        {isEmailVerified ? 
                            <CheckSharpIcon fontSize="small" style={{fontSize:'18px'}}/> 
                            : 
                            <>
                                <ClearIcon fontSize="small" style={{marginRight:'4px', fontWeight:'bold', fontSize:'15px'}}/>
                                <Typography style={{color:"#0071FF"}}>Verify </Typography>
                            </>
                        }
                    </IconButton>
                </Tooltip>
                
            </InputAdornment>
        )
    }


    const handleChange = (updateEsignDrawing) => {
        if(!updateEsignDrawing && props.entityObj?.enable_esign && props.entityObj?.signature) {
            updateEntityESign(false);
            setDontResetEntityInfo(true);
        } else if(!updateEsignDrawing && !props.entityObj?.enable_esign && props.entityObj?.signature) {
            updateEntityESign(true);
            setDontResetEntityInfo(true);
        } else {
            props.handleEnableInvoiceESignModalOpen();
        }
    }

    const updateEntityESign = (enable_esign) => {

        const signature = props.entityObj?.signature;

        updateEntityEsignApi(invoice_account_id, entity_id, signature, enable_esign)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEntityEsignApi', res);

                enqueueSnackbar('Invoice e-sign settings updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === entity_id) {
                            item.signature = res.signature;
                            item.enable_esign = res.enable_esign;
                        }
                        return item
                    })
                };

                let obj = {
                    ...res,
                }
                props.setEntityObj(obj);

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });
                props.onEntityUpdate();

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);
                setDontResetEntityInfo(false);

            })
            .catch((e) => {
                consoleToLog("Error updateEntityEsignApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const onCountryNameChange = (e, newValue) => {
        setSelectedCountry(newValue);
        setSelectedState(undefined);
    }

    const onStateNameChange = (e, newValue) => {
        setSelectedState(newValue);
        props.editClientObj && props.setClientState(newValue);
    }

    const handleChangeColorPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleChangeColorPopoverClose = () => {
        setAnchorEl(null);
    }

    const handleChangeComplete = (selectedColor) => {
        setBrandColor(selectedColor.hex);
    };

    return (
        <Drawer variant="temporary"
            anchor="right"
            open={props.openEntityDrawer}
            onClose={closeDrawer}
            classes={{
                paper: classes.drawerPaper
            }}
            >
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'0px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        {props.entityObj ? 'Update Entity' : 'Add Entity'}
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-16px'}} 
                        onClick={closeDrawer}>
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div style={{padding:'0px 16px 24px 16px', height:'calc(100vh - 130px)', overflow:'auto'}}>

            <Grid item container direction="column">
                <Grid item md={8} lg={8}>
                    <div style={{display:'flex', justifyContent:'center', position:'relative'}}>
                        <div className={classes.imgContainer}>
                            <img className={classes.imageFile}
                                src={props.entityObj?.logo_url ? props.entityObj?.logo_url : "/images/entity_business-and-trade.png"}
                                id="input_InvoiceAccountPhoto"
                                alt="entity logo"
                            />
                        </div>
                        <label className={classes.uploadImg}>
                        <Typography style={{marginLeft:'4px', marginTop:'3px'}}>
                            <EditRoundedIcon fontSize="small" style={{fontSize:'18px'}}/>
                        </Typography>
                            <input style={{display:'none'}}
                                type="file"
                                id="input_InvoiceAccountPhoto"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={onInvoiceAccountPhotoSelected}
                                disabled={disabled}
                            />
                        </label>

                        <div style={{position:'absolute', bottom:5, right:'-90px'}}>
                            <Typography variant="body2">
                                Brand Color
                            </Typography>

                            <div onClick={handleChangeColorPopoverOpen}
                                style={{background:brandColor}} 
                                className={classes.defaultColorStyle1}>
                            </div>

                            <Popover
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleChangeColorPopoverClose}
                            >
                                <SketchPicker       
                                    color={brandColor}
                                    onChangeComplete={handleChangeComplete}
                                    width='93.5%'
                                />

                            </Popover>
                        </div>
                    </div>
                </Grid>  

                <Grid item container direction="column" spacing={2} style={{marginTop:'2px'}}>
                    <Grid item md={12} lg={12}>
                        <Typography variant="body2" className={classes.customMarginBottom}>
                            Name
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={invoiceAccountName}
                            placeholder="Enter Entity Name"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            disabled={disabled}
                            onChange={(e) => setInvoiceAccountName(e.target.value)}
                        />
                    </Grid> 

                    <Grid item md={12} lg={12}>
                        <Typography variant="body2" className={classes.customMarginBottom}>
                            Address   
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={parseString(invoiceAccountAddress)}
                            placeholder="Enter Entity Address"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            disabled={disabled}
                            onChange={(e) => setInvoiceAccountAddress(e.target.value)}  
                            multiline
                            maxRows={4}
                        />
                    </Grid>

                    <Grid item container alignItems='center' spacing={1}>
                    
                        <Grid item md={6} lg={6}>
                            <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                                Country
                            </Typography>   

                            <Autocomplete
                                id="combo-box-demo"
                                options={countries}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                value={selectedCountry}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, newValue) => {
                                    onCountryNameChange(e, newValue)
                                }}
                                disabled
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" 
                                    placeholder='Search Country' 
                                    fullWidth
                                    classes={{
                                        root: classes.customTextField
                                    }}
                                        />
                                )}
                            />
                        </Grid>

                        <Grid item md={6} lg={6}>
                            <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                                State
                            </Typography>   

                            <Autocomplete
                                id="combo-box-demo"
                                options={stateList}
                                classes={{
                                    root: classes.MuiAutocompleteinputRoot
                                }}
                                value={selectedState}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, newValue) => {
                                    onStateNameChange(e, newValue)
                                }}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" 
                                    placeholder='Search State' 
                                    fullWidth
                                    classes={{
                                        root: classes.customTextField
                                    }}
                                        />
                                )}
                            />
                        </Grid>
                    </Grid>
                    
                    {/* <Grid item md={12} lg={12}>
                        <Typography variant="subtitle1" style={{marginBottom:'4px'}}>
                            Country
                        </Typography>   

                        <Select 
                            className={selectedCountry === 'none' ? classes.disableStyle : classes.enableStyle}
                            value={selectedCountry}
                            onChange={onClientCountryChange}
                            variant="outlined"
                            placeholder='Select Country'
                            autoWidth
                            disabled={disabled}
                            style={{width:'100%'}}
                            >
                            {
                                selectedCountry === 'none' && <MenuItem value='none'>Select Country</MenuItem>
                            } 
                            {showAllCountries()}
                        </Select>
                    </Grid> */}

                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item md={6} lg={6}>
                            <Typography variant="body2" className={classes.customMarginBottom}>
                                Email   
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={invoiceAccountEmail}
                                placeholder="Enter Entity Email"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                onChange={(e) => setInvoiceAccountEmail(e.target.value)}  
                                InputProps={{
                                    endAdornment: props.entityObj && endAdornmentIcon()
                                }}

                            />
                        </Grid>

                        <Grid item md={6} lg={6}>
                            <Typography variant="body2" className={classes.customMarginBottom}>
                                Mobile (Optional)    
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={invoiceAccountMobile !== null ? invoiceAccountMobile : ''}
                                placeholder="Enter Entity Mobile Number"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                disabled={disabled}
                                onChange={(e) => setInvoiceAccountMobile(e.target.value)}  
                                onKeyPress={(e) => phoneNumberValidate(e)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={12} lg={12}>
                        <Typography variant="body2" className={classes.customMarginBottom}>
                            Tax Id (Optional)
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={invoiceAccountTaxId}
                            placeholder="Enter Entity Tax Id"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            disabled={disabled}
                            onChange={(e) => setInvoiceAccountTaxId(e.target.value)}
                        />
                    </Grid> 

                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item md={6} lg={6}>
                            <Typography variant="body2" className={classes.customMarginBottom}>
                                Client Notes (Optional)    
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={invoiceAccountClientNotes}
                                placeholder="Enter Invoice Client Notes"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                disabled={disabled}
                                onChange={(e) => setInvoiceAccountClientNotes(e.target.value)} 
                                multiline
                                maxRows={4} 
                            />
                        </Grid>

                        <Grid item md={6} lg={6}>
                            <Typography variant="body2" className={classes.customMarginBottom}>
                                Payment Terms (Optional)    
                            </Typography>

                            <TextField 
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={invoiceAccountPaymentTerms}
                                placeholder="Enter Invoice Payment Terms"
                                InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                                disabled={disabled}
                                onChange={(e) => setInvoiceAccountPaymentTerms(e.target.value)}  
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                    </Grid>

                    {isInvoiceAccountCountryIndia(props.selectedAccount?.country) &&
                    <Grid item md={12} lg={12}>
                        <Typography variant="body2" className={classes.customMarginBottom}>
                            UPI ID (Optional)
                        </Typography>

                        <TextField 
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            value={upiIdText}
                            placeholder="Enter Entity UPI ID"
                            InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                            disabled={disabled}
                            onChange={(e) => setUpiIdText(e.target.value)}
                        />
                    </Grid> }

                    {props.entityObj &&
                    <>
                    <Grid item md={12} lg={12}>
                        <Typography variant="body1">
                            Invoice E-Signature
                        </Typography>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.entityObj?.enable_esign ? props.entityObj?.enable_esign : false}
                                    onChange={() => handleChange(false)}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Enable E-Sign"
                        />
                    </Grid> 

                    {props.entityObj?.enable_esign &&
                    <Grid item md={12} lg={12}>
                        {signUrlLoading ?
                            <CircularProgress size={16} style={{marginLeft:"16px"}}/>
                            :
                            signUrl &&
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <img src={signUrl} className={classes.signatureStyles} />
                                </Grid>

                                <Grid item>
                                    <Tooltip title={'Edit E-Sign'} arrow>
                                        <Button style={{minWidth:'20px', marginLeft:'8px'}}
                                            onClick={() => handleChange(true)}>
                                            <EditIcon style={{fontSize:'20px'}}/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                    </Grid>}
                    </>}

                </Grid> 
            
            </Grid>


            <div className="modal__footer">
                <Button variant="contained" color="primary" 
                    onClick={onSaveClick}
                    className="modal__button">
                    {loading && <CircularProgress size={15} className='loading__style'/>}
                    {props.entityObj ? 'Update Entity' : 'Add Entity'}
                </Button>
                <Button variant="outlined" style={{
                    minWidth: '130px',
                }} onClick={closeDrawer}>
                    Cancel
                </Button>
            </div>
            </div>

        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEntityDrawer);