import React, {useState,} from 'react';
import {Grid, Typography, TextField, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';

const ThankyouDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, apiLoading, setDirtyFlag}) => {
    const {data} = itemObj;
    const {text, title} = data;

    const [greetingsTitle, setGreetingsTitle] = useState(title);
    const [greetingsDescription, setGreetingsDescription] = useState(text);

    const handleGreetingsTitleChange = (event) => {
        setGreetingsTitle(event.target.value);
        setDirtyFlag(true);
    };

    const handleGreetingsDescriptionChange = (value) => {
        setGreetingsDescription(value);
        setDirtyFlag(true);
    };

    const setUpdatedItemObj = () => {
        const dataObj = {
            text: greetingsDescription,
            title: greetingsTitle,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
        
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Thank You'
        >
            <div className='drawer_content_container'>

                <Grid item md={12}>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={greetingsTitle}
                        onChange={handleGreetingsTitleChange}
                    
                    />
                </Grid>

                <Grid item md={12}>

                    <Typography className={'drawer_label_style'}>
                        Description
                    </Typography>

                    <BaseEditor editorValue={greetingsDescription}
                        setEditorValue={handleGreetingsDescriptionChange}/>
                </Grid>

            </div>

            <div className={'drawer_footer'}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item>
                        <Button 
                            onClick={onSaveClick}
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </BaseDrawer>
    );
}

export default ThankyouDrawer;
