import React, {useState, memo} from 'react';
import {Grid, TextField, Typography, MenuItem, Box, Avatar} from '@material-ui/core';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { autoCompleteClientListApi } from '../../../services/clientService';
import { consoleToLog } from '../../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    MuiTextFieldroot: {
        border: 'none'
    },
    avatar: {
        width: '15px !important',
        height: '15px !important',
        fontSize: '12px !important',
        background: 'none !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        border: '1px solid rgba(0, 0, 0, 0.26) !important'
    },
    autoStyle: {
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    inputRoot: {
        padding: '10px', // Ensure padding is applied here
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)', // Hover border color
        }
    },
    input: {
        color: 'black', // Text color
        '&::placeholder': {
            color: 'black', // Placeholder text color
            opacity: 1, // Ensure placeholder is visible
        },
    },
}));

const SelectClient = memo(({selectedEntity, selectedClient, setSelectedEntity, setSelectedClient, 
    errorMessage, errorType, ...props}) => {
    const classes = useStyles();

    const [clientList, setClientList] = useState([]);
    const [inputText, setInputText] = useState('')
    const [open, setOpen] = useState(false);

    const {selectedAccount, resetErrorMessageAndErrorType} = props;
    const entities = selectedAccount.entities;


    const onSelectEntityClick = (e) => {
        const value = e.target.value;
        setSelectedEntity(value);
        resetErrorMessageAndErrorType();
    }

    const getClientsList = async (text) => {
        const invoice_account_id = selectedAccount.id;

        if(!text) return;
        try {
            const response = await  autoCompleteClientListApi(invoice_account_id, text);
            const res = response.data;
            consoleToLog('Response autoCompleteClientListApi: ', res);
            setClientList(res.companies);

        } catch(e) {
            consoleToLog('Response autoCompleteClientListApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        }
        
    } 

    const onAddClient = (option) => {
        setSelectedClient(option);
        resetErrorMessageAndErrorType();
    }

    const renderOptions = (option) => {
        return (
            <div style={{display: "flex", width:"100%"}} 
                className={classes.autoStyle}
                onClick={() => onAddClient(option)}     
            >
                <Avatar style={{marginRight: "6px", marginTop: "4px"}}  
                    classes={{
                        root: classes.avatar
                    }}
                >
                    {option.name.charAt(0)}
                </Avatar>
                {option.name}
            </div>
        );
    }

    const handleInputChange = (e, newValue) => {
        getClientsList(newValue);
            
        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }

    return (
        <div>
            <Box className='component_container'>
                <Grid item md={12}>
                    <Typography className='component_heading'>
                        Select Client
                    </Typography>

                    <Typography className='component_description'>
                        Choose the entity and the client for whom this proposal will be created. This ensures the proposal is tailored to the specific client's requirements.
                    </Typography>
                </Grid>

                <Grid item md={12}>
                    <Grid item container
                        alignItems='center'
                        spacing={1}>
                        
                        <Grid item md={6}>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                classes={{
                                    root:classes.MuiTextFieldroot
                                }}
                                select
                                value={selectedEntity}
                                onChange={onSelectEntityClick}
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entities.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        
                        <Grid item md={6}>
                            <Autocomplete
                                fullWidth
                                id="size-small-outlined-multi"
                                size="small"
                                open={open}
                                onOpen={() =>  {
                                    if(inputText) {
                                        setOpen(true)
                                    }
                                }}
                                onClose={() => setOpen(false)}
                                options={clientList}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => renderOptions(option)}
                                value={selectedClient}
                                onChange={(e, newValue) => setSelectedClient(newValue)}
                                onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                                classes={{
                                    inputRoot: classes.inputRoot,
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="outlined" 
                                        placeholder="Select Client"
                                        margin='dense'
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                                input: classes.input,
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>

                    <Grid item container alignItems='center'>
                        <Grid item md={6}>
                            {errorType === 1 && errorMessage &&
                            <Typography className='error_message_text'>
                                *{errorMessage}
                            </Typography>}
                        </Grid>

                        <Grid item md={6}>
                            {errorType === 2 && errorMessage &&
                            <Typography className='error_message_text'>
                                *{errorMessage}
                            </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
});

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default memo(connect(mapStateToProps)(SelectClient));
