import React, {useState} from 'react';
import { TableRow, TableCell, Grid, Tooltip, Typography, ClickAwayListener, Link, Button} from '@material-ui/core';
import {formatAmount} from '../../util/currencyUtil';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SendIcon from '@material-ui/icons/Send';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { ProposalStatus, EstimateStatusColor } from '../../util/AppUtil';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { trackGAEvent } from '../../util/AppUtil';
import { consoleToLog } from '../../util/AppUtil';
import { usePropsContext } from '../context';
import { history } from '../../routers/AppRouter';
import { useSnackbar } from 'notistack';
import { downloadProposalPdfApi } from '../../services/proposalService';

const useStyles = makeStyles((theme) => ({
    marginDropdown: {
        margin: theme.spacing(1),
    },
    estimateListHover: {
        padding:'16px 0px',
        position:'relative',
        '& .action__icon' : {
            padding:'4px 4px 0px 4px',
            border:'1px solid white',
            borderRadius:'4px',
            position:'relative',
            top:'2px'
        },
        '&:hover .action__icon' : {
            border:'1px solid #ccc'
        },
        '&:hover':{
            cursor:"pointer"
        },
        '& .more_actionButton' : {
            visibility:'hidden',
            width:'150px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton1' : {
            visibility:'hidden',
            width:'120px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
        },
        '& .more_actionButton2' : {
            visibility:'hidden',
            width:'220px',
            background:'white',
            border:'1px solid #ccc',
            borderRadius:'5px',
            fontSize:'14px',
            padding:'1px 0px',
            display:'flex',
            alignItems:'center'
        },
        '&:hover .more_actionButton' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton1' : {
            visibility:'visible'
        },
        '&:hover .more_actionButton2' : {
            visibility:'visible'
        },
    },
    statusCellWidth: {
        width:'90px'
    },
    buttonContainer: {
        display:'flex',
        justifyContent:'flex-start',
        position:'absolute', 
        right:'44px',
        [theme.breakpoints.down(1950)] : {
            right:'42px',
        },
        [theme.breakpoints.down(1450)] : {
            right:'41px',
        }
    },
    actionsIcons: {
        fontSize: '18px',
    },
    displayFlex: {
        display:'flex !important',
        alignItems:'center !important'
    },
    invoiceTypeStyle: {
        fontSize:'10px',
        borderRadius: '4px !important',
        backgroundColor: '#f9f9f9 !important',
        border: '1px solid #ececec !important',
        padding:'3px',
        marginRight:'3px'
    },
    billTypeStyle: {
        height: '22px',
        verticalAlign: 'middle',
        fontSize: '12px',
        color: '#fff', 
        textAlign: 'center',
        width:'110px',
        [theme.breakpoints.down(1500)] : {
            width:'90px',
            fontSize: '11px',
        },
        [theme.breakpoints.down(1400)] : {
            width:'80px',
            fontSize: '10px',
        }
    }
}));

const EstimateActions = ({ proposal, classes, onPreviewProposalClick, onSendProposalClick, onUndoCancelClick, onCreateInvoiceClick, onEditProposalClick,
    closeOptionsMenu, handleConfirmDialogOpen, selectedAccount
}) => {
    const renderButtons = () => {
        switch (proposal.status) {
            case ProposalStatus.DRAFT:
            case ProposalStatus.APPROVED:
            return (
                <>
                <ActionButton
                    onClick={(e) => onPreviewProposalClick(e, proposal)}
                    icon={<VisibilityOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`} />}
                    text="Preview"
                    className="more_actionButton1"
                />
                
                {proposal.can_edit 
                ?
                <ActionButton
                    onClick={(e) => onEditProposalClick(e, proposal)}
                    icon={<EditOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`}/>}
                    text="Edit Proposal"
                    className="more_actionButton"
                />
                :
                <ActionButton
                    onClick={(e) => onSendProposalClick(e, proposal)}
                    icon={<SendIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`}/>}
                    text="Send"
                    className="more_actionButton"
                />
                }
                </>
            );
            case ProposalStatus.SENT:
            case ProposalStatus.VIEWED:
            case ProposalStatus.DOWNLOADED:
            case ProposalStatus.REJECTED:
                return (
                    <>
                    <ActionButton
                        onClick={(e) => onPreviewProposalClick(e, proposal)}
                        icon={<VisibilityOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`} />}
                        text="Preview"
                        className="more_actionButton1"
                    />
                    {proposal.can_edit && 
                    <ActionButton
                        onClick={(e) => onEditProposalClick(e, proposal)}
                        icon={<EditOutlinedIcon fontSize='small' className={`more_actionsIcons ${classes.actionsIcons}`}/>}
                        text="Revoke And Edit Proposal"
                        className="more_actionButton2"
                    />}
                    </>
                );
            default:
            return null;
        }
        };
    
        return <div className={classes.buttonContainer}>{renderButtons()}</div>;
    };
    
    const ActionButton = ({ onClick, icon, text, className }) => (
        <Button item className={className} onClick={onClick}>
        {icon}
        <span>{text}</span>
        </Button>
    );


const ProposalTableRow = ({proposal, entityList, selectedAccount, accessLevelViewer, user, handleConfirmDialogOpen, fromClientDetails,
        storeProposalState, duplicateProposalClicked
}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [proposalId, setProposalId] = useState(undefined);
    const [isActive, setIsActive] = useState(false);

    const {handleProposalModalOpen} = usePropsContext();
    const invoice_account_id = selectedAccount?.id;
    const name = selectedAccount?.name;
    const full_name = user?.firstname + ' ' + user?.lastname;

    const estimateActions = (proposal) => {
        switch (proposal.status) {
            case ProposalStatus.DRAFT:
            case ProposalStatus.SENT:
            case ProposalStatus.VIEWED:
            case ProposalStatus.DOWNLOADED:
            case ProposalStatus.APPROVED:
            case ProposalStatus.REJECTED:
                return (
                    proposal.id === proposalId &&
                    <>
                        {proposal.can_edit &&
                        <li onClick={(e) => onEditProposalClick(e, proposal)}>
                            <a className={classes.displayFlex}>    
                            <EditOutlinedIcon fontSize='small' 
                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                            Edit
                            </a>
                        </li>
                        }
                        <li onClick={(e) => duplicateProposalClicked(e, proposal, closeOptionsMenu)}
                            >
                            <a className={classes.displayFlex}>
                            <FilterNoneIcon fontSize='small' 
                            className={`more_actionsIcons ${classes.actionsIcons}`}/>
                            Duplicate from this
                            </a>
                        </li>
                        <li onClick={(e) => onDownloadProposalClick(e, proposal)}
                            >
                            <a className={classes.displayFlex}>
                                <CloudDownloadOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Download
                            </a>
                        </li>
                        <li onClick={(e) => handleConfirmDialogOpen(e, proposal, closeOptionsMenu, {delete_proposal_clicked: true})}>
                            <a className={classes.displayFlex}>
                                <DeleteOutlinedIcon fontSize='small' 
                                className={`more_actionsIcons ${classes.actionsIcons} icon_position_top`}/>
                                Delete
                            </a>
                        </li>
                    </>
                );
            default:
                return null;
        }
    };

    const closeOptionsMenu = () => {
        setIsActive(false);
        setProposalId('');
    }

    const onEditProposalClick = (e, proposal) => {
        e.stopPropagation();
        trackGAEvent(name, 'Proposal list Item - Edit Proposal clicked', `${full_name}`);
        storeProposalState(true, false, proposal, false);
        handleProposalModalOpen();
        closeOptionsMenu();
    }

    const onPreviewProposalClick = (e, proposal) => {
        e.stopPropagation();
        trackGAEvent(name, 'Proposal list Item - Preview Proposal clicked', `${full_name}`);
        storeProposalState(false, false, proposal, false);
        handleProposalModalOpen();
        closeOptionsMenu();
    }

    const onSendProposalClick = (e, proposal) => {
        trackGAEvent(name, 'Send Proposal button clicked', `${full_name}`);
        e.stopPropagation();
        storeProposalState(false, false, proposal, true);
        handleProposalModalOpen(true);
        closeOptionsMenu();
    }

    const onDownloadProposalClick = async(e, proposal) => {
        e.stopPropagation();
        e.preventDefault();
        const proposal_id = proposal.id;
        const entity_id = proposal.entity_id;
        const client_id = proposal.client_id;

        try {
            const response = await downloadProposalPdfApi(invoice_account_id, entity_id, client_id, proposal_id);
            const res = response.data;
            consoleToLog('Response downloadProposalPdfApi: ', res);
            const downloadUrl = res.result;

            // Create a temporary anchor element to trigger the download
            const tempLink = document.createElement('a');
            tempLink.href = downloadUrl;
            tempLink.setAttribute('download', `proposal_${proposal.name}.pdf`);
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);

            trackGAEvent(selectedAccount?.name, 'Proposal downloaded', `${full_name}`);
            enqueueSnackbar('Proposal downloaded successfully', {variant: 'success'});
        } catch (error) {
            consoleToLog('Error downloadEstimateApi: ', error);
            if (error.response && error.response.data && error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {variant: 'error'});
            } else {
                enqueueSnackbar('Failed to download proposal', {variant: 'error'});
            }
        }
    }

    return (
        <TableRow hover className={classes.estimateListHover}
            onClick={(e) => onPreviewProposalClick(e, proposal)}>
            <TableCell className={`tablebody_style1 ${classes.statusCellWidth}`}>
                <Grid item container alignItems='center'>
                    <div className='status_style'
                        style={{background: EstimateStatusColor(proposal.status.toLowerCase())}}>
                    </div>
                    <Tooltip title={proposal.status.charAt(0).toUpperCase() + proposal.status.slice(1).toLowerCase()} arrow>
                        <Typography 
                        style={{
                            color:EstimateStatusColor(proposal.status.toLowerCase()), 
                            fontSize: "10px"
                        }}>
                            {proposal.status.charAt(0).toUpperCase() + proposal.status.slice(1).toLowerCase()}
                        </Typography>
                    </Tooltip>
                </Grid>
            </TableCell>
            <TableCell className='tablebody_style'>
                <Tooltip title={moment(proposal.created_at, 'YYYY-MM-DD').format('MMM Do, YY')} arrow>
                    <Typography variant='subtitle1' className='issue_date_text_ellipsis'>
                        {moment(proposal.created_at, 'YYYY-MM-DD').format('MMM Do, YY')}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell className='tablebody_style'>
                <Tooltip title={proposal.name} arrow>
                    <Typography variant='subtitle1'>
                        {proposal.name}
                    </Typography>
                </Tooltip>
            </TableCell>
            {entityList && entityList.length > 1 && 
                <TableCell className='tablebody_style'>
                    <Tooltip title={proposal.entity_name} arrow>
                        <Typography variant="subtitle1" 
                            className={'text_ellipsis'}>
                            {proposal.entity_name}
                        </Typography>
                    </Tooltip>
                </TableCell>
            }
            <TableCell className='tablebody_style'>
                <Tooltip title={proposal.client_name} arrow>
                    <Typography variant="subtitle1" 
                        className={'text_ellipsis1'}>
                            {proposal.client_name}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell>{formatAmount(proposal.total_amount, proposal.currency)}</TableCell>
            {!fromClientDetails &&
            <TableCell>
            {!accessLevelViewer &&
                <Grid item
                    style={{display:'flex',
                    alignItems:'center',
                    flexDirection:'row-reverse',
                    justifyContent:'flex-end',
                    position:'relative'}}>

                    <div style={{position:'absolute', right:'0px'}}>        
                        <Tooltip title="More Actions" arrow>  
                            <i className="flaticon-more action__icon"
                            onClick={(e) => {
                                    e.stopPropagation();
                                    setIsActive(!isActive);
                                    setProposalId(proposal.id);
                                }}
                            style={{fontSize:'15px', position:'relative', left:'-16px'}}></i>
                        </Tooltip>
                        <>    
                        {proposal.id === proposalId &&
                            <ClickAwayListener onClickAway={() => {
                                    setIsActive(false);
                                    setProposalId('');
                            }}>
                                <Grid item className={`menu ${isActive ? `active ${classes.marginDropdown}` : "inactive"}`}>
                                    <ul>{estimateActions(proposal)}</ul>
                                </Grid>
                            </ClickAwayListener>
                        }
                        </>
                    </div>

                    <EstimateActions proposal={proposal}
                        classes={classes}
                        onEditProposalClick={onEditProposalClick}
                        onPreviewProposalClick={onPreviewProposalClick}
                        onSendProposalClick={onSendProposalClick}
                        closeOptionsMenu={closeOptionsMenu}
                        handleConfirmDialogOpen={handleConfirmDialogOpen}
                        selectedAccount={selectedAccount}
                    />
                </Grid>
                }
            </TableCell>}
        </TableRow>
    );
}

export default ProposalTableRow;