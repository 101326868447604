import React from 'react';
import {Box, Button, Typography, CircularProgress} from '@material-ui/core';

const ErrorMessageComponent = ({ apiLoading, onCloseProposalDialog, fromParentComponent,  getProposalObj, fromCreateProposalComponent, 
    fromClientPreviewProposal, onCreateProposalClick, callClientProposalPreviewApi}) => {

    const onTryAgainClick = () => {
        if(fromParentComponent) {
            getProposalObj(true);
        } else if(fromCreateProposalComponent) {
            onCreateProposalClick();
        } else if(fromClientPreviewProposal) {
            callClientProposalPreviewApi();
        }
    }

    return (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <img src={'/images/details_error.png'} width={'50%'} style={{marginTop:'70px'}} />

                <Typography variant='h5' style={{textAlign:'center'}}>
                    Something went wrong!
                </Typography>

                <Box display='flex' justifyContent='center' marginTop={2}>
                    <Button variant='contained' 
                        onClick={onTryAgainClick}
                        style={{ width: '120px' }} // Set width for the button
                        color='secondary'>
                        Try again
                        {apiLoading && fromParentComponent && <CircularProgress size={18} style={{marginLeft:'8px', color:'white'}}/>}
                    </Button>
                    {!fromClientPreviewProposal &&
                    <Button variant='outlined'
                        onClick={onCloseProposalDialog}
                        style={{ width: '120px', marginLeft: '8px' }} // Set width and margin for the button
                        color='secondary'>
                        Cancel
                    </Button>}
                </Box>
        </Box>
    );
}

export default ErrorMessageComponent;