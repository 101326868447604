import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import BackgroundPreview from './BackgroundPreview';

const useStyles = makeStyles((theme) => ({
    leaderItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap:'8px',
        alignItems:'center',
    },
    leaderShipTitle: {
        fontSize:'32px',
        fontWeight: 600,
        margin: '32px 0px 16px 0px'
    },
    leaderShipTitle1: {
        fontSize:'24px',
        fontWeight: 600,
        margin: '32px 0px'
    },
    titleStyles: {
        marginTop:"32px",
        fontSize:'32px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize:'24px',
            fontWeight: 600
        },
    },
    titleStyles1: {
        marginTop:"32px",
        fontSize:'24px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize:'21px',
            fontWeight: 600
        },
    },
    centerAlignText: {
        textAlign:"center"
    }
}))

const TeamPreview = ({item, index, normalUserPreview, brand_color}) => {
    const classes = useStyles();

    const {data} = item;
    const {title, team_image_title, team_image, show_team_image, team_image_signed_url, show_leadership_title, show_team_image_title, leaders} = data;

    const titleClass = normalUserPreview ? classes.titleStyles1 : classes.titleStyles;
    const leadershipTitleClass = normalUserPreview ? classes.leaderShipTitle1 : classes.leaderShipTitle;

    return (
        <BackgroundPreview item={item} title={title} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
            >

                <Grid item md={12}>
                    {show_team_image_title &&
                    <Grid item md={12}>
                        <Typography className={titleClass} style={{color:brand_color}}>
                            {team_image_title}
                        </Typography>
                    </Grid>}

                    {show_team_image &&
                        <Grid item md={12} style={{marginTop:show_team_image_title ? '16px' : '0px'}}>
                            <div className='team_image_container'>
                                <img src={team_image_signed_url ? team_image_signed_url : '/images/team_photo.png'} 
                                    className='image_style'/>
                            </div>
                        </Grid>
                        
                    }
                </Grid>


            <Grid item md={12}>
                {show_leadership_title &&
                <Typography className={leadershipTitleClass} style={{color:brand_color}}
                    dangerouslySetInnerHTML={{__html: 'Leadership Team'}} />
                }
                <Grid item container alignItems='center' justifyContent='center'>
                    {
                        leaders && leaders.length > 0 &&
                        leaders.map((item) => {
                            const {name, title, bio, profile_pic, profile_pic_signed_url} = item;
                            return  item &&
                                    <Grid item xs={12} md={4} className={classes.leaderItemContainer}>
                                        <div className='profile_pic_container'>
                                            <img src={profile_pic_signed_url ? profile_pic_signed_url : '/images/profile_pic.png'} 
                                                className='image_style'/>
                                        </div>

                                        <div>
                                            <Typography>
                                                <b>{name}</b>
                                            </Typography>
                                            <Typography className={classes.centerAlignText}>
                                                {title}
                                            </Typography>
                                        </div>
                                        {/* <Typography>
                                            {bio}
                                        </Typography> */}
                                    </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </BackgroundPreview>
    );
}

export default TeamPreview;