import React,{useState} from 'react';
import { Grid, IconButton, Typography, Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        width:'100%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px 0px #0000000D',
    },
    header: {
        backgroundColor: '#f0f0f0',
        padding: '8px 0px 8px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    moveIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        fontSize: '18px',
        fontWeight: 600,
    },
    childContainer: {
        padding: theme.spacing(3)
    },
    customColumn1: {
        flexBasis:'14%',
        maxWidth:'14%'

    },
    customColumn2: {
        flexBasis:'65%',
        maxWidth:'65%'
    },
}));

const BaseSection = ({ title, children, item, handleDrawerOpen, onShowOrHidePage, callDeleteCustomSectionApi }) => {
    const classes = useStyles();

    const [startOnNewPage, setStartOnNewPage] = useState(true);

    const handleChange = (event) => {
        setStartOnNewPage(event.target.checked);
    };

    return (
        <Paper variant='outlined' className={`${classes.sectionContainer} ${!item.show_page ? 'hide_page_style' : ''}`}>
            <Grid container alignItems="center" justifyContent='space-between' className={classes.header}>
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>

                <Grid item>
                    <Grid item container alignItems='center' spacing={3}>
                        <Grid item md={item.type === 'custom' ? 3 : 4}>
                            {item.show_page
                            ?
                            <Tooltip title='Hide Page' arrow>
                                <IconButton 
                                    onClick={() => onShowOrHidePage(item, false)}
                                    className='edit_header_icon_btn'>
                                    <img src={'/images/visibility_off.svg'} />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title='Show Page' arrow>
                                <IconButton 
                                    onClick={() => onShowOrHidePage(item, true)}
                                    className='edit_header_icon_btn'>
                                    <img src={'/images/visibility.svg'} />
                                </IconButton>
                            </Tooltip>
                            }
                        </Grid>

                        <Grid item md={item.type === 'custom' ? 3 : 4}>
                            <IconButton 
                                onClick={() => handleDrawerOpen(item)}
                                className='edit_header_icon_btn'>
                                <img src={'/images/stylus.svg'} />
                            </IconButton>
                        </Grid>

                        {item.type === 'custom' &&
                        <Grid item md={3}>
                            <IconButton 
                                onClick={() => callDeleteCustomSectionApi(item.id)}
                                className='edit_header_icon_btn'>
                                <img src={'/images/drawer_delete.svg'} />
                            </IconButton>
                        </Grid>}

                        {/* {item?.type !== 'cover_page' &&
                        <Grid item md={7} className={classes.customColumn2}>
                            
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={startOnNewPage}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="secondary"
                                    />
                                    }
                                    label="Start on new page"
                                />
                        </Grid>
                        } */}
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.childContainer}>
                {children}
            </div>
        </Paper>
    );
};

export default BaseSection;
