import React from 'react';
import BackgroundPreview from './BackgroundPreview';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Link} from '@material-ui/core';
import { showSocialMediaImages } from '../../../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '69px',
        lineHeight:'95px',
        fontWeight: 800,
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
            fontWeight: 600,
            lineHeight:'55px',
        },
        [theme.breakpoints.down(400)]: {
            fontSize: '30px',
            fontWeight: 600,
            lineHeight:'45px',
        },
    },
    title1: {
        fontSize: '60px',
        fontWeight: 700,
        lineHeight:'75px',
        [theme.breakpoints.down(1450)]: {
            fontSize: '50px',
            fontWeight: 600,
            lineHeight:'70px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
            fontWeight: 600,
            lineHeight:'45px',
        },
    },
    footerStyle: {
        position: 'absolute',
        bottom: '200px',
        left: '80px',
        right: '80px',
    
        [theme.breakpoints.down('sm')]: {
            bottom: '150px',
            left: '20px',
            right: '20px',
        },
    },
    footerStyle1: {
        position: 'absolute',
        bottom: '200px',
        left: '60px',
        right: '60px',
    
        [theme.breakpoints.down('sm')]: {
            bottom: '150px',
            left: '20px',
            right: '20px',
        },
    },
    preparedLabelText: {
        fontSize: '24px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    imageStyle: {
        position: 'relative',
        left: '8px',
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
        },
    },
    dateStyle: {
        fontSize: '42px',
        fontWeight: 700,
        position: 'relative',
        left: '8px',
        top: '16px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            top: '4px',
        },
    },
    dateStyle1: {
        fontSize: '32px',
        fontWeight: 700,
        position: 'relative',
        left: '8px',
        top: '16px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            top: '4px',
        },
    },
    clientNameText: {
        fontSize: '24px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    socialMediaImageStyle: {
        width: '32px',
        height: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '24px',
            height: '24px',
        },
    },
    imageContainer: {
        width: '10%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#f9f9f9',
        [theme.breakpoints.down('sm')]: {
            width: '10%',
        },
    },
    imageContainer1: {
        width: '10%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#f9f9f9',
        [theme.breakpoints.down('sm')]: {
            width: '35px',
            height: '35px',
        },
    },
    logoImage: {
        width: '100%',
        objectFit: 'cover',
        objectPosition:'cover'
    },
    companyName: {
        fontSize:'24px',
        fontWeight: 600,
        position:'relative',
        top:'-1px',
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px',
        },
    },
    companyName1: {
        fontSize:'18px',
        fontWeight: 600,
        position:'relative',
        top:'-1px',
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px',
        },
    },
    customClass: {
        flexBasis:'12%',
        maxWidth:'12%',
        [theme.breakpoints.down('sm')]: {
            flexBasis:'20%',
            maxWidth:'20%',
        },
    },
    customClass1: {
        flexBasis:'11%',
        maxWidth:'11%'
    }
}))

const CoverPagePreview = ({item, index, brand_color, entity_name, entity_logo, normalUserPreview}) => {
    const classes = useStyles();

    const {data} = item;
    const {client_company_name, title, client_first_name, client_last_name, show_company_logo, show_company_name, website, social_links} = data;
    const todayDate = new Date();
    const year = todayDate.getFullYear();

    const titleClass = normalUserPreview ? classes.title1 : classes.title;
    const dateClass = normalUserPreview ? classes.dateStyle1 : classes.dateStyle;
    const companyLogo = normalUserPreview ? classes.customClass1 : classes.customClass;
    const companyName = normalUserPreview ? classes.companyName1 : classes.companyName;
    const footerStyle = normalUserPreview ? classes.footerStyle1 : classes.footerStyle;

    const onWebsiteUrlClick = () => {
        window.open(website, '_blank');
    }

    return (
        <BackgroundPreview item={item} title={''} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >
                    <Grid item md={12}>
                        <Grid item container alignItems='center'>
                            {show_company_logo &&
                                <Grid item className={companyLogo}>
                                    {show_company_logo && <img src={entity_logo ? entity_logo : '/images/entity_business-and-trade.png'} className={classes.logoImage} />}
                                </Grid>
                            }

                            <Grid item>
                                <Typography className={companyName} style={{marginLeft:show_company_logo ? '6px' : '0px'}}>
                                    {show_company_name && entity_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                <Grid item md={12} style={{marginTop:'122px'}}>
                    <img src='/images/dotted_image.png' className={classes.imageStyle}/>
                    <Typography variant='h6' className={dateClass} style={{color: brand_color}}>
                        {year}
                    </Typography>
                    <Typography className={titleClass} style={{color: brand_color}}>
                        {title}
                    </Typography>
                </Grid>

                <Grid item md={12} className={footerStyle}>
                    <Grid item container alignItems='center' justifyContent='space-between'>
                        <Grid item>
                            <Typography className={classes.preparedLabelText}>
                                Prepared for:
                            </Typography>

                            {client_first_name && client_last_name &&
                            <Typography className={classes.clientNameText}>
                                {`${client_first_name} ${client_last_name}`}
                            </Typography>}

                            <Typography className={classes.clientNameText}>
                                {client_company_name}
                            </Typography>
                        </Grid>

                        <Grid item>
                                {website &&
                                <Typography 
                                    onClick={onWebsiteUrlClick}
                                    className='drawer_link_style'>
                                    {website}
                                </Typography>}
                            <Grid item container alignItems='center' spacing={1}>
                                
                            {
                                social_links && social_links.length > 0 &&
                                social_links.map((item) => {
                                    return <Grid item key={item.type}>
                                                <Link href={item.link} target='blank'>
                                                    <img src={(showSocialMediaImages(item.type))} className={classes.socialMediaImageStyle}/>
                                                </Link>
                                            </Grid>
                                })
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </BackgroundPreview>
    );
}

export default CoverPagePreview;