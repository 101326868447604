import React from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {Grid, Typography, Button, Tooltip, TextField, Popover} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IssueDateRangeFilterComponent from './filters/IssueDateRangeFilterComponent';
import DueDateRangeFilterComponent from './filters/DueDateRangeFilterComponent';
import StatusFilterComponent from './filters/StatusFilterComponent';
import ClientFilterComponent from './filters/ClientFilterComponent';
import {clearSelectedInvoiceFilters} from '../actions/invoiceFilters';
import {connect} from 'react-redux';
import InvoiceEstimateTypeFilterComponent from './filters/InvoiceEstimateTypeFilterComponent';
import AutocompleteEntities from './filters/AutocompleteEntities';
import { clearSelectedProposalFilters } from '../actions/proposalFilters';

const useStyles = makeStyles((theme) => ({
    customMarginTop : {
        marginTop:"16px"
    },
    customMarginBottom : {
        marginBottom:'-8px'
    },
}));

const FilterDrawer = (props) => {
    const classes = useStyles();

    const proforma_enabled = props.selectedAccount.general_settings?.proforma_enabled
    return (
        <RightToLeftSideDrawer title="Filters" 
            openDrawer={props.openFilterDrawer}
            closeDrawer={props.handleCloseFilterDrawer}>

            <Grid item sm={12} style={{padding:'16px'}}>
                    <IssueDateRangeFilterComponent pageReset={props.pageReset}
                        fromProposalListComponent={props.fromProposalListComponent}/>

                    <StatusFilterComponent pageReset={props.pageReset} 
                        fromEstimatesComponent={props.fromEstimatesComponent}
                        fromProposalListComponent={props.fromProposalListComponent}
                        />

                    {/* <DueDateRangeFilterComponent/> */}
                    {proforma_enabled || props.fromEstimatesComponent &&
                    <InvoiceEstimateTypeFilterComponent pageReset={props.pageReset}
                        fromEstimatesComponent={props.fromEstimatesComponent}/>
                    }

                    <AutocompleteEntities pageReset={props.pageReset}
                        fromProposalListComponent={props.fromProposalListComponent}/>

                    <ClientFilterComponent pageReset={props.pageReset}
                        fromProposalListComponent={props.fromProposalListComponent}/>

                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                            className="modal__button"
                            onClick={() =>{
                                    props.pageReset();
                                    if(props.fromProposalListComponent) {
                                        props.clearSelectedProposalFilters();
                                    } else {
                                        props.clearSelectedInvoiceFilters();
                                    }
                                }
                            }
                            >
                        Reset Filters
                    </Button>
                </div> 
            </Grid>
            
        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

const mapDispatchToProps = (dispatch) => ({
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedProposalFilters: () => dispatch(clearSelectedProposalFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);