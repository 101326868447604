import React, { memo } from 'react';
import { Drawer, IconButton, Typography, Grid, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '54vw',
        overflowX:'hidden',
        [theme.breakpoints.down(1420)] : {
            width: '60vw',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '6px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background:'#F9F9F9',
    },
    headingTitle: {
        fontSize: '16px',
        fontWeight: 600
    }
}));

const BaseDrawer = memo(({ open, onClose, title, children }) => {
    const classes = useStyles();

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            classes={{ paper: classes.drawerPaper }}
        >
            <div className={classes.header}>
                <Typography variant="h6"
                    className={classes.headingTitle}>
                    {title}
                </Typography>

                <IconButton onClick={onClose}>
                    <img src='/images/close_black.svg' />
                </IconButton>
            </div>

            <div>
                {children}
            </div>

        </Drawer>
    );
});

export default memo(BaseDrawer);
