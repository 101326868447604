import axios from 'axios';
import {getAttachmentUploadURLApi, getAttachmentDownloadURLApi} from '../services/proposalService';

export const uploadAttachmentApi = async (invoice_account_id, attachment_type, attachment_subtype, filename, file, item_id) => {
    const response = await getAttachmentUploadURLApi(invoice_account_id, attachment_type, attachment_subtype, filename, item_id);
    const res = response.data;
    const signed_url = res.signed_url;
    const upload_url = res.url_path;
    const options = {
            headers: {
            "Content-Type": file.type,
            }
        };
        axios.defaults.headers.common = {};
    const data = await axios.put(signed_url, file, options)
    return {upload_url, data};
        
}


export const downloadAttachmentApi = async (invoice_account_id, attachment_subtype, path, proposal_id) => {
    const response = await getAttachmentDownloadURLApi(invoice_account_id, attachment_subtype, path, proposal_id);
    const res = response.data;
    const signed_url = res.signed_url;
    const upload_url = res.url_path;
    return {signed_url, upload_url};
        
}