import React, {useState,} from 'react';
import {Grid, Typography, TextField, Button, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';

const useStyles = makeStyles((theme) => ({
    labelStyle: {
        fontWeight: 400,
        lineHeight: '24px',
        marginRight:'4px'
    },
    addNewButton: {
        position:'relative',
        left: '86%',
        top:'16px'
    },
}));


const IntroductionDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, apiLoading, setDirtyFlag}) => {
    const classes = useStyles();
    const {data} = itemObj;
    const {description, title} = data;

    const [introTitle, setIntroTitle] = useState(title);
    const [introDescp, setIntroDescp] = useState(description);

    const setUpdatedItemObj = () => {
        const dataObj = {
            description: introDescp,
            title: introTitle,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    const handleTitleChange = (event) => {
        setIntroTitle(event.target.value);
        setDirtyFlag(true);
    };

    const handleDescriptionChange = (value) => {
        setIntroDescp(value);
        setDirtyFlag(true);
    };

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Introduction'>
                <div className='drawer_content_container'>

                    <Grid item md={12}>
                        <Typography className={classes.labelStyle}>
                            Page Title
                        </Typography>

                        <TextField 
                            className={'drawer_textfield_margintop'}
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            value={introTitle}
                            onChange={handleTitleChange}
                        
                        />
                    </Grid>

                    <Grid item md={12}>

                        <Typography className={classes.labelStyle}>
                            Description
                        </Typography>

                        <BaseEditor editorValue={introDescp}
                            setEditorValue={handleDescriptionChange}/>
                    </Grid>

                </div>

                <div className={'drawer_footer'}>
                    <Grid item container alignItems='center' spacing={1}>
                        <Grid item>
                            <Button 
                                onClick={onSaveClick}
                                className={`next_btn_style drawer_btn_width`}>
                                {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant='outlined' color='primary'
                                onClick={onDrawerClose}
                                className={'drawer_btn_width'}>
                                Cancel 
                            </Button>
                        </Grid>
                    </Grid>
                </div>

    </BaseDrawer>
    );
}

export default IntroductionDrawer;