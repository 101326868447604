import React from 'react';
import BackgroundPreview from './BackgroundPreview';
import {Grid, Typography, Divider} from '@material-ui/core';
import { ReadOnlyEditor } from '../edit/BaseEditor';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dividerStyle: {
        background:'#555', 
        width:'2px', 
        height:'100%',
        position:'relative',
        top:'8px'
    },
    listContainer: {
        display:'flex',
        flexDirection: 'column',
        gap: '56px',
        paddingBottom:'72px',
        [theme.breakpoints.down('sm')]: {
            gap: '26px',
        }
    },
    quotesImage: {
        width:'42px',
        height: '32.36px',
        [theme.breakpoints.down('sm')]: {
            display:'none',
            width:'30px',
            height: '20px',
        }
    },
    quotesImage1: {
        width:'30px',
        height: '20px',
        [theme.breakpoints.down('sm')]: {
            display:'none',
            width:'20px',
            height: '20px',
        }
    },
    reviewerStyle: {
        fontSize:"18px",
        fontWeight: 600,
        lineHeight: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize:"15px",
            fontWeight: 600,
            lineHeight: '26px',
        }
    },
    reviewerStyle1: {
        fontSize:"16px",
        fontWeight: 600,
        lineHeight: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize:"15px",
            fontWeight: 600,
            lineHeight: '26px',
        }
    }
}))

const ReviewsPreview = ({item, index, normalUserPreview, brand_color}) => {
    const classes = useStyles();

    const {data} = item;
    const {title, description, reviews} = data;

    const descpStyle = normalUserPreview ? 'preview_description_style1' : 'preview_description_style';
    const quotesImageStyle = normalUserPreview ? classes.quotesImage1 : classes.quotesImage;
    const reviewerStyle = normalUserPreview ? classes.reviewerStyle1 : classes.reviewerStyle;

    return (
        <BackgroundPreview item={item} 
            title={title}
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >
                
                <Grid item md={12} className={descpStyle}>
                    <ReadOnlyEditor editorValue={description}/>
                </Grid>

                <Grid item md={12}>
                    <div className={`${descpStyle} ${classes.listContainer}`}>
                        {
                            reviews && reviews.length > 0 &&
                            reviews.map((item) => {
                                const {reviewed_by, review_company_name, review_text} = item;
                                return <Grid item container alignItems='center' style={{borderLeft: '2px solid #555'}}>
                                            <Grid item md={1} style={{flexBasis:'4%', maxWidth:'4%'}}>
                                            </Grid>
                                            <Grid item md={11}  style={{flexBasis:'95%', maxWidth:'95%'}}
                                                className={descpStyle}>
                                                <img src='/images/quotes_image.png' className={quotesImageStyle}/>
                                                <ReadOnlyEditor editorValue={review_text}/>
                                            
                                                <Typography className={reviewerStyle}>
                                                    <span style={{position:"relative", top:"-4px", marginRight:"8px"}}><b>___</b></span>{`${reviewed_by}`}
                                                </Typography>

                                                <Typography className={reviewerStyle}>
                                                    {review_company_name}
                                                </Typography>


                                            </Grid>
                                            
                                        </Grid>
                            })
                        }
                    </div>
                </Grid>

        </BackgroundPreview>
    );
}

export default ReviewsPreview;